import { createSlice } from "@reduxjs/toolkit";
import { getRangeInMillisFromIndex } from "DAI/MediaEditor/utils/mediaEditor";

export const AspectRatio = {
  Aspect1_1: {
    label: "1:1",
    value: "aspect_1_1",
    dimensions: {
      height: "25px",
      width: "25px",
    },
  },
  Aspect4_5: {
    label: "4:5",
    value: "aspect_4_5",
    dimensions: {
      height: "25px",
      width: "20px",
    },
  },
  Aspect9_16: {
    label: "9:16",
    value: "aspect_9_16",
    dimensions: {
      height: "28px",
      width: "16px",
    },
  },
};

export const allAspectRatios = Object.values(AspectRatio);

export const AudiogramPlayerMode = {
  Playback: "Playback",
  Trim: "Trim",
};

const audiogramEditorSlice = createSlice({
  name: "audiogramEditor",
  initialState: {
    audiogram: null,
    backgrounds: {
      [AspectRatio.Aspect9_16.value]: [],
      [AspectRatio.Aspect1_1.value]: [],
      [AspectRatio.Aspect4_5.value]: [],
    },
    editedAudiogram: null,
    player: {
      mode: AudiogramPlayerMode.Playback,
      rangeInMillis: {
        start: 0,
        end: 0,
      },
    },
    trimming: {
      isTouched: false,
      trimmedRangeInMillis: {
        start: 0,
        end: 0,
      },
      trimmedWordRange: {
        start: 0,
        end: 0,
      },
      highlightedWordRange: {
        start: 0,
        end: 0,
      },
      highlightedRangeInMillis: {
        start: 0,
        end: 0,
      },
    },
  },

  reducers: {
    mute(state) {
      state.player.isMuted = true;
    },
    unmute(state) {
      state.player.isMuted = false;
    },
    setAudiogram(state, action) {
      state.audiogram = action.payload;
      state.editedAudiogram = action.payload;
    },
    setEditedAudiogram(state, action) {
      state.editedAudiogram = action.payload;
    },
    setPlayerMode(state, action) {
      state.player.mode = action.payload;
    },
    setPlayerRangeInMillis(state, action) {
      state.player.rangeInMillis = action.payload;
    },

    setTrimmerToTouched(state) {
      state.trimming.isTouched = true;
    },
    resetTrimmer(state) {
      state.trimming = {
        isTouched: false,
        trimmedRangeInMillis: {
          start: 0,
          end: 0,
        },
        trimmedWordRange: {
          start: 0,
          end: 0,
        },
        highlightedWordRange: {
          start: 0,
          end: 0,
        },
        highlightedRangeInMillis: {
          start: 0,
          end: 0,
        },
      };
    },
    /**
     * @param {Object} action
     * @param {Object} action.payload
     * @param {number} action.payload.startIndex
     * @param {number} action.payload.endIndex
     */
    setTrimmedWordRange(state, action) {
      state.trimming.trimmedWordRange = {
        start: action.payload.startIndex,
        end: action.payload.endIndex,
      };

      const millisRange = getRangeInMillisFromIndex({
        words: state.editedAudiogram.groupedWords,
        startIndex: action.payload.startIndex,
        endIndex: action.payload.endIndex,
      });
      state.trimming.trimmedRangeInMillis = millisRange;
    },
    setTrimmedRangeInMillis(state, action) {
      if (!state.editedAudiogram) {
        return;
      }

      const startWordIndex = state.editedAudiogram.groupedWords.findIndex(
        (word) => {
          if (action.payload.start >= word.start && action.payload.start < word.end) {
            return true;
          }

          return false;
        },
      );

      const endWordIndex = state.editedAudiogram.groupedWords.findIndex(
        (word) => {
          if (action.payload.end <= word.end) {
            return true;
          }

          return false;
        },
      );

      if (startWordIndex !== -1 && endWordIndex !== -1) {
        state.trimming.trimmedWordRange = {
          start: startWordIndex,
          end: endWordIndex,
        };

        state.trimming.trimmedRangeInMillis = {
          start: state.editedAudiogram.groupedWords[startWordIndex].start,
          end: state.editedAudiogram.groupedWords[endWordIndex].end,
        };
      }
    },

    setHighlightedWordRange(state, action) {
      if (!action.payload) {
        state.trimming.highlightedWordRange = {
          start: 0,
          end: 0,
        };

        state.trimming.highlightedRangeInMillis = {
          start: 0,
          end: 0,
        };

        return;
      }

      state.trimming.highlightedWordRange = {
        start: action.payload.start || 0,
        end: action.payload.end || state.editedAudiogram.groupedWords.length - 1,
      };

      state.trimming.highlightedRangeInMillis = {
        start:
          state.editedAudiogram.groupedWords[action.payload.start]?.start
          || state.player.rangeInMillis.start,
        end:
          state.editedAudiogram.groupedWords[action.payload.end]?.end
          || state.player.rangeInMillis.end,
      };
    },

    setBackgrounds(state, action) {
      state.backgrounds = action.payload;
    },
  },
});

export const {
  mute,
  unmute,
  setAudiogram,
  setEditedAudiogram,
  setPlayerMode,
  setTrimmedRangeInMillis,
  setTrimmedWordRange,
  setHighlightedWordRange,
  setPlayerRangeInMillis,
  setBackgrounds,
  setTrimmerToTouched,
  resetTrimmer,
} = audiogramEditorSlice.actions;

/**
 * @returns {ReturnType<typeof audiogramEditorSlice["getInitialState"]>}
 */
export const selectAudiogramEditorSlice = (state) => (
  state.audiogramEditor
);

/**
 * @returns {ReturnType<typeof audiogramEditorSlice["getInitialState"]>["player"]}
 */
export const selectPlayerState = (state) => state.audiogramEditor.player;
export const selectAudiogram = (state) => state.audiogramEditor.audiogram;
export const selectAudiogramBackgrounds = (state) => state.audiogramEditor.backgrounds;
export const selectEditedAudiogram = (state) => state.audiogramEditor.editedAudiogram;
/**
 * @returns {ReturnType<typeof audiogramEditorSlice["getInitialState"]>["trimming"]}
 */
export const selectTrimmingState = (state) => state.audiogramEditor.trimming;

export default audiogramEditorSlice.reducer;
