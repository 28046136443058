import Axios from "axios";
import { camelCase, isPlainObject, mapKeys, mapValues, snakeCase } from "lodash";
import { history } from "utils/history";

const instance = Axios.create({});

instance.interceptors.request.use(
  (config) => {
    const conf = { ...config };
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo && userInfo.token) {
      conf.headers.Authorization = `Bearer ${userInfo.token}`;
    }

    conf.baseURL = process.env.REACT_APP_BACKEND_URL;

    if (process.env.NODE_ENV === "development") {
      // Prepend /dai_api to URLs to proxy requests to the staging
      // server on the development environment
      conf.url = `/dai_api${config.url}`;
    }

    return conf;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (res) => res,
  (error) => {
    const err = error;

    err.code = error.response.status;

    if (error.response.status === 401) {
      localStorage.removeItem("userInfo");

      if (history.location.pathname !== "/auth/login") {
        history.navigate("/auth/login", {
          state: {
            referrer: history.location.pathname,
            message: "You must sign in to your Deciphr AI account to continue.",
          },
        });
      }
    }

    return Promise.reject(err);
  },
);

instance.CancelToken = Axios.CancelToken;
instance.isCancel = Axios.isCancel;

/**
 * Get the url depending on the environment the api
 * is being called in
 *
 * @param {string} path
 */
export function getReqUrl(path) {
  let url = `${process.env.REACT_APP_BACKEND_URL.endsWith("/")
    ? process.env.REACT_APP_BACKEND_URL.slice(0, -1)
    : process.env.REACT_APP_BACKEND_URL
  }`;

  if (process.env.NODE_ENV === "development") {
    url += "/dai_api";
  }

  url += path;

  return url;
}

const toCamelCase = (_, key) => (camelCase(key));

/**
 * Map keys of an object to follow camel case
 *
 * @see https://github.com/odynvolk/map-keys-deep-lodash/blob/master/src/index.js
 *
 * @param {Object} object Object for mapping the keys
 */
export function mapKeysToCamelCase(object) {
  if (
    typeof object === "string"
    || typeof object === "number"
    || typeof object === "boolean"
  ) {
    return object;
  }

  if (!object) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map((item) => (mapKeysToCamelCase(item)));
  }

  if (!isPlainObject(object)) {
    return object;
  }

  const result = mapKeys(object, toCamelCase);

  return mapValues(result, (value) => (
    mapKeysToCamelCase(value)
  ));
}

const toSnakeCase = (_, key) => (snakeCase(key));

export function mapKeysToSnakeCase(object) {
  if (
    typeof object === "string"
    || typeof object === "number"
    || typeof object === "boolean"
  ) {
    return object;
  }

  if (!object) {
    return null;
  }

  if (Array.isArray(object)) {
    return object.map((item) => (mapKeysToSnakeCase(item)));
  }

  if (!isPlainObject(object)) {
    return object;
  }

  const result = mapKeys(object, toSnakeCase);

  return mapValues(result, (value) => (
    mapKeysToSnakeCase(value)
  ));
}

export default instance;
