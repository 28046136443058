/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    plan: null,
    transcriptUsed: 0,
    transcriptTotal: 0,
    audioUsed: 0,
    audioTotal: 0,
    regenerateUsed: 0,
    regenerateTotal: 0,
    usedCancellationCoupon: false,
    planPaused: false,
    pausedPlanName: null,
    invoices: [],
  },

  reducers: {
    initializeSubscriptionData: (state, action) => {
      const { invoices, paused_plan, plan, plan_paused, used_cancellation_coupon } = action.payload;

      state.invoices = invoices;
      state.plan = plan;
      state.pausedPlanName = paused_plan;
      state.planPaused = plan_paused;
      state.usedCancellationCoupon = used_cancellation_coupon;
    },

    setPlan: (state, action) => {
      state.plan = action.payload;
    },

    setCredits: (state, action) => {
      const {
        audio_used,
        audio_total,
        transcript_used,
        transcript_total,
        regenerate_used,
        regenerate_total,
      } = action.payload;

      state.audioUsed = audio_used;
      state.audioTotal = audio_total;
      state.transcriptUsed = transcript_used;
      state.transcriptTotal = transcript_total;
      state.regenerateUsed = regenerate_used || 0;
      state.regenerateTotal = regenerate_total || 0;
    },
  },
});

// Actions
export const { initializeSubscriptionData, setPlan, setCredits } = subscriptionSlice.actions;

// Selectors
export const getCredits = (state) => ({
  media: state.subscription.audioTotal - state.subscription.audioUsed,
  transcript: state.subscription.transcriptTotal - state.subscription.transcriptUsed,
});

export const getInvoices = (state) => state.subscription.invoices;
export const selectPlan = (state) => state.subscription.plan;
export const getSubscriptionPlan = (state) => state?.subscription?.plan?.plan;
export const hasTrialStarted = (state) => state?.subscription?.plan?.status !== null;

export default subscriptionSlice.reducer;
