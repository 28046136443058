import { createSlice } from "@reduxjs/toolkit";

const headerSlice = createSlice({
  name: "headerNav",
  initialState: {
    pageTitle: "",
    showBackButton: false,
  },

  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },

    setShowBackButton: (state, action) => {
      state.showBackButton = action.payload;
    },

    reset: (state) => {
      state.pageTitle = "";
      state.showBackButton = false;
    },
  },
});

// Actions
export const { setPageTitle, setShowBackButton, reset } = headerSlice.actions;

// Seelctors
export const selectPageTitle = (state) => state.headerNav.pageTitle;
export const selectShowBackButton = (state) => state.headerNav.showBackButton;

export default headerSlice.reducer;
