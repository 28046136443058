import axios from "utils/axiosConfig";

export const saveAudiogramTrasncript = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/audiogram/update/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const uploadLogo = async (docId, audId, payload) => {
  const formData = new FormData();
  formData.append("image", payload);

  try {
    const { data } = await axios({
      method: "POST",
      url: `/audiogram/logo/${docId}/${audId}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const checkAudioGramStatus = async (id) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/audiogram/status/${id}`,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const exportContent = async ({ docId, params }) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/v2/up/export/${docId}`,
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const generateAudiogram = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/v1/audiogram/from-quotes/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateAudiogramBackground = async (docId, bgId) => {
  try {
    return await axios({
      method: "POST",
      url: `/audiogram/update-background/${docId}/${bgId}`,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const checkVideoReelStatus = async (id) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/videogram/status/${id}`,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateVideReelTranscript = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/videogram/update/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getSubtitles = async (mediaType, id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/subtitle/${mediaType}/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const uploadReelLogo = async (docId, audId, payload) => {
  const formData = new FormData();
  formData.append("image", payload);

  try {
    const { data } = await axios({
      method: "POST",
      url: `/videogram/logo/${docId}/${audId}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Create a new video reel from transcript excerpt
 *
 * @param {string} docId Id of the upload
 * @param {string} excerpt The part of the transcript to make reel out of
 * @returns {Promise<(Record<string, any> | import("axios").AxiosError)>} Created reel data
 */
export const createNewReel = async (docId, excerpt) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/videogram/create`,
      data: {
        doc_id: docId,
        transcript_excerpt: excerpt,
      },
    });

    return data;
  } catch (e) {
    return e;
  }
};

export const askDeciphr = async (docId, question, threadId = null) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const parts = [
    `${process.env.REACT_APP_BACKEND_URL.endsWith("/")
      ? process.env.REACT_APP_BACKEND_URL.slice(0, -1)
      : process.env.REACT_APP_BACKEND_URL
    }`,
  ];

  if (process.env.NODE_ENV === "development") {
    parts.push("dai_api");
  }

  parts.push(`up/ask/${docId}`);

  const url = parts.join("/");

  const res = await fetch(
    url,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: question,
        thread_id: threadId,
      }),
    },
  );

  return res;
};
