import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logo from "assets/logov2.svg";

export default function HeaderLogo({ show }) {
  if (show) {
    return (
      <Link
        to="/home"
        aria-label="Deciphr AI Home"
        style={{ marginTop: "4px" }}
      >
        <img src={logo} alt="" height="32" width="132" aria-hidden />
      </Link>
    );
  }

  return null;
}

HeaderLogo.propTypes = {
  show: PropTypes.bool.isRequired,
};
