import { useState, useEffect, useContext } from "react";
import { useLocation, Outlet } from "react-router-dom";
import AuthLayout from "DAI/Auth/layouts/AuthLayout";
import { GlobalData } from "context";

export default function Auth() {
  const urLocation = useLocation();
  const initAction = urLocation.pathname.split("/")[2];

  const [isConfirmEmail, setIsConfirmEmail] = useState(false);
  const { openAlert, closeAlert, setMessage, setSeverity } =
    useContext(GlobalData);

  const errorHandler = (authErr) => {
    setSeverity("error");
    openAlert();
    setMessage(authErr);
  };

  useEffect(() => {
    if (urLocation.search.split("&").length > 1) {
      urLocation.search.split("&").forEach((v) => {
        if (v.includes("mode")) {
          setIsConfirmEmail(v.replace("?mode=", "") === "verifyEmail");
        }
      });
    } else {
      setIsConfirmEmail(false);
    }

    closeAlert();
  }, [urLocation]);

  if (isConfirmEmail) {
    return <Outlet context={{ initAction, errorHandler }} />;
  }

  return (
    <AuthLayout initAction={initAction}>
      <Outlet context={{ initAction, errorHandler }} />
    </AuthLayout>
  );
}
