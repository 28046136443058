import { Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import TestimonialHeading from "DAI/Auth/components/Testimonials/Heading";
import BrandLogos from "DAI/Auth/components/BrandLogos";
import SecurityBadge from "DAI/Auth/components/SecurityBadge";
import { userReviews } from "./data";
import daiBg from "assets/dai-bg.svg";
import logo from "assets/logo.png";

const UserReviews = lazy(() => import("DAI/Auth/components/UserReviews"));
const CountdownTimer = lazy(() => import("DAI/Auth/components/CountdownTimer"));

export default function SignupTestimonials() {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      sx={{
        position: "relative",
        bgcolor: "#6552F3",
        backgroundImage: `url(${daiBg})`,
        backgroundPosition: "112.5% 0%",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: { xs: "100%", md: "55%" },
        padding: {
          xs: "32px 16px",
          lg: "32px 24px",
        },
      }}
    >
      {/* <Link
        href="https://www.deciphr.ai"
        sx={{
          ml: "40px",
          display: "flex",
          gap: "16px",
        }}
      >
        <img src={daiLogo} alt="" width="33" height="39" />
        <img src={deciphrLogo} alt="Deciphr AI" width="134" height="37" />
      </Link> */}

      {isMobile && (
        <Box sx={{ textAlign: "center", mb: "6px" }}>
          <img src={logo} alt="" width="128" height="47" />
        </Box>
      )}

      {location.pathname.includes("/auth/login") && (
        <Box
          sx={{
            maxWidth: {
              sm: "220px",
              md: "330px",
              lg: "450px",
              xl: "560px",
            },

            m: "48px auto 0",
            textAlign: "center",
          }}
        >
          <TestimonialHeading>Your one-click content team.</TestimonialHeading>

          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 500,
              color: "#FFF",
              lineHeight: "160%",
            }}
          >
            We've helped podcasters around the globe save over 200,000 hours in
            content creation. Join the ranks of top brands who've experienced
            what a single Deciphr upload can create and never looked back.
          </Typography>
        </Box>
      )}

      <Suspense fallback={<></>}>
        {location.pathname.includes("/auth/signup") && <CountdownTimer />}

        <Box
          sx={{
            maxWidth: "480px",
            height: "512px",
            overflow: "hidden",
            mx: "auto",
            flex: 1,
          }}
        >
          <UserReviews reviews={userReviews} />
        </Box>
      </Suspense>

      <SecurityBadge />

      <BrandLogos />
    </Box>
  );
}
