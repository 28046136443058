/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { rollbar } from "Core/helpers/rollbar";
import {
  checkAudioGramStatus,
  exportContent,
  generateAudiogram,
  getSubtitles,
  saveAudiogramTrasncript,
  updateAudiogramBackground,
  uploadLogo,
} from "DAI/ProcessedData/services/apis";
import { getBackgrounds } from "DAI/ProcessedData/services/audiogramApis";
import { downloadVideo } from "pages/newViewTranscript/audiogram/downloadUtil";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";

const audiogramSlice = createSlice({
  name: "audiogram",
  initialState: {
    docId: "",
    audigramMeta: {},
    audioFile: "",
    selectedVideo: "",
    openAudioGramPreview: false,
    audiogram: [],
    audiogramBackground: [],
    time: 0,
    progress: 0,
    downloadLoader: null,
    isAudiogramAvailable: false,
    isAudiogramGenerated: false,
    updateLoader: false,
    listOfPendingAudiogramsId: [],
    currentTime: "",
    isUpdatingSubtitles: false,
    selectedAuidogramSubtitle: {},
    exitModal: false,
  },

  reducers: {
    setOpenAudioGramPreview: (state, action) => {
      state.openAudioGramPreview = action.payload.isOpen;
      state.audigramMeta = action.payload.meta;
    },

    selectedVideo: (state, action) => {
      state.selectedVideo = action.payload;
    },

    setAudiogramData: (state, action) => {
      state.audiogram = action.payload.audiogram;
      state.isAudiogramAvailable = (action.payload.audiogram && action.payload.audiogram.length > 0);
      state.audioFile = action.payload.audioFile;
      state.audiogramBackground = action.payload.audiogramBackground;
      state.selectedVideo = action.payload.selectedVideo;
      state.docId = action.payload.docId;
      state.isAudiogramGenerated = action.payload.audiogram?.some((v) => v.video_url);
    },

    resetAudiogram: (state) => {
      state.audiogram = [];
      state.isAudiogramAvailable = false;
      state.audioFile = "";
      state.audiogramBackground = [];
      state.selectedVideo = "";
      state.docId = "";
      state.isAudiogramGenerated = false;
    },

    setAudiogram: (state, action) => {
      state.audiogram = action.payload;
    },

    setTime: (state, action) => {
      state.time = action.payload;
    },

    setProgress: (state, action) => {
      state.progress = action.payload;
    },

    setLoading: (state, action) => {
      state.downloadLoader = action.payload;
    },

    setUpdateLoader: (state, action) => {
      state.updateLoader = action.payload;
    },

    updateAudiogramTransciprt: (state, action) => {
      const {
        id: audiogramId,
        grouped_words: changedTranscript,
        ...additionalPayload
      } = action.payload;

      const updatedAudiogram = state.audiogram.map((audiogram) => {
        if (audiogram.id !== audiogramId) {
          return audiogram;
        }

        const constructTextFromGroupedWords = changedTranscript
          .map((v) => v.text)
          .join(" ");

        return {
          ...audiogram,
          ...additionalPayload,
          grouped_words: changedTranscript,
          text: constructTextFromGroupedWords,
        };
      });
      state.audiogram = updatedAudiogram;
    },

    updateAllAudiogramTemplate: (state, action) => {
      const { payload } = action;
      const updatedAudiogram = state.audiogram.map((audiogram) => ({
        ...audiogram,
        ...payload,
      }));

      state.audiogram = updatedAudiogram;
    },

    listOfPendingAudiogramsId: (state, action) => {
      state.listOfPendingAudiogramsId = action.payload;
    },

    setSelectedAudiogramSubtitle: (state, action) => {
      state.selectedAuidogramSubtitle = action.payload;
    },

    setIsUpdatingSubtitles: (state, action) => {
      state.isUpdatingSubtitles = action.payload;
    },

    setExitModal: (state, action) => {
      state.exitModal = action.payload;
    },
  },
});

export const {
  setOpenAudioGramPreview,
  setAudioFile,
  selectedVideo,
  setAudiogramData,
  resetAudiogram,
  setAudiogram,
  setTime,
  setProgress,
  setLoading,
  updateAudiogramTransciprt,
  setUpdateLoader,
  listOfPendingAudiogramsId,
  setIsUpdatingSubtitles,
  setSelectedAudiogramSubtitle,
  setExitModal,
  updateAllAudiogramTemplate,
} = audiogramSlice.actions;

export const openAudioGramPreviewSelector = (state) => state.audiogram.openAudioGramPreview;
export const audigramMetaSelector = (state) => state.audiogram.audigramMeta;
export const audioFileSelector = (state) => state.audiogram.audioFile;
export const selectedVideoSelector = (state) => state.audiogram.selectedVideo;
export const audiogramSelector = (state) => state.audiogram.audiogram;
export const audiogramBackgroundSelector = (state) => state.audiogram.audiogramBackground;
export const searchAudioGramSelector = (state) => state.audiogram.search;
export const timeSelector = (state) => state.audiogram.time;
export const progressSelector = (state) => state.audiogram.progress;
export const downloadLoaderSelector = (state) => state.audiogram.downloadLoader;
export const isAudiogramAvailableSelector = (state) => state.audiogram.isAudiogramAvailable;
export const isAudiogramGeneratedSelector = (state) => state.audiogram.isAudiogramGenerated;
export const updateLoaderSelector = (state) => state.audiogram.updateLoader;
export const listOfPendingAudiogramsIdSelector = (state) => state.audiogram.listOfPendingAudiogramsId;
export const setCurrentWordSelector = (state) => state.audiogram.currentWord;
export const isUpdatingSubtitlesSelector = (state) => state.audiogram.isUpdatingSubtitles;
export const exitModalSelector = (state) => state.audiogram.exitModal;
export const isAudiogramsAvailable = (state) => {
  const { audiogram } = state.audiogram;

  return audiogram !== null && audiogram?.length > 0;
};

export const checkAllAudiogramStatus = createAsyncThunk("audiogram/status", async (docId, thunkAPI) => {
  const { dispatch } = thunkAPI;

  try {
    const { status } = await checkAudioGramStatus(docId);

    if (!status) return;

    const pendingList = status.map((v) => {
      if (v.status === "Pending" || v.status === "Reprocessing") {
        return v.id;
      }

      return null;
    }).filter((id) => id !== null);

    dispatch(listOfPendingAudiogramsId(pendingList));
  } catch (e) {
    dispatch(setToast({ message: e.message, severity: "error" }));
  }
});

export const initAudiograms = createAsyncThunk("audiogram/init", async (payload, thunkAPI) => {
  const { audiogram, audioFile, docId } = payload;
  const { dispatch } = thunkAPI;

  try {
    const backgrounds = await getBackgrounds();

    dispatch(
      setAudiogramData({
        audiogram,
        audioFile,
        audiogramBackground: backgrounds,
        docId,
      }),
    );

    if (audiogram && audiogram.length > 0) {
      dispatch(checkAllAudiogramStatus(docId));
    }
  } catch (e) {
    rollbar.error("Failed to fetch audiogram backgrounds", {
      error: e?.response?.data,
    });
  }
});

export const genAudiogram = createAsyncThunk("audiogram/generateAudiogram", async (payload, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { audiogram } = getState();
  const { docId } = audiogram;

  dispatch(setLoading(payload.audiogram_id));

  try {
    const res = await generateAudiogram(docId, payload);
    downloadVideo(res?.video_url || "", "Quote");
  } catch (e) {
    dispatch(setToast({ message: e.message, severity: "error" }));
  }

  dispatch(setLoading(null));
});

export const changeBackground = createAsyncThunk("audiogram/changeBg", async (payload, thunkAPI) => {
  const { docId, backgroundId } = payload;
  const { dispatch } = thunkAPI;

  try {
    await updateAudiogramBackground(docId, backgroundId);

    dispatch(checkAllAudiogramStatus(docId));
  } catch (e) {
    // passively ignore
  }
});

export const downloadAllAudiogram = createAsyncThunk(
  "audiogram/downloadAllAudiogram",
  async (type, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { audiogram } = getState();
    const { docId } = audiogram;
    const params = {
      audiogram: (type === "audiograms") ? 1 : 0,
      videoreels: (type === "video reels") ? 1 : 0,
      summary: 0,
      quotes: 0,
      chapters: 0,
      transcript: 0,
    };

    try {
      const tab = window.open("/download?all_audiograms", Date.now());
      const data = await exportContent({ docId, params });

      tab.focus();

      tab.location = data.url;

      if (type === "audiograms") {
        analytics.track("Download Audiogram");
      } else {
        analytics.track("Downloaded video reel");
      }

      setTimeout(() => {
        tab.close();
      }, 3000);
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
  },
);

export const saveAudiogramTranscrpt = createAsyncThunk(
  "audiogram/saveAudiogramTrasncript",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    dispatch(setUpdateLoader(payload.id));

    const state = getState();
    const { docId } = state.transcript;

    const fixfontColor = (color) => color.replace("#", "");

    const formatBrandedContent = (branded_content) => {
      const obj = { ...branded_content, font_color: fixfontColor(branded_content.font_color) };
      return obj;
    };

    const newPayload = {
      id: payload.id,
      aspect_ratio: payload.aspectRatio,
      apply_to_all: payload.apply_to_all,
      background_id: payload.background_id,
      platforms: payload.platforms,
      grouped_words: payload.grouped_words,
      font: payload.font,
      start: payload.start,
      end: payload.end,
      monotone_platforms: payload.monotone_platforms,
      font_color: fixfontColor(payload?.font_color),
      highlight_color: fixfontColor(payload?.highlight_color),
      branded_content: formatBrandedContent(payload?.branded_content),
    };

    try {
      if (payload.logoBlob) {
        await uploadLogo(docId, payload.id, payload.logoBlob);
      }

      await saveAudiogramTrasncript(docId, newPayload);

      dispatch(updateAudiogramTransciprt({
        ...newPayload,
        logo: { default_logo_url: payload.logo },
        grouped_words: payload.new_grouped_words,
      }));

      if (payload.apply_to_all) {
        const { grouped_words, start, end, id, ...payloadWithoutGroupedWords } = newPayload;
        dispatch(updateAllAudiogramTemplate({
          ...payloadWithoutGroupedWords,
          logo: { default_logo_url: payload.logo },
        }));
        analytics.track("Apply to all audiogram");
      } else {
        analytics.track("Audiogram Changes Saved");
      }

      setTimeout(() => {
        dispatch(checkAllAudiogramStatus(docId));
        dispatch(listOfPendingAudiogramsId([newPayload.id]));
      }, 100);

      dispatch(setToast({ message: "Successfully Updated", severity: "success", autoClose: true }));
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }

    dispatch(setUpdateLoader(false));
    dispatch(setExitModal(true));
  },
);

export const getAudioSubtitle = createAsyncThunk(
  "audiogram/getAudioSubtitle",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { media_type, id, content } = payload;
    dispatch(setIsUpdatingSubtitles(true));

    const fixfontColor = (color) => color.replace("#", "");

    const formatBrandedContent = (branded_content) => {
      const obj = { ...branded_content, font_color: fixfontColor(branded_content.font_color) };
      return obj;
    };

    const updatedContent = {
      id: content.id,
      aspect_ratio: content.aspectRatio,
      background_id: content.background_id,
      grouped_words: content.grouped_words,
      font: content.font,
      font_color: fixfontColor(content.font_color),
      highlight_color: fixfontColor(content.highlight_color),
      branded_content: formatBrandedContent(content.branded_content),
    };

    try {
      const subtitles = await getSubtitles(media_type, id, updatedContent);
      window?.octopusAudiogramSubtitleInstance?.freeTrack();
      window?.octopusAudiogramBrandInstance?.freeTrack();
      window?.octopusAudiogramSubtitleInstance?.setTrack(subtitles.main_subtitle);
      window?.octopusAudiogramBrandInstance?.setTrack(subtitles.brand_subtitle);
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    } finally {
      dispatch(setIsUpdatingSubtitles(false));
    }
  },
);

export default audiogramSlice.reducer;
