import googleTagManager from "@analytics/google-tag-manager";
import Analytics from "analytics";
import posthog from "posthog-js";

const pluginsArry = () => {
  const arry = [
    googleTagManager({
      containerId: process.env.REACT_APP_GMT_ID,
    }),
  ];
  return arry;
};

export const GTMAnalytics = Analytics({
  app: "deciphr_app",
  plugins: pluginsArry(),
});

/**
 *
 * @param {string} eventName Name of the FB Pixel event to trigger
 * @param {object} [props] Custom properties to attach to the event. Optional.
 */
export const GTMPixelTrack = (eventName, props) => {
  if (process.env.REACT_APP_ENV === "production") {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        props,
      });
    }
  }
};

export const GTM = {
  /**
   * Track the list of items being purchased for GTM Meta Pixel tracking
   *
   * @param {string} plan The subscription/add-on plan name
   * @param {number} add_on The add-on price
   * @param {boolean} isPayG Indicates a one-time purchase of an add-on, without a subscription plan
   */
  trackPurchasedItems: (plan, addOn) => {
    const items = [];
    const isPayG = !!addOn;

    if (isPayG) {
      if (addOn?.value) {
        items.push({ name: addOn.name, price: addOn.price });
      }
    } else {
      items.push({
        name: `${plan.name} (${plan.planCode})`,
        price: plan.amount,
      });
    }

    items.forEach((item) => {
      GTMPixelTrack("fb_purchase", {
        content_name: item.name,
        currency: "USD",
        num_items: 1,
        value: item.price,
      });
    });
  },
};

export const analytics = {
  /**
   *
   * @param {object} page properties of the page like url, title, etc
   */
  page: (page) => {
    GTMAnalytics.page(page);
  },
  /**
   * to track every event in Posthog and GTM
   * @param {string} event event name
   * @param {object} properties other event properties
   */
  track: (event, properties) => {
    if (process.env.REACT_APP_ENV === "production") {
      if (event.startsWith("$") === false) {
        // Posthog internal events start with a $ sign, don't send these to GTM
        GTMAnalytics.track(event, properties);
      }

      posthog.capture(event, properties);
    }
  },
  /**
   * to set user properties in Posthog and GTM
   * @param {string} userId unique user id
   * @param {object} properties user properties
   */
  identify: (userId, properties) => {
    if (process.env.REACT_APP_ENV === "production") {
      GTMAnalytics.identify(userId, properties);

      // PostHog
      posthog.identify(userId, properties);
    }
  },

  /**
   * to initialize Posthog on page load if Posthog is turned on
   * this is required to link the user in Posthog
   * conditionally initialize Posthog based on the environment variable
   */
  init: () => {
    if (process.env.REACT_APP_ENV === "production") {
      posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        person_profiles: "identified_only",
      });
    }
  },

  /**
   * to reset user in Posthog
   */
  resetUser: () => {
    if (process.env.REACT_APP_ENV === "production") {
      posthog.reset();
    }
  },
};
