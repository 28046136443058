import axios from "utils/axiosConfig";

export const uploadRecordedAudio = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/planner/upload",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getStatus = async (audioId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/planner/${audioId}/status`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPlannerContent = async (audioId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/planner/${audioId}/content`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPlannerList = async (params) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/planner",
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};


export const pendingFileStatus = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/planner/status`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}