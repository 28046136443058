import { rollbar } from "Core/helpers/rollbar";
import axios from "./axiosConfig";

export const signup = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/v1/signup",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const login = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/login",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const oAuthApi = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/oauth",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const forgotInit = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/login/reset-password-link",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const forgot = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/login/confirm-reset-password",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const verifyEmail = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/signup/confirm-email",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getFilesList = async (params) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/v2/up/all",
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const searchFiles = async (params) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/up/search",
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUnprocessedFileIds = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/up/unprocessed",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getInsights = async (id) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/up/details/${id}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const DelInsights = async (id) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/up/delete/${id}`,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const resendVerification = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/signup/resend-verification-email`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveSpeaker = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/up/update-speakers/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addSpeakerlist = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/up/speaker/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const delSpeakerList = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: `/up/speaker/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateSpeaker = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/up/update-speaker/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateTranscript = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/up/transcript/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const replaceAllTranscript = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/up/transcript/replace/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateSummaryChapters = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/up/processed_data/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAudiogramBackground = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/audiogram/backgrounds",
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const generateAudiogram = async (id, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/v1/audiogram/from-quotes/${id}`,
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateAudiogramBackground = async (docId, bgId) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/audiogram/update-background/${docId}/${bgId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadTranscript = async ({ docId, queryParams }) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/v1/up/download/${docId}`,
      params: queryParams,
      responseType: "blob",
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const exportContent = async ({ docId, params }) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/v2/up/export/${docId}`,
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateUserInfo = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/user/profile",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const changePassword = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/user/password",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getVerificationEmail = async (payload) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/user/email-verification",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const generateArticle = async ({
  docId,
  inspiration,
  context,
  type,
  format,
  tone,
}) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/article/generate/${docId}`,
      headers: {
        "Content-Type": "application/json",
      },

      data: {
        inspiration,
        context,
        type,
        format,
        tone,
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const checkArticalStatus = async (articleId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/article/status/${articleId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getArticle = async (articleId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/article/article/${articleId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllArticles = async (articleId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/article/${articleId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const payment = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/v3/payment/subscribe",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const resumePlanApi = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/payment/subscription/resume",
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const applyDiscount = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/payment/subscription/coupon",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const userInfo = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/v1/user/profile",
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const applyPromoCode = async (code, plan) => {
  rollbar.info("Promo code entered", { couponCode: code, plan });

  try {
    const { data } = await axios({
      method: "POST",
      url: "/v3/payment/coupon",
      data: {
        coupon: code,
        plan,
      },
    });

    rollbar.info("Promo code applied successfully", { couponCode: code, plan });

    return data;
  } catch (e) {
    rollbar.error("Promo code failed", {
      couponCode: code,
      plan,
      error: e?.config?.data,
      errorCode: e?.status,
    });

    return Promise.reject(e);
  }
};

export const createUpdateShareOutput = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/share",
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getShareOutput = async (id) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/share/${id}`,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPromoCodes = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/user/coupon",
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
