import axios from "utils/axiosConfig";

export const fetchCollabSeats = async () => {
  const { data } = await axios.get("/user/seats");

  return data.seats;
};

export const addCollabSeat = async (email) => {
  const { data } = await axios.post(
    "/user/seats",
    {
      email,
    },
  );

  return data;
};

export const removeCollabSeat = async (email) => {
  const { data } = await axios.post(
    "/user/seats/delete",
    {
      email,
    },
  );

  return data;
};

export const resendCollabSeat = async (email) => {
  const { data } = await axios.post(
    "/user/seats/resend-invitation",
    {
      email,
    },
  );

  return data;
};

export const signupCollabUser = async ({
  email,
  firstName,
  lastName,
  password,
}) => {
  const { data } = await axios.post(
    "/signup/seats",
    {
      email,
      first_name: firstName,
      last_name: lastName,
      password,
    },
  );

  return data;
};

export const fetchSeatProfile = async () => {
  const { data } = await axios.get("/user/seats/profile");

  return data;
};

export const updateSeatProfile = async (payload) => {
  const { data } = await axios.post("/user/seats/profile", {
    first_name: payload.firstName,
    last_name: payload.lastName,
  });

  return data;
};
