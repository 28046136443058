import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import theme from "theme";
import MuiButton from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

/**
 * @param {Object} props
 * @param {boolean} props.iconButton When `true` renders an icon button instead of a normal button
 */
export default function DAIButton({
  disableElevation,
  loadingPosition,
  size,
  ariaLabel,
  loading,
  fullWidth,
  startIcon,
  disabled,
  endIcon,
  show,
  variant,
  isBlock,
  destructive,
  children,
  title,
  type,
  iconButton,
  sx,
  onClick,
  ...props
}) {
  const baseStyle = {
    fontFamily: "Satoshi",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "transparent",
    transition: "all 0.3s ease-out",
    borderRadius: "999px",
    outline: 0,
    px: "20px",
    mx: "5px",
    textTransform: "none",
    width: isBlock ? "100%" : undefined,

    "&:disabled": {
      pointerEvents: "all",
    },
  };

  const contained = {
    bgcolor: destructive ? "#F44E45" : "#6552F3",
    color: "#fff",
    borderColor: destructive ? "#F44E45" : "#6552F3",

    "&:hover": {
      bgcolor: destructive ? "#D25649" : "#4C3CB5",
      color: "#fff",
      borderColor: destructive ? "#D25649" : "#4C3CB5",
      boxShadow: "none",
    },

    "&:disabled": {
      bgcolor: theme.palette.gray[300],
      filter: "grayscale(1)",
      color: "#fff",
      borderColor: theme.palette.gray[300],
      pointerEvents: "all",
      cursor: "not-allowed",

      "&:hover": {
        bgcolor: theme.palette.gray[300],
        borderColor: theme.palette.gray[300],
      },
    },
  };

  const outlined = {
    bgcolor: "transparent",
    color: destructive ? "#F44E45" : theme.palette.gray[800],
    textTransform: "underline",
    borderWidth: "1px",
    borderColor: destructive ? "#F44E45" : theme.palette.gray[300],

    "&:hover": {
      bgcolor: destructive ? "#F44E45" : theme.palette.brand[100],
      color: theme.palette.brand[500],
      borderColor: destructive ? "#F44E45" : theme.palette.brand[200],
      borderWidth: "1px",
      boxShadow: "none",
    },
  };

  const text = {
    bgcolor: "transparent",
    color: destructive ? "#F44E45" : "#6552F3",
    borderColor: "transparent",
    px: "2px",

    // We need !important here because some goddamned override
    // is defined in index.css. Thanks a lot, junior developer.
    "&:hover": {
      bgcolor: "transparent !important",
      color: destructive ? "#D25649 !important" : "#4C3CB5 !important",
      borderColor: "transparent !important",
      boxShadow: "none !important",
    },
  };

  const getStyle = () => {
    const style = [baseStyle];

    switch (variant) {
      case "contained":
        style.push(contained);
        break;
      case "outlined":
        style.push(outlined);
        break;
      case "text":
        style.push(text);
        break;
      default:
        style.push(contained);
    }

    if (iconButton) {
      style.push({
        px: "8px",
      });
    }

    if (Array.isArray(sx)) {
      style.push(...sx);
    } else if (sx && typeof sx === "object") {
      style.push(sx);
    }

    return style;
  };

  if (show === false) {
    return null;
  }

  if (iconButton) {
    return (
      <IconButton
        variant={variant || "contained"}
        aria-label={ariaLabel}
        size={size || "large"}
        type={type || "button"}
        title={title}
        disabled={disabled}
        loading={loading}
        LinkComponent={props.LinkComponent}
        href={props.href}
        target={props.target}
        rel={props.rel}
        sx={getStyle()}
        onClick={onClick}
      >
        {children}
      </IconButton>
    );
  }

  if (loading !== undefined) {
    return (
      <LoadingButton
        variant={variant || "contained"}
        aria-label={ariaLabel}
        size={size || "large"}
        type={type || "button"}
        title={title}
        disabled={disabled}
        loading={loading}
        loadingPosition={loadingPosition}
        disableElevation={disableElevation}
        startIcon={startIcon}
        endIcon={endIcon}
        sx={getStyle()}
        fullWidth={fullWidth}
        onClick={onClick}
      >
        {children}
      </LoadingButton>
    );
  }

  return (
    <MuiButton
      variant={variant || "contained"}
      aria-label={ariaLabel}
      size={size || "large"}
      type={type || "button"}
      title={title}
      disabled={disabled}
      disableElevation={disableElevation}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={getStyle()}
      fullWidth={fullWidth}
      LinkComponent={props.LinkComponent}
      href={props.href}
      target={props.target}
      rel={props.rel}
      onClick={onClick}
    >
      {children}
    </MuiButton>
  );
}

DAIButton.propTypes = {
  disableElevation: PropTypes.bool,
  loadingPosition: PropTypes.string,
  size: PropTypes.string,
  ariaLabel: PropTypes.string,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  variant: PropTypes.string,
  isBlock: PropTypes.bool,
  destructive: PropTypes.bool,
  children: PropTypes.elementType,
  title: PropTypes.string,
  type: PropTypes.string,
  iconButton: PropTypes.elementType,
  href: PropTypes.string,
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
  rel: PropTypes.oneOf(["noopener", "noreferrer"]),
  LinkComponent: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.any,
  onClick: PropTypes.func,
};

DAIButton.defaultProps = {
  disableElevation: undefined,
  loadingPosition: undefined,
  size: null,
  ariaLabel: null,
  loading: undefined,
  fullWidth: false,
  startIcon: undefined,
  endIcon: undefined,
  disabled: false,
  show: true,
  variant: "contained",
  isBlock: false,
  destructive: false,
  children: undefined,
  title: "",
  type: "",
  sx: [],
  iconButton: undefined,
  href: undefined,
  target: undefined,
  rel: undefined,
  LinkComponent: undefined,
  onClick: () => { },
};
