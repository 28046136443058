import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { differenceBy, truncate } from "lodash";
import { getStatus } from "DAI/Library/components/LibraryFileCard/statuses";
import { reprocessFile } from "DAI/Library/services/apis";
import {
  createFolder as createFolderAPI,
  deleteFiles as deleteFilesAPI,
  deleteFolder as deleteFolderAPI,
  getFolder as getFolderAPI,
  GetFolderFilter, GetFolderSort,
  moveFileIntoFolder as moveFileIntoFolderAPI,
  moveFilesIntoFolder,
  renameFolder as renameFolderAPI,
} from "DAI/Library/services/library";
import { setToast } from "store/toastSlice";
import { DelInsights } from "utils/apis";
import { removeFileFromSearch } from "./librarySearchSlice";

const DEFAULT_ITEMS_PER_PAGE_LIMIT = 10;
const ROOT_FOLDER = "root";

export const LibraryTabs = {
  Files: "files",
  Folders: "folders",
};

const defaultFolderParams = {
  folderId: "",
  till: DEFAULT_ITEMS_PER_PAGE_LIMIT,
  limit: DEFAULT_ITEMS_PER_PAGE_LIMIT,
  sort: GetFolderSort.DateDesc,
  filter: GetFolderFilter.All,
};

function getCurrTabFromUrl() {
  return new URLSearchParams(window.location.search).get("tab");
}

function getCurrFolderId(breadcrumbs) {
  return breadcrumbs[
    breadcrumbs.length - 1
  ].folderId;
}

const librarySlice = createSlice({
  name: "library",
  initialState: {
    files: {
      root: [],
    },
    selectedCount: 0,
    selectedFiles: {},
    folders: {
      root: [],
    },
    details: {
      root: {
        createdAt: null,
        id: "root",
        name: "root",
        parentId: null,
        updatedAt: null,
      },
    },
    folderParams: {
      root: {
        ...defaultFolderParams,
        folderId: "root",
      },
    },
    allFilesFetched: {
      root: false,
    },
    allFoldersFetched: false,
    isFetching: false,
    currTab: getCurrTabFromUrl(),
    breadcrumbs: [{
      label: "Folders",
      folderId: "root",
      to: "/library?tab=folders",
    }],
  },
  reducers: {
    /**
     * @param {Object} action
     * @param {Object} action.payload
     * @param {string} action.payload.folderId
     * @param {Record<string, any>} action.payload.details
     * @param {Record<string, any>[]} action.payload.files
     * @param {Record<string, any>[]} action.payload.folders
     */
    setFolder(state, action) {
      const {
        folderId,
        details,
        files,
        folders,
      } = action.payload;

      if (files) {
        state.files[folderId] = files;
      }

      state.details[folderId] = details;

      if (folders) {
        state.folders[folderId] = folders;
      }
    },

    /**
     * @param {Object} action
     * @param {Object} action.payload
     * @param {string} action.payload.folderId
     * @param {Record<string, any>} action.payload.params
     */
    setFolderParams(state, action) {
      const {
        folderId,
        params,
      } = action.payload;

      state.folderParams[folderId] = {
        ...state.folderParams[folderId],
        ...params,
        folderId,
      };
    },

    /**
     * @param {string} Id of the folder
     */
    initFolderParams(state, action) {
      if (!state.folderParams[action.payload]) {
        state.folderParams[action.payload] = {
          ...defaultFolderParams,
          folderId: action.payload,
        };
      }
    },

    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.name Name of the folder to add
     * @param {string} action.payload.id Id of the folder
     */
    addNewFolder(state, action) {
      if (!state.folders[ROOT_FOLDER]) {
        state.folders[ROOT_FOLDER] = [];
      }

      state.folders[ROOT_FOLDER].unshift({
        id: action.payload.id,
        name: action.payload.name,
        createdAt: Date.now(),
        parentId: "root",
        totalFiles: 0,
      });
    },

    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.folderId
     * @param {string} action.payload.newName
     */
    changeFolderName(state, action) {
      state.folders[ROOT_FOLDER] = state.folders[ROOT_FOLDER]
        .map((folder) => {
          if (action.payload.folderId === folder.id) {
            return {
              ...folder,
              name: action.payload.newName,
            };
          }

          return folder;
        });
    },

    /**
     * @param {object} action
     * @param {string} action.payload Id of the folder to delete
     */
    removeFolder(state, action) {
      state.folders[ROOT_FOLDER] = state.folders[ROOT_FOLDER]
        .filter((folder) => (
          action.payload !== folder.id
        ));
    },

    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.folderId
     * @param {string} action.payload.fileId
     */
    removeFile(state, action) {
      const {
        fileId,
        folderId,
      } = action.payload;

      if (!state.files[folderId]) {
        return;
      }

      state.files[folderId] = state.files[folderId].filter(
        (file) => (file.id !== fileId),
      );
    },

    /**
     * @param {object} action
     * @param {folderId} action.payload.folderId
     * @param {Record<string, any>} action.payload.files
     */
    removeFiles(state, action) {
      const {
        folderId,
        files: filesToDelete,
      } = action.payload;

      state.files[folderId] = differenceBy(
        state.files[folderId],
        filesToDelete,
        (item) => (item.id),
      );
    },

    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.folderId
     * @param {boolean} action.payload.isFetched
     */
    setAllFilesFetched(state, action) {
      state.allFilesFetched[
        action.payload.folderId
      ] = action.payload.isFetched;
    },

    setAllFoldersFetched(state, action) {
      state.allFoldersFetched = action.payload;
    },

    /**
     * @param {object} action
     * @param {folderId} action.payload Id of the folder
     */
    incrementPage(state, action) {
      const folderId = action.payload
        || getCurrFolderId(state.breadcrumbs);

      if (state.folderParams[folderId]) {
        state.folderParams[folderId].till += state
          .folderParams[folderId].limit;
      }
    },

    /**
     * @param {object} action
     * @param {string} action.payload Id of the folder
     */
    resetPagination(state, action) {
      const folderId = action.payload
        || getCurrFolderId(state.breadcrumbs);

      state.folderParams[folderId].till = DEFAULT_ITEMS_PER_PAGE_LIMIT;
      state.folderParams[folderId].limit = DEFAULT_ITEMS_PER_PAGE_LIMIT;
    },

    /**
     * @param {object} action
     * @param {string} action.payload Id of the folder
     */
    clearFolderData(state, action) {
      const folderId = action.payload
        || getCurrFolderId(state.breadcrumbs);

      state.folders[folderId] = [];
      state.files[folderId] = [];
    },

    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },

    updateFileStatusToPartiallyProcessed: (state, action) => {
      const {
        folderId,
        fileId,
      } = action.payload;

      const index = state.files[folderId]?.findIndex(
        (file) => file.id === fileId,
      );

      if (index > -1) {
        state.files[folderId][index].viewable = true;
      }
    },

    updateFileStatusToProcessed: (state, action) => {
      const {
        folderId,
        fileId,
      } = action.payload;

      const index = state.files[folderId]?.findIndex(
        (file) => file.id === fileId,
      );

      if (index > -1) {
        state.files[folderId][index].processed = "Y";
      }
    },

    updateReprocessedTime: (state, action) => {
      const {
        folderId,
        fileId,
      } = action.payload;

      const file = state.files[folderId].find(
        (f) => f.id === fileId,
      );

      if (file) {
        state.files[folderId] = state.files[folderId].map((f) => {
          if (f.id === file.id) {
            file.reprocessedAt = dayjs().subtract("30", "minutes").toDate().getTime();

            return file;
          }

          return f;
        });
      }
    },

    setCurrTab(state, action) {
      state.currTab = action.payload;
    },

    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.label
     * @param {string} action.payload.folderId
     * @param {string} action.payload.link
     */
    openFolder(state, action) {
      if (state.breadcrumbs.length < 2) {
        state.breadcrumbs.push(action.payload);
      } else {
        state.breadcrumbs[state.breadcrumbs.length - 1] = action.payload;
      }
    },

    closeCurrFolder(state) {
      if (state.breadcrumbs.length > 1) {
        state.breadcrumbs.pop();
      }
    },

    /**
     * @param {object} action
     * @param {object} action.payload File object
     */
    selectFile(state, action) {
      state.selectedFiles[action.payload.id] = action.payload;
      state.selectedCount += 1;
    },

    /**
     * @param {object} action
     * @param {object} action.payload File object
     */
    unselectFile(state, action) {
      delete state.selectedFiles[action.payload.id];
      state.selectedCount -= 1;
    },

    clearSelectedFiles(state) {
      state.selectedFiles = {};
      state.selectedCount = 0;
    },

    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {Record<string, any>[]} action.payload.files
     * @param {string} action.payload.parentFolderId
     * @param {string} action.payload.targetFolderId
     */
    moveFiles(state, action) {
      const {
        files,
        parentFolderId,
        targetFolderId,
      } = action.payload;

      if (state.files[parentFolderId]) {
        state.files[parentFolderId] = differenceBy(
          state.files[parentFolderId],
          files,
          (item) => item.id,
        );
      }

      if (state.files[targetFolderId]) {
        state.files[targetFolderId].unshift(...files);
      }

      state.folders[ROOT_FOLDER] = state.folders[ROOT_FOLDER].map((folder) => {
        if (folder.id === parentFolderId) {
          folder.totalFiles -= files.length;
        }

        if (folder.id === targetFolderId) {
          folder.totalFiles += files.length;
        }

        return folder;
      });
    },
  },
});

export const {
  setFolder,
  addNewFolder,
  changeFolderName,
  removeFolder,
  setFolderParams,
  initFolderParams,
  setFileParams,
  removeFile,
  setAllFilesFetched,
  setAllFoldersFetched,
  incrementPage,
  resetPagination,
  setIsFetching,
  updateFileStatusToPartiallyProcessed,
  updateFileStatusToProcessed,
  updateReprocessedTime,
  openFolder,
  closeCurrFolder,
  setCurrTab,
  clearFolderData,
  selectFile,
  unselectFile,
  clearSelectedFiles,
  removeFiles,
  moveFiles,
} = librarySlice.actions;

export const selectLibrarySlice = (state) => state.library;
export const foldersSelector = (state) => state.library.folders;
export const selectBreadcrumbs = (state) => state.library.breadcrumbs;
export const selectedFilesSelector = (state) => state.library.selectedFileIds;
export const folderParamsSelector = (state) => state.library.folderParams;
export const currFolderIdSelector = (state) => (
  getCurrFolderId(state.library.breadcrumbs)
);
export const currFilesSelector = (state) => (
  state.library.files[getCurrFolderId(state.library.breadcrumbs)]
);
export const currFoldersSelector = (state) => (
  state.library.folders[getCurrFolderId(state.library.breadcrumbs)]
);
export const currTabSelector = (state) => state.library.currTab;

export const getFolder = createAsyncThunk(
  "library/getFolder",
  /**
   * @param {Object} [params]
   * @param {"all" | "files" | "folders"} [params.mode] Choose which part to update
   * @param {string} params.folderId Id of the folder to fetch
   */
  async (params, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { library: state } = getState();
    const {
      mode = "all",
      folderId,
    } = params;

    const folderParams = state.folderParams[params.folderId];

    if (!folderParams) {
      return null;
    }

    try {
      const newLimit = folderParams.till + folderParams.limit;

      dispatch(setIsFetching(true));
      const res = await getFolderAPI({
        folderId: folderParams.folderId,
        start: 0,
        limit: newLimit,
        sort: folderParams.sort,
        filter: folderParams.filter,
      });
      const newFolder = {
        folderId,
        files: [],
        folders: [],
      };

      if (mode === "all" || mode === "files") {
        newFolder.files = res.children.files;

        dispatch(setFolder({
          folderId,
          details: res.details,
          files: newFolder.files,
        }));
      }

      if (mode === "all" || mode === "folders") {
        newFolder.folders = res.children.folders;

        dispatch(setFolder({
          folderId,
          details: res.details,
          folders: newFolder.folders,
        }));
      }

      if (
        res.children.files.length < newLimit
      ) {
        dispatch(setAllFilesFetched({
          folderId,
          isFetched: true,
        }));
      } else {
        dispatch(setAllFilesFetched({
          folderId,
          isFetched: false,
        }));
      }

      if (
        res.children.folders.length < folderParams.limit
      ) {
        dispatch(setAllFoldersFetched(true));
      } else {
        dispatch(setAllFoldersFetched(false));
      }

      dispatch(setIsFetching(false));

      const currTab = getCurrTabFromUrl();

      if (currTab === LibraryTabs.Files) {
        const hasFailedFiles = res.children.files.find((file) => {
          const status = getStatus(file.reprocessedAt);
          const daysPassed = dayjs().diff(file.reprocessedAt, "days");

          return file.processed === "N" && status?.key === "failed" && daysPassed <= 3;
        });

        if (hasFailedFiles) {
          dispatch(
            setToast({
              message:
                "One or more of your files failed to process. Don't "
                + "worry, your upload credits weren't used.",
              severity: "error",
            }),
          );
        }
      }

      return res;
    } catch (err) {
      dispatch(setIsFetching(false));
      return null;
    }
  },
);

/**
 * Create a new folder
 *
 * @param {string} name Name of the folder
 */
export const createFolder = createAsyncThunk(
  "library/createFolder",
  async (name, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      dispatch(setToast({
        message: `Creating folder ${name}`,
        severity: "info",
        autoClose: true,
      }));

      const res = await createFolderAPI({ name });

      dispatch(setToast({
        message: `Created ${name}`,
        severity: "success",
        autoClose: true,
      }));

      dispatch(addNewFolder({
        name,
        id: res.folderId,
      }));

      window.dispatchEvent(new Event("refreshFolders"));

      return res;
    } catch (err) {
      dispatch(setToast({
        message: "Creating folder failed",
        severity: "error",
        autoClose: true,
      }));

      return null;
    }
  },
);

export const renameFolder = createAsyncThunk(
  "library/renameFolder",
  /**
   * @param {object} params
   * @param {string} params.folderId Id of the folder to change
   * @param {string} params.newName New name for the folder
   */
  async (params, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      dispatch(changeFolderName({
        folderId: params.folderId,
        newName: params.newName,
      }));

      const res = await renameFolderAPI(params);

      return res;
    } catch (err) {
      dispatch(setToast({
        message: "Renaming folder failed",
        severity: "error",
        autoClose: true,
      }));

      return null;
    }
  },
);

export const deleteFolder = createAsyncThunk(
  "library/deleteFolder",
  /**
   * @param {object} params
   * @param {string} params.folderId Id of the folder to delete
   * @param {string} params.name Name of the folder
   */
  async (params, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const name = truncate(params.name, { length: 15 });

    try {
      dispatch(removeFolder(params.folderId));
      const res = await deleteFolderAPI(params.folderId);

      dispatch(setToast({
        message: `Deleted ${name}`,
        severity: "success",
        autoClose: true,
      }));

      window.dispatchEvent(new Event("refreshFolders"));

      return res;
    } catch (err) {
      dispatch(setToast({
        message: err?.response?.error || `Deleting ${name} failed`,
        severity: "error",
        autoClose: true,
      }));

      return null;
    }
  },
);

export const deleteFile = createAsyncThunk(
  "library/deleteFile",
  async (id, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { library } = getState();
    const currFolderId = getCurrFolderId(library.breadcrumbs);

    dispatch(removeFileFromSearch(id));
    dispatch(removeFile({
      folderId: currFolderId,
      fileId: id,
    }));

    try {
      await DelInsights(id);

      dispatch(
        setToast({
          message: "File was deleted successfully.",
          severity: "success",
          autoClose: true,
        }),
      );
    } catch (e) {
      dispatch(
        setToast({
          message: e?.response?.error || "Deleting file failed",
          severity: "error",
          autoClose: true,
        }),
      );
    }
  },
);

export const deleteFiles = createAsyncThunk(
  "library/deleteFiles",
  /**
   * @param {object} payload
   * @param {string} payload.folderId
   * @param {Record<string, any>} payload.files
   */
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      folderId,
      files,
    } = payload;

    dispatch(removeFileFromSearch(files));
    dispatch(removeFiles({
      folderId,
      files,
    }));
    dispatch(clearSelectedFiles());

    try {
      const fileIds = files.map((file) => (file.id));

      await deleteFilesAPI(fileIds);

      dispatch(
        setToast({
          message: "Files deleted successfully.",
          severity: "success",
          autoClose: true,
        }),
      );
    } catch (e) {
      dispatch(
        setToast({
          message: e?.response?.error || "Deleting files failed",
          severity: "error",
          autoClose: true,
        }),
      );
    }
  },
);

export const reprocess = createAsyncThunk(
  "library/reprocess",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      await reprocessFile(payload.fileId);

      dispatch(updateReprocessedTime({
        folderId: payload.folderId,
        fileId: payload.fileId,
      }));
    } catch (e) {
      dispatch(setToast({
        message: "Reprocessing file failed",
        severity: "error",
        autoClose: true,
      }));
    }
  },
);

export const moveFileToFolder = createAsyncThunk(
  "library/moveFileIntoFolder",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { library } = getState();
    const currFolderId = getCurrFolderId(library.breadcrumbs);

    try {
      dispatch(moveFiles(
        [{ id: payload.fileId }],
        currFolderId,
        payload.folderId,
      ));
      await moveFileIntoFolderAPI({
        fileId: payload.fileId,
        folderId: payload.folderId,
      });

      dispatch(setToast({
        message: "File moved successfully",
        severity: "success",
        autoClose: true,
      }));
    } catch (e) {
      dispatch(setToast({
        message: "Error moving file",
        severity: "error",
        autoClose: true,
      }));
    }
  },
);

export const moveMultipleFilesIntoFolder = createAsyncThunk(
  "library/moveMultipleFilesIntoFolder",
  /**
   * @param {object} payload
   * @param {number[]} payload.files
   * @param {string} payload.folderId
   */
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { library } = getState();
    const currFolderId = getCurrFolderId(library.breadcrumbs);

    try {
      dispatch(moveFiles({
        files: payload.files,
        parentFolderId: currFolderId,
        targetFolderId: payload.folderId,
      }));

      dispatch(setToast({
        message: "Moving files",
        severity: "info",
        autoClose: true,
      }));

      await moveFilesIntoFolder({
        fileIds: payload.files.map((file) => file.id),
        folderId: payload.folderId,
      });

      dispatch(setToast({
        message: "Files moved successfully",
        severity: "success",
        autoClose: true,
      }));
    } catch (e) {
      dispatch(setToast({
        message: "Error moving file",
        severity: "error",
        autoClose: true,
      }));
    }
  },
);

export const moveMultipleFilesIntoLibrary = createAsyncThunk(
  "library/moveMultipleFilesIntoLibrary",
  /**
   * @param {object} payload
   * @param {number[]} payload.files
   */
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { library } = getState();
    const currFolderId = getCurrFolderId(library.breadcrumbs);

    try {
      dispatch(moveFiles({
        files: payload.files,
        parentFolderId: currFolderId,
        targetFolderId: "root",
      }));
      await moveFilesIntoFolder({
        fileIds: payload.files.map((item) => (item.id)),
        folderId: "root",
      });

      dispatch(setToast({
        message: "Files moved back to library",
        severity: "success",
        autoClose: true,
      }));
    } catch (e) {
      dispatch(setToast({
        message: "Error moving file",
        severity: "error",
        autoClose: true,
      }));
    }
  },
);

export default librarySlice.reducer;
