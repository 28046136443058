import axios from "utils/axiosConfig";

export const createPage = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/pages",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updatePage = async (pageId, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: "/pages/" + pageId,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deletePage = async (pageId) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: "/pages/" + pageId,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPage = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/pages",
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPagesFiles = async (prams) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/pages/files",
      params: prams,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const uploadImage = async (payload) => {
  const formData = new FormData();
  formData.append("image", payload);

  try {
    const { data } = await axios({
      method: "POST",
      url: "/pages/upload_image",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
