import awsLogo from "assets/logos/aws-logo.png";
import dropboxLogo from "assets/logos/dropbox-logo.png";
import googleLogo from "assets/logos/google-logo.png";
import paypalLogo from "assets/logos/paypal-logo.png";
import spotifyLogo from "assets/logos/spotify-logo.png";

export const partnerLogos = [
   { name: "Google", src: googleLogo },
   { name: "Spotify", src: spotifyLogo },
   { name: "PayPal", src: paypalLogo },
   { name: "Dropbox", src: dropboxLogo },
   { name: "Amazon Web Services", src: awsLogo },
];
