import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { getSubscriptionPlan, userInfoSelector } from "store/user";

dayjs.extend(isSameOrAfter);

// Do not redirect to onboarding from any of these paths
const matchedPaths = [
  "/user-onboarding",
  "user-onboarding-icp",
  "/user-onboarding-success",
  "/verify-email",
  "/success",
];

// const signupDate = new Date("2023-08-08");

export default function OnboardingNagger() {
  const { email, completedOnboarding } = useSelector(userInfoSelector);
  const userPlan = useSelector(getSubscriptionPlan);
  // const cards = useSelector(selectCards);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      if (userInfo?.user.emailVerified === false) {
        if (matchedPaths.indexOf(location.pathname) === -1) {
          navigate("/verify-email");

          return;
        }
      }

      if (completedOnboarding === false) {
        // dayjs(userInfo.createdAt).isSameOrAfter(signupDate)
        if (userPlan === "FREE") {
          if (matchedPaths.indexOf(location.pathname) === -1) {
            navigate("/user-onboarding");
          }
        }
      }
    }
  }, [completedOnboarding, email, location.pathname, userPlan, navigate]);
}
