import axiosClient from "utils/axiosConfig";

export const getYTLinks = async (url, limit = 1) => {
  try {
    const { data } = await axiosClient({
      method: "GET",
      url: "/video_transcription/youtube",
      params: { url, limit, pageToken: null },
    });

    return data.videos?.[0] || null;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const processYTVideo = async (payload) => {
  try {
    const { data } = await axiosClient({
      method: "POST",
      url: "/video_transcription/youtube/process",
      data: {
        videos: [payload],
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const isYTVideoWithinReasonableLength = (duration) => duration <= 7200;

export const sendToActiveCampaign = () => async (payload) => {
  try {
    const { data } = await axiosClient({
      method: "POST",
      url: "/user/onboarding/data",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
