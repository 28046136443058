export const regenerateContent = async (text, action) => {
  const isDev = (process.env.REACT_APP_ENV === "development");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let token;

  if (userInfo && userInfo.token) {
    token = `Bearer ${userInfo.token}`;
  }

  try {
    const pathPrefix = isDev ? "dai_api/" : "";
    const url = `${process.env.REACT_APP_BACKEND_URL}${pathPrefix}up/assist`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "text/stream",
        Authorization: token,
      },
      body: JSON.stringify({ text, action }),
    });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const sectionToContentMap = {
  0: { value: "show_notes", label: "Show Notes" },
  1: { value: "abstract", label: "Summary" },
  2: { value: "quotes", label: "Quotes" },
  3: { value: "insight", label: "Chapters" },
  4: { value: "transcript", label: "Transcript" },
  5: { value: "article", label: "Article" },
  6: { value: "tweet", label: "Tweet Thread" },
  7: { value: "caption", label: "Captions" },
  8: null,
  9: null,
  10: null,
  11: null,
  12: null,
};
