import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { regenerateContent } from "DAI/Regenerate/services";
import { incrementRegeneratesUsed } from "store/user";
import { setToast } from "store/toastSlice";

const regenerateSlice = createSlice({
  name: "regenerate",
  initialState: {
    isLoading: false,
    prompt: "",
    action: "", // shortcut options from AI Assistant
    output: "",
    outputMode: "", // prompt or revert
    /*
    * history keeps track of the originally generated content output
    * whenever a user decides to replace it with content generated
    * by the AI Assistant. This is an escape hatch for users to
    * revert to the OG content in case they don't like the new output.
    *
    * The history object maps file IDs and their content types:
    *
    * history: {
    *   file_id_1: {
    *     showNotes: "<markdown content>",
    *     abstract: "<markdown content>",
    *     quotes: "<markdown content>",
    *     insights: "<markdown content>",
    *     article: "<markdown content>",
    *     tweet: "<markdown content>",
    *     caption: "<markdown content>",
    *   },
    * }
    *
    * If a file ID or a content type is absent, then there's
    * no history of that file/content type.
    */
    history: {},
  },

  reducers: {
    openAIAssistant: (state) => {
      state.isAIAssistantOpen = true;
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setIsHistoryLoading: (state, action) => {
      state.isHistoryLoading = action.payload;
    },

    setPrompt: (state, action) => {
      state.prompt = action.payload;
    },

    setAction: (state, action) => {
      state.action = action.payload;
    },

    setOutput: (state, action) => {
      state.output = action.payload;
    },

    setOutputMode: (state, action) => {
      state.outputMode = action.payload;
    },

    appendToHistory: (state, action) => {
      const { fileId, contentType, content } = action.payload;

      if (!state.history[fileId]) {
        state.history[fileId] = {};
      }

      // Only store content if it doesn't already exist.
      // We only care about the first version of the content.
      if (!state.history[fileId][contentType]) {
        state.history[fileId][contentType] = content;
      }
    },

    removeFromHistory: (state, action) => {
      const { fileId, contentType } = action.payload;

      if (state.history[fileId]) {
        state.history[fileId][contentType] = undefined;
      }
    },
  },
});

export const {
  openAIAssistant,
  setIsLoading,
  setPrompt,
  setAction,
  setOutput,
  setOutputMode,
  appendToHistory,
  removeFromHistory,
} = regenerateSlice.actions;

/**************
 * SELECTORS
**************/

export const isPromptLoading = (state) => state.regenerate.isLoading;
export const selectPrompt = (state) => state.regenerate.prompt;
export const selectAction = (state) => state.regenerate.action;
export const selectOutput = (state) => state.regenerate.output;
export const selectOutputMode = (state) => state.regenerate.outputMode;
export const getOGContent = (fileId, contentType) => (state) => {
  if (state.regenerate.history[fileId]) {
    return state.regenerate.history[fileId][contentType] || null;
  }

  return null;
};

/****************
 * ASYNC THUNKS
 ***************/

export const regenerate = createAsyncThunk("regenerate/regen", async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { prompt, text } = payload;

  dispatch(setIsLoading(true));

  try {
    const TextDecoderStream = window.TextDecoderStream;
    const response = await regenerateContent(text, prompt);
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    let output = "";

    while (true) {
      const { value, done } = await reader.read();

      if (done) {
        dispatch(setPrompt(""));

        break;
      }

      output += value;

      dispatch(setOutput(output));
    }

    // dispatch(setOutputMode("prompt"));
    dispatch(incrementRegeneratesUsed());
  } catch (e) {
    dispatch(
      setToast({
        message: "Content could not be regenerated at this time. Please retry in a bit.",
        severity: "error",
        autoClose: false,
      }),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
});

export default regenerateSlice.reducer;
