import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import HeaderNav from "DAI/Navigation/components/HeaderNav";
import ErrorBar from "hoc/errorBar";

function PageLoader({ customLoader = null }) {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderNav showLogo />

      <ErrorBar />

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {customLoader || <CircularProgress size={80} sx={{ color: "#6552F3" }} />}
      </Box>
    </Box>
  );
}

export default PageLoader;
