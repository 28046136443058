import Grid from "@mui/material/Grid";
import BrandLogo from "DAI/Auth/components/Testimonials/BrandLogo";
import { partnerLogos } from "./data";

export default function BrandLogos() {
  return (
    <Grid container sx={{ justifyContent: "center", mt: "40px" }}>
      {partnerLogos.map((brand, index) => (
        <Grid
          item
          xs={6}
          md={4}
          lg={2.4}
          sx={{ display: "flex", justifyContent: "center" }}
          key={index}
        >
          <BrandLogo url={brand.image} name={brand.name} />
        </Grid>
      ))}
    </Grid>
  );
}
