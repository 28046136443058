import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getContentList,
  processFile,
  getGeneratedContent,
  updateTitle,
  updateContent,
  downloadContent,
  pendingFileStatus,
  getBlogs,
  getStatusOfCurrentFile
} from "DAI/contentBrain/service/createContentAPIs";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";
import { setcontentBrain } from "store/transcriptSlice";

const createContentSlice = createSlice({
  name: "createContent",
  initialState: {
    contentList: [],
    generatedContent: {},
    isLoading: false,
    sort: "date_desc",
    limit: 20,
    areAllFilesFetched: false,
    contentProcessing: false,
    headlingLoader: false,
    contentLoader: false,
    downloadLoader: false,
    isInitalMount: false,
    blogList : [],
    uploadedFileProcessed: null,
    uploadedContentId: null,
  },

  reducers: {
    updateFilesList: (state, action) => {
      const { contentList, opType } = action.payload;
      const operation = opType || "append"; // default

      if (contentList) {
        if (operation === "append") {
          state.contentList = state.contentList.concat(contentList);
        } else {
          state.contentList = contentList;
        }
      }
    },
    setContentList: (state, action) => {
      state.contentList = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAllFilesFetched: (state, action) => {
      state.areAllFilesFetched = action.payload;
    },
    setGeneratedContent: (state, action) => {
      state.generatedContent = action.payload;
    },
    updateSort: (state, action) => {
      if (state.sort !== action.payload) {
        state.sort = action.payload;
        state.contentList = [];
        state.areAllFilesFetched = false;
      }
    },
    setContentProcessing: (state, action) => {
      state.contentProcessing = action.payload;
    },
    setHeadingLoader: (state, action) => {
      state.headlingLoader = action.payload;
    },
    setContentLoader: (state, action) => {
      state.contentLoader = action.payload;
    },
    setDownloadLoader: (state, action) => {
      state.downloadLoader = action.payload;
    },
    updateFileStatusToProcessed: (state, action) => {
      const index = state.contentList.findIndex(
        (file) => file.id === action.payload.id
      );
      if (index > -1) {
        state.contentList[index].created_at = action.payload.created_at;
        state.contentList[index].status = action.payload.status;
        state.contentList[index].title = action.payload.title;
      }
    },
    setInitialMount: (state, action) => {
      state.isInitalMount = action.payload;
    },
    setBlogList: (state, action) => {
      state.blogList = action.payload;
    },
    setUploadedFileProcessed: (state, action) => {
      state.uploadedFileProcessed = action.payload;
    },
    setUploadedContentId: (state, action) => {
      state.uploadedContentId = action.payload;
    },
  },
});

export const {
  setContentList,
  updateFilesList,
  setIsLoading,
  setAllFilesFetched,
  setGeneratedContent,
  updateSort,
  setContentProcessing,
  setHeadingLoader,
  setContentLoader,
  setDownloadLoader,
  updateFileStatusToProcessed,
  setInitialMount,
  setBlogList,
  setUploadedFileProcessed,
  setUploadedContentId
} = createContentSlice.actions;

export const contentList = (state) => state.createContent.contentList;
export const isLoading = (state) => state.createContent.isLoading;
export const areAllFilesFetched = (state) =>
  state.createContent.areAllFilesFetched;
export const selectParams = (state) => {
  return new URLSearchParams({
    sort: state.createContent.sort,
    start: state.createContent.contentList.length,
    limit: state.createContent.limit,
  });
};
export const generatedContent = (state) => state.createContent.generatedContent;
export const contentProcessing = (state) =>
  state.createContent.contentProcessing;
export const headlingLoader = (state) => state.createContent.headlingLoader;
export const contentLoader = (state) => state.createContent.contentLoader;
export const downloadLoader = (state) => state.createContent.downloadLoader;
export const isInitialMountSelector = (state) =>
  state.createContent.isInitalMount;
export const blogList = (state) => state.createContent.blogList;
export const uploadedFileProcessedSelector = (state) =>
  state.createContent.uploadedFileProcessed;
export const uploadedContentIdSelector = (state) =>
  state.createContent.uploadedContentId;
export const sortSelector = (state) => state.createContent.sort;

export const getFiles = createAsyncThunk(
  "createContent/getContentList",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { createContent } = getState();
    const { contentList, limit, areAllFilesFetched, isLoading } = createContent;
    const params = selectParams(getState());

    if (payload?.fromStart) {
      params.set("start", 0);
      params.set("limit", contentList.length || limit);
    }

    if (areAllFilesFetched && !payload?.fromStart) {
      return;
    }

    if (isLoading) {
      return;
    }

    dispatch(setIsLoading(true));

    try {
      const { data } = (await getContentList(params)) || [];
      const opType = payload?.fromStart ? "overwrite" : "append";

      dispatch(updateFilesList({ contentList: data, opType }));

      if (data.length < limit) {
        // No more files to fetch
        dispatch(setAllFilesFetched(true));
      }
    } catch (e) {
      dispatch(
        setToast({
          message: "Files could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const statusCheckAndUpdate = createAsyncThunk(
  "createContent/statusCheckAndUpdate",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { createContent } = getState();
    const { contentList } = createContent;
    const unprocessedFiles = contentList.filter(
      (file) => file.status === "pending" || file.status === "processing"
    );

    try {
      const { data } = await pendingFileStatus();
      unprocessedFiles.forEach((content) => {
        const index = data.findIndex((file) => file.id === content.id);
        if (index > -1) {
          dispatch(updateFileStatusToProcessed(data[index]));
        }
      });
    } catch (e) {
      dispatch(
        setToast({
          message: "Files could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    }
  }
);

export const process_File = createAsyncThunk(
  "createContent/processFile",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setContentProcessing(true));

    try {
      const data = await processFile(payload);
      dispatch(setUploadedContentId(data.id));
      dispatch(getFiles({ fromStart: true }));
      analytics.track("ContentBrain Content Created", {
        "Content Type": payload.content_type,
        "Content Prompt": payload.custom_prompt,
        "Content Keywords": payload.keywords,
        "Content Ideas": payload.ideas,
        "Content File": payload.file_ids,
      });
      dispatch(
        setToast({
          message: "File is being processed. Please wait.",
          severity: "success",
          autoClose: true,
        })
      );
      return data;
    } catch (err) {
      dispatch(
        setToast({
          message: "File could not be processed. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
      throw err;
    } finally {
      dispatch(setContentProcessing(false));
    }
  }
);

export const getGenerated_Content = createAsyncThunk(
  "createContent/getGeneratedContent",
  async (contentId, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setIsLoading(true));

    try {
      const data = await getGeneratedContent(contentId);
      dispatch(setGeneratedContent(data));
      dispatch(setcontentBrain(data.content));
    } catch (err) {
      dispatch(
        setToast({
          message: "Files could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updateContentHeadLine = createAsyncThunk(
  "createContent/updateContentHeadLine",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setHeadingLoader(true));

    try {
      await updateTitle(payload.id, {
        title: payload.title,
      });
      dispatch(
        setToast({
          message: "Title updated successfully.",
          severity: "success",
          autoClose: true,
        })
      );
      analytics.track("ContentBrain Content Title Updated", {
        "Content Title": payload.title,
      });
    } catch (err) {
      dispatch(
        setToast({
          message: "Title could not be updated. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setHeadingLoader(false));
    }
  }
);

export const updateContentBody = createAsyncThunk(
  "createContent/updateContentBody",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setContentLoader(true));

    try {
      await updateContent(payload.contentId, {
        content: payload.content,
      });
      dispatch(
        setToast({
          message: "Content updated successfully.",
          severity: "success",
          autoClose: true,
        })
      );
      analytics.track("ContentBrain Content Updated");
    } catch (err) {
      dispatch(
        setToast({
          message: "Content could not be updated. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setContentLoader(false));
    }
  }
);

export const download_Content = createAsyncThunk(
  "createContent/downloadContent",
  async (contentId, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setDownloadLoader(true));
    try {
      const data = await downloadContent(contentId);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "content.docx");
      document.body.appendChild(link);
      link.click();
      link.remove();

      analytics.track("ContentBrain Content Downloaded");
    } catch (err) {
      dispatch(
        setToast({
          message: "Content could not be downloaded. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setDownloadLoader(false));
    }
  }
);

export const getBlogsList = createAsyncThunk(
  "createContent/getBlogsList",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setIsLoading(true));
    const params = new URLSearchParams({
      url: payload,
    });

    try {
      const { data } = (await getBlogs(params)) || [];
      dispatch(setBlogList(data));
      analytics.track("ContentBrain: User Searched Blogs List");
    } catch (err) {
      console.log(err);
      dispatch(
        setToast({
          message: "Blogs could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getStatusOfCurrentFileAction = createAsyncThunk(
  "createContent/getStatusOfCurrentFileAction",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const { status } = (await getStatusOfCurrentFile(payload)) || [];
      if(status === "processed"){
        dispatch(setUploadedContentId(null));
        dispatch(setUploadedFileProcessed(payload));
      }
    } catch (err) {
      dispatch(
        setToast({
          message: "File could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    }
  }
);

export default createContentSlice.reducer;
