import theme from "theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Alert from "components/alert";
import Toast from "components/toast";

function Wrapper({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {children}

      <Alert />

      <Toast />
    </ThemeProvider>
  );
}

export default Wrapper;
