import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createStyle,
  deleteStyle,
  editStyle,
  getStyles,
  uploadFile,
} from "DAI/WritingStyle/services";
import { setToast } from "store/toastSlice";

/**
 * Slice
 */
const writingStyleSlice = createSlice({
  name: "writingStyle",
  initialState: {
    styleList: [
      {
        id: "deciphr_default",
        name: "Deciphr Default",
        processed: true,
      },
    ],
    defaultStyleId: "deciphr_default",
    page: 1,
    isInitiallyFetched: false,
    isAllFetched: false,
    dropdownItems: [],
    selectedStyleItemId: null,
  },

  reducers: {
    setWritingStyles: (state, action) => {
      state.styleList = action.payload;
    },
    setDefaultStyleId: (state, action) => {
      state.defaultStyleId = action.payload;
    },
    appendStyleToStart: (state, action) => {
      state.styleList.unshift(action.payload);
    },
    incrementPageVal: (state) => {
      state.page += 1;
    },
    setInitiallyFetched: (state) => {
      state.isInitiallyFetched = true;
    },
    setAllFetched: (state) => {
      state.isAllFetched = true;
    },
    setDropdownItems: (state, action) => {
      state.dropdownItems = action.payload;
    },
    setSelectedStyleItemId: (state, action) => {
      state.selectedStyleItemId = action.payload;
    }
  },
});

/**
 * Selectors
 */
export const styleListSelector = (state) => state.writingStyle.styleList;
export const defaultStyleIdSelector = (state) =>
  state.writingStyle.defaultStyleId;
export const pageSelector = (state) => state.writingStyle.page;
export const isInitiallyFetchedSelector = (state) =>
  state.writingStyle.isInitiallyFetched;
export const isAllFetchedSelector = (state) => state.writingStyle.isAllFetched;
export const dropdownItemsSelector = (state) =>
  state.writingStyle.dropdownItems;
export const selectedStyleItemIdSelector = (state) =>
  state.writingStyle.selectedStyleItemId;

/**
 * Actions
 */
export const {
  setWritingStyles,
  setDefaultStyleId,
  appendStyleToStart,
  incrementPageVal,
  setInitiallyFetched,
  setAllFetched,
  setDropdownItems,
  setSelectedStyleItemId
} = writingStyleSlice.actions;

export const getDropwdownItems = createAsyncThunk(
  "writingStyle/getDropdownItems",
  async (_, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { writingStyle } = getState();

    const params = new URLSearchParams({
      page: writingStyle.page,
      limit: 20,
    });

    try {
      const data = await getStyles(params);
      const style = { value: "deciphr_default", label: "Deciphr Default", default: false };
      const allStyles = data.map((item) => ({
        value: item.id,
        label: item.name,
        default: item.default
      }))

      if (data) {
        dispatch(setDropdownItems([style, ...allStyles]));
        const defaultStyle = data.filter(item => item.default)?.[0]?.id || style.value;
        dispatch(setSelectedStyleItemId(defaultStyle));
      }
    } catch (e) {
      dispatch(
        setToast({
          message: e.response?.data?.message || e.message,
          severity: "error",
        })
      );
    }
  }
);

/**
 * Async Thunks
 */
export const getWritingStyles = createAsyncThunk(
  "writingStyle/getAll",
  async (_, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { writingStyle } = getState();

    const params = new URLSearchParams({
      page: writingStyle.page,
      limit: 20,
    });

    try {
      const data = await getStyles(params);

      dispatch(
        setWritingStyles([
          ...data,
          {
            id: "deciphr_default",
            name: "Deciphr Default",
            processed: true,
          },
        ])
      );

      dispatch(incrementPageVal());

      if (!writingStyle.isInitiallyFetched) {
        dispatch(setInitiallyFetched());
      }

      if (data.length < 20) {
        dispatch(setAllFetched());
      }

      const defaultStyle = data.find((style) => style.default);
      if (defaultStyle) {
        dispatch(setDefaultStyleId(defaultStyle.id));
      }
    } catch (e) {
      dispatch(
        setToast({
          message: e.response?.data?.message || e.message,
          severity: "error",
        })
      );
    }
  }
);

export const createWritingStyle = createAsyncThunk(
  "writingStyle/create",
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const data = await createStyle();
      dispatch(appendStyleToStart(data));

      return {
        id: data.id,
        name: data.name,
      };
    } catch (e) {
      dispatch(
        setToast({
          message: e.response?.data?.message || e.message,
          type: "error",
        })
      );
    }
  }
);

export const editWritingStyle = createAsyncThunk(
  "writingStyle/edit",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { writingStyle } = getState();

    try {
      const { styleId, values } = payload;
      const data = await editStyle(styleId, values);

      dispatch(
        setWritingStyles([
          data,
          ...writingStyle.styleList.filter((style) => style.id !== data.id),
        ])
      );
    } catch (e) {
      dispatch(
        setToast({
          message: e.response?.data?.message || e.message,
          type: "error",
        })
      );
    }
  }
);

export const uploadRefFile = createAsyncThunk(
  "writingStyle/uploadRefFile",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { writingStyle } = getState();

    try {
      const { styleId, file } = payload;

      dispatch(
        setWritingStyles(
          writingStyle.styleList.map((style) => {
            if (style.id === styleId) {
              return {
                ...style,
                title: file.name,
              };
            }

            return style;
          })
        )
      );

      await uploadFile(styleId, file);
    } catch (e) {
      dispatch(
        setToast({
          message: e.response?.data?.message || e.message,
          type: "error",
        })
      );
    }
  }
);

export const deleteWritingStyle = createAsyncThunk(
  "writingStyle/delete",
  async (styleId, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { writingStyle } = getState();

    try {
      await deleteStyle(styleId).then(() => {
        dispatch(
          setWritingStyles(
            writingStyle.styleList.filter((style) => style.id !== styleId)
          )
        );
      });
    } catch (e) {
      dispatch(
        setToast({
          message: e.response?.data?.message || e.message,
          type: "error",
        })
      );
    }
  }
);

export const setDefaultWritingStyle = createAsyncThunk(
  "writingStyle/setDefault",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { writingStyle } = getState();
    const { id, name, ref_url } = payload;

    try {
      dispatch(setDefaultStyleId(id));

      dispatch(
        setWritingStyles(
          writingStyle.styleList.map((style) => {
            if (style.id === id) {
              return {
                ...style,
                default: true,
              };
            }

            return {
              ...style,
              default: false,
            };
          })
        )
      );

      await editStyle(id, { name, ref_url, default: true });
    } catch (e) {
      dispatch(
        setToast({
          message: e.response?.data?.message || e.message,
          type: "error",
        })
      );
    }
  }
);

export default writingStyleSlice.reducer;
