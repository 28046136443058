import {
  forgot,
  forgotInit,
  login,
  oAuthApi,
  signup,
  verifyEmail,
} from "./apis";
import { GTMPixelTrack } from "./GTM";
import { history } from "./history";

const redirectOnLogin = (data, referrer) => {
  if (referrer) {
    history.navigate(referrer);
    return;
  }

  if (data.org_role) {
    // DAI Editor
    history.navigate("/dashboard");
  } else {
    // DAI
    history.navigate("/home");
  }
};

export const loginApi = async (payload, referrer, callback, errorHandler) => {
  let authData;

  try {
    authData = await login({
      email: payload.email,
      password: payload.password,
      captcha_token: payload.captchaToken,
    });

    localStorage.setItem("userInfo", JSON.stringify(authData));
    redirectOnLogin(authData, referrer);
    callback(authData);
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};

export const googleOAuth = async (
  payload,
  callback,
  errorHandler,
  referrer = null,
) => {
  let authData;

  try {
    authData = await oAuthApi({
      data: payload,
      provider: "google",
    });

    localStorage.setItem("userInfo", JSON.stringify(authData));
    redirectOnLogin(authData, referrer);
    callback(authData);
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message || err?.message);
  }
};

export const forgotInitApi = async (payload, callback, errorHandler) => {
  try {
    await forgotInit({
      email: payload.email,
    });
    callback();
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};

export const forgotApi = async (payload, callback, errorHandler) => {
  try {
    await forgot(payload);
    callback();
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};

export const signupApi = async (payload, callback, errorHandler) => {
  try {
    const authData = await signup({
      email: payload.email,
      first_name: payload.firstName,
      last_name: payload.lastName,
      password: payload.password,
      podcast_name: payload.podcastName || "",
      captcha_token: payload.captchaToken,
    });

    try {
      window.tolt.signup(payload.email);
    } catch (e) {
      //
    }

    localStorage.setItem("userInfo", JSON.stringify(authData));

    callback(authData);
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};

export const VerifyEmail = async (payload, callback, errorHandler) => {
  try {
    await verifyEmail(payload);
    callback();
    GTMPixelTrack("fb_signup_verified", { status: true });
  } catch (err) {
    console.log(err);
    errorHandler(err?.response?.data?.message);
  }
};
