import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { deleteCard, setDefaultCard } from "DAI/PaymentCards//services";
import { setToast } from "store/toastSlice";

dayjs.extend(customParseFormat);

const cardsSlice = createSlice({
  name: "cards",
  initialState: {
    cards: [],
    isLoading: false,
  },

  reducers: {
    setCards: (state, action) => {
      state.cards = action.payload;
    },

    setDefault: (state, action) => {
      const userCards = state.cards.map((card) => {
        card.default = false;

        if (card.id === action.payload) {
          card.default = true;
        }

        return card;
      });

      state.cards = userCards;
    },

    filterCard: (state, action) => {
      state.cards = state.cards.filter((card) => card.id !== action.payload);
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setCards,
  setDefault,
  filterCard,
  setIsLoading,
} = cardsSlice.actions;

/** *************
 * SELECTORS
************** */

export const selectCards = (state) => state.cards.cards;
export const selectIsLoading = (state) => state.cards.isLoading;

export const getDefaultCard = (state) => state.cards.cards.find((card) => {
  const expiry = dayjs(`${card.exp_month}/${card.exp_year}`, "M/YYYY").endOf("month");

  return card.default === true && (expiry.toDate() >= new Date());
});

/** *****************
 * ASYNC THUNKS
****************** */

export const setCardAsDefault = createAsyncThunk("cards/default", async (payload, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { cards } = getState();
  const { isLoading } = cards;

  if (isLoading) {
    return;
  }

  dispatch(setIsLoading(true));

  try {
    await setDefaultCard(payload.id);

    dispatch(setDefault(payload.id));
    dispatch(
      setToast({
        message: `${payload.brand} card ending in ${payload.last4} has been set as the active card.`,
        severity: "success",
        autoClose: true,
      }),
    );
  } catch (e) {
    const message = e?.response?.data?.message
      || `${payload.brand} card ending in ${payload.last4} could not be set as active. Please retry in a bit.`;

    dispatch(
      setToast({ message, severity: "error" }),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
});

export const removeCard = createAsyncThunk("cards/remove", async (payload, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { cards } = getState();
  const { isLoading } = cards;

  if (isLoading) {
    return;
  }

  dispatch(setIsLoading(true));

  try {
    await deleteCard(payload.id);

    dispatch(filterCard(payload.id));
    dispatch(
      setToast({
        message: `${payload.brand} card ending in ${payload.last4} has been deleted.`,
        severity: "success",
        autoClose: true,
      }),
    );
  } catch (e) {
    dispatch(
      setToast({
        message: `${payload.brand} card ending in ${payload.last4} could not be deleted. Please try again.`,
        severity: "error",
      }),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
});

export default cardsSlice.reducer;
