import axios, { mapKeysToCamelCase } from "utils/axiosConfig";

export const GetFolderSort = {
  DateAsc: "date_asc",
  DateDesc: "date_desc",
};

export const GetFolderFilter = {
  All: "all",
  Document: "document",
  Audio: "audio",
  Video: "video",
};

/**
 * Get items in a folder
 *
 * @param {Object} [params]
 * @param {string} [params.folderId="root"] Id of the folder
 * @param {number} [params.start=0] Index of the item to start at
 * @param {number} [params.limit=25] Number of items to return from the start
 * @param {string} [params.sort=25] Sort order to apply
 * @param {string} [params.filter] Filter results
 */
export async function getFolder(params) {
  const folderId = params?.folderId || "root";

  const { data } = await axios.get(
    `/folders/${folderId}`,
    {
      params: {
        start: params?.start || 0,
        limit: params?.limit || 25,
        sort: params?.sort || GetFolderSort.DateDesc,
        filter: params?.filter || GetFolderFilter.All,
      },
    },
  );

  return mapKeysToCamelCase(data);
}

export const getRecentFolders = async (start = 0, limit = 10, sort = "updated_desc") => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/folders",
      params: {
        start,
        limit,
        sort,
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Create a new folder
 *
 * @param {Object} params Name of the folder
 * @param {string} params.name Name of the folder
 * @param {string} [params.parentId=root] Parent folder id
 */
export async function createFolder(params) {
  const { data } = await axios.post(
    "/folders",
    {
      name: params.name,
      parent_id: params.parentId || "root",
    },
  );

  return mapKeysToCamelCase(data);
}

/**
 * Rename an existing folder
 *
 * @param {object} params
 * @param {string} params.folderId
 * @param {string} params.newName
 */
export async function renameFolder({
  folderId,
  newName,
}) {
  const { data } = await axios.put(`/folders/${folderId}/rename`, {
    name: newName,
  });

  return mapKeysToCamelCase(data);
}

/**
 * Delete a folder
 *
 * @param {string} folderId id of the folder
 */
export async function deleteFolder(folderId) {
  const { data } = await axios.delete(`/folders/${folderId}`);

  return mapKeysToCamelCase(data);
}

export async function moveFileIntoFolder({
  fileId,
  folderId,
}) {
  const { data } = await axios.put(`/folders/${folderId}/${fileId}`);

  return mapKeysToCamelCase(data);
}

export async function moveFilesIntoFolder({
  fileIds,
  folderId,
}) {
  const { data } = await axios.put(
    `/v1/folders/${folderId}`,
    {
      file_ids: fileIds,
    },
  );

  return data;
}

/**
 * @param {number[]} fileIds List of file ids to delete
 */
export async function deleteFiles(fileIds) {
  const { data } = await axios.post(
    "/v1/up/delete",
    {
      file_ids: fileIds,
    },
  );

  return data;
}
