import { createSlice } from "@reduxjs/toolkit";

const pricingSlice = createSlice({
  name: "pricing",
  initialState: {
    displayTinyPricingCard: false,
  },

  reducers: {
    setDisplayTinyPricingCard: (state, action) => {
      state.displayTinyPricingCard = action.payload;
    },

    toggleTinyCard: (state) => {
      state.displayTinyPricingCard = !state.displayTinyPricingCard;
    },
  },
});

export const {
  setDisplayTinyPricingCard,
  toggleTinyCard,
} = pricingSlice.actions;

export const isTiny = (state) => state.pricing.displayTinyPricingCard;

export default pricingSlice.reducer;
