import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllGeneratedContent,
  pendingFileStatus,
  getFileStatus,
  createContent,
  searchContent,
  updateTitle,
  updateDescription,
  downloadContent,
  getContent,
} from "DAI/contentBrain/service/searchAPI";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";
import { setcontentBrain } from "store/transcriptSlice";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    searchedContent: [],
    hotWords: [],
    isCreating: false,
    currentCreatedContentId: null,
    isCurrentContentProcessed: null,
    content: {},
    sort: "date_desc",
    filters: "",
    limit: 20,
    areAllFilesFetched: false,
    contentProcessing: false,
    headlingLoader: false,
    contentLoader: false,
    downloadLoader: false,
    isInitalMount: false,
    isLoading: false,
    library: [],
    searchLoader: false,
    areAllSearchedFilesFetched: false,
    search: "",
  },

  reducers: {
    setSearchedContent: (state, action) => {
      const { files, opType } = action.payload;
      const operation = opType || "append"; // default

      if (files) {
        if (operation === "append") {
          state.searchedContent = state.searchedContent.concat(files);
        } else {
          // overwrite
          state.searchedContent = files;
        }
      }
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setHotWords: (state, action) => {
      state.hotWords = action.payload;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload;
    },
    setCurrentCreatedContentId: (state, action) => {
      state.currentCreatedContentId = action.payload;
    },
    setIsCurrentContentProcessed: (state, action) => {
      state.isCurrentContentProcessed = action.payload;
    },
    setContent: (state, action) => {
      state.content = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAllFilesFetched: (state, action) => {
      state.areAllFilesFetched = action.payload;
    },
    updateSort: (state, action) => {
      if (state.sort !== action.payload) {
        state.sort = action.payload;
        // state.searchedContent = [];
        state.areAllFilesFetched = false;
      }
    },
    updateFilters: (state, action) => {
      if (state.filters !== action.payload) {
        state.filters = action.payload;
        // state.searchedContent = [];
        state.areAllFilesFetched = false;
      }
    },
    setContentProcessing: (state, action) => {
      state.contentProcessing = action.payload;
    },
    setHeadingLoader: (state, action) => {
      state.headlingLoader = action.payload;
    },
    setContentLoader: (state, action) => {
      state.contentLoader = action.payload;
    },
    setDownloadLoader: (state, action) => {
      state.downloadLoader = action.payload;
    },
    updateFileStatusToProcessed: (state, action) => {
      const index = state.library.findIndex(
        (file) => file.id === action.payload.id
      );
      if (index > -1) {
        state.library[index].processed = action.payload.processed;
        state.library[index].title = action.payload.title;
      }
    },
    setInitialMount: (state, action) => {
      state.isInitalMount = action.payload;
    },
    setLibrary: (state, action) => {
      state.library = action.payload;
    },
    updateFilesList: (state, action) => {
      const { library, opType } = action.payload;
      const operation = opType || "append"; // default

      if (library) {
        if (operation === "append") {
          state.library = state.library.concat(library);
        } else {
          state.library = library;
        }
      }
    },
    setSearchLoader: (state, action) => {
      state.searchLoader = action.payload;
    },
    setAreAllSearchedFilesFetched: (state, action) => {
      state.areAllSearchedFilesFetched = action.payload;
    },
    resetAll: (state) => {
      state.searchedContent = [];
      state.hotWords = [];
      state.isCreating = false;
      state.currentCreatedContentId = null;
      state.isCurrentContentProcessed = null;
      state.content = {};
      state.sort = "date_desc";
      state.filters = "";
      state.search = "";
    }
  },
});

export const {
  setSearchedContent,
  setHotWords,
  setIsCreating,
  setCurrentCreatedContentId,
  setIsCurrentContentProcessed,
  setContent,
  setHeadingLoader,
  setContentLoader,
  setDownloadLoader,
  setInitialMount,
  setLibrary,
  setIsLoading,
  updateFilesList,
  setAllFilesFetched,
  updateFileStatusToProcessed,
  updateSort,
  updateFilters,
  setSearchLoader,
  setAreAllSearchedFilesFetched,
  setSearch,
  resetAll,
} = searchSlice.actions;

export const searchedContentSelector = (state) => state.search.searchedContent;
export const hotWordsSelector = (state) => state.search.hotWords;
export const isCreatingSelector = (state) => state.search.isCreating;
export const currentCreatedContentIdSelector = (state) =>
  state.search.currentCreatedContentId;
export const isCurrentContentProcessedSelector = (state) =>
  state.search.isCurrentContentProcessed;
export const contentSelector = (state) => state.search.content;
export const isLoadingSelector = (state) => state.search.isLoading;
export const areAllFilesFetchedSelector = (state) =>
  state.search.areAllFilesFetched;
export const contentProcessingSelector = (state) =>
  state.search.contentProcessing;
export const headlingLoader = (state) => state.search.headlingLoader;
export const contentLoader = (state) => state.search.contentLoader;
export const downloadLoader = (state) => state.search.downloadLoader;
export const isInitalMount = (state) => state.search.isInitalMount;
export const selectParams = (state) => {
  return new URLSearchParams({
    sort: state.search.sort,
    start: state.search.searchedContent.length,
    limit: state.search.limit,
    filter: state.search.filters,
  });
};
export const librarySelector = (state) => state.search.library;
export const isLoading = (state) => state.search.isLoading;
export const searchLoaderSelector = (state) => state.search.searchLoader;
export const areAllSearchedFilesFetched = (state) =>
  state.search.areAllSearchedFilesFetched;
export const filtersSelector = (state) => state.search.filters;
export const searchSelector = (state) => state.search.search;

export const searchContentAction = createAsyncThunk(
  "search/searchContent",
  async (payload, { dispatch, getState }) => {
    const { search } = getState();
    const { searchedContent, limit, areAllSearchedFilesFetched, searchLoader } =
      search;
    const params = selectParams(getState());

    if (areAllSearchedFilesFetched && !payload?.fromStart && payload?.query.trim() !== "") {
      return;
    }

    if (searchLoader) {
      return;
    }

    dispatch(setSearchLoader(true));
    params.set("query", payload.query);

    if (payload?.fromStart) {
      params.set("start", 0);
      params.set("limit", searchedContent.length || limit);
    }

    try {
      const { hits, hotwords } = (await searchContent(params)) || [];
      const opType = payload?.fromStart ? "overwrite" : "append";

      dispatch(setSearchedContent({ files: hits, opType }));
      dispatch(setHotWords(hotwords));
      if (hits.length < limit) {
        dispatch(setAreAllSearchedFilesFetched(true));
      }
    } catch (e) {
      dispatch(
        setToast({
          message: e.response.data.error || e.message,
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setSearchLoader(false));
    }
  }
);

export const createContentAction = createAsyncThunk(
  "search/createContent",
  async (payload, { dispatch }) => {
    dispatch(setIsCreating(true));
    try {
      const data = await createContent(payload);
      dispatch(setCurrentCreatedContentId(data.id));

      dispatch(
        setToast({
          message: "Files are Processing",
          severity: "success",
          autoClose: true,
        })
      );
      analytics.track("created content using search tool", {
        content_type: payload.content_type,
        custom_prompt: payload.custom_prompt,
      });
    } catch (e) {
      dispatch(
        setToast({
          message: e.response.data.error || e.message,
          severity: "error",
          autoClose: true,
        })
      );
      dispatch(setIsCreating(false));
    }
  }
);

export const statusOfProcessingFile = createAsyncThunk(
  "search/statusOfProcessingFile",
  async (_, { dispatch, getState }) => {
    const id = getState().search.currentCreatedContentId;
    if (!id) return;
    try {
      const { status } = (await getFileStatus(id)) || [];
      if (status === "Y") {
        dispatch(setCurrentCreatedContentId(null));
        dispatch(setIsCreating(false));
        setTimeout(() => {
          dispatch(setIsCurrentContentProcessed(id));
        }, 1000);
      }
    } catch (e) {
      dispatch(
        setToast({
          message: e.response.data.error || e.message,
          severity: "error",
          autoClose: true,
        })
      );
    }
  }
);

export const getContentAction = createAsyncThunk(
  "search/getContent",
  async (id, { dispatch }) => {
    try {
      const { content } = (await getContent(id)) || {};
      dispatch(setContent(content));
      dispatch(setcontentBrain(content.content));
    } catch (e) {
      dispatch(
        setToast({
          message: e.response.data.error || e.message,
          severity: "error",
          autoClose: true,
        })
      );
    }
  }
);

export const updateContentHeadLine = createAsyncThunk(
  "search/updateContentHeadLine",
  async (payload, { dispatch }) => {
    dispatch(setHeadingLoader(true));
    try {
      await updateTitle(payload.id, {
        title: payload.title,
      });
      dispatch(
        setToast({
          message: "Title updated successfully",
          severity: "success",
          autoClose: true,
        })
      );
      analytics.track("Search Created Content Title updated successfully.");
    } catch (e) {
      dispatch(
        setToast({
          message: e.response.data.error || e.message,
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setHeadingLoader(false));
    }
  }
);

export const download_Content = createAsyncThunk(
  "search/downloadContent",
  async (contentId, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setDownloadLoader(true));
    try {
      const data = await downloadContent(contentId);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "content.docx");
      document.body.appendChild(link);
      link.click();
      link.remove();

      analytics.track("Search Created Content Downloaded");
    } catch (err) {
      dispatch(
        setToast({
          message: "Content could not be downloaded. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setDownloadLoader(false));
    }
  }
);

export const updateContentBody = createAsyncThunk(
  "search/updateContentBody",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setContentLoader(true));

    try {
      await updateDescription(payload.contentId, {
        content: payload.content,
      });
      dispatch(
        setToast({
          message: "Content updated successfully.",
          severity: "success",
          autoClose: true,
        })
      );
      analytics.track("Search Created Content Updated");
    } catch (err) {
      dispatch(
        setToast({
          message: "Content could not be updated. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setContentLoader(false));
    }
  }
);

export const getAllPlannerFiles = createAsyncThunk(
  "search/getAllPlannerFiles",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { search } = getState();
    const { library, limit, areAllFilesFetched, isLoading } = search;
    const params = selectParams(getState());

    if (payload?.fromStart) {
      params.set("start", 0);
      params.set("limit", library.length || limit);
    }

    if (areAllFilesFetched && !payload?.fromStart) {
      return;
    }

    if (isLoading) {
      return;
    }

    dispatch(setIsLoading(true));

    try {
      const { data } = (await getAllGeneratedContent(params)) || [];
      const opType = payload?.fromStart ? "overwrite" : "append";

      dispatch(updateFilesList({ library: data, opType }));

      if (data.length < limit) {
        // No more files to fetch
        dispatch(setAllFilesFetched(true));
      }
    } catch (e) {
      dispatch(
        setToast({
          message: "Files could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const statusCheckAndUpdate = createAsyncThunk(
  "search/statusCheckAndUpdate",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { search } = getState();
    const { library } = search;
    const unprocessedFiles = library.filter(
      (file) => file.processed === "N" || file.processed === "processing"
    );

    try {
      const { data } = await pendingFileStatus();
      unprocessedFiles.forEach((content) => {
        const index = data.findIndex((file) => file.id === content.id);
        if (index > -1) {
          dispatch(updateFileStatusToProcessed(data[index]));
        }
      });
    } catch (e) {
      dispatch(
        setToast({
          message: "Files could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    }
  }
);

export default searchSlice.reducer;
