import { useSelector } from "react-redux";
import theme from "theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UserAvatar from "DAI/Profile/components/UserAvatar";
import { seatInfoSelector } from "store/seatUser";
import { userInfoSelector } from "store/user";

export default function UserInfo() {
  const { firstName, loginInfo } = useSelector(userInfoSelector);
  const seatInfo = useSelector(seatInfoSelector);

  const getFirstname = () => {
    if (loginInfo.isSeatAccount) {
      return seatInfo.firstName;
    }

    return firstName;
  };

  return (
    <Box
      aria-hidden
      sx={{
        alignItems: "center",
        display: "flex",
        gap: "16px",
        mb: "32px",
      }}
    >
      <UserAvatar size="sm" />

      <Typography
        sx={{
          color: theme.palette.gray[800],
          fontFamily: "Satoshi",
          fontSize: "18px",
          fontWeight: 700,
          letterSpacing: "0.5px",
        }}
      >
        Hi {getFirstname()},
      </Typography>
    </Box>
  );
}
