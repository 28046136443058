// TODO: Implement callback actions
import { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { selectToast, hideToast } from "../../store/toastSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WarningIcon from "@mui/icons-material/Warning";
import { Box } from "@mui/material";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} variant="outlined" {...props} />;
});

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

export default function Toast() {
  const toast = useSelector(selectToast);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideToast());
  };

  if (!toast || !toast.message) {
    return null;
  }

  return (
    <div>
      <Snackbar
        autoHideDuration={toast?.autoClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={toast?.show}
        TransitionComponent={SlideTransition}
        key={SlideTransition.name}
        onClose={handleClose}
        sx={{
          "&.MuiSnackbar-anchorOriginBottomRight": {
            right: "80px",
          },
        }}
      >
        <Alert
          severity={toast?.severity}
          sx={{
            width: "100%",
            borderRadius: "99px",
            bgcolor: "#fff",
            boxShadow: "0 0 16px 0 rgba(0,0,0,0.34)",
          }}
          //onClose={handleClose}
          iconMapping={{
            success: <CheckCircleIcon fontSize="inherit" />,
            error: <WarningIcon fontSize="inherit" />,
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {toast?.message}
            <HighlightOffIcon
              onClick={handleClose}
              fontSize="small"
              color="#777E9020"
              sx={{ marginLeft: "15px", cursor: "pointer", opacity: 0.5 }}
            />
          </Box>
        </Alert>
      </Snackbar>
    </div>
  );
}
