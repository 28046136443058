import axios from "utils/axiosConfig";

export const getContentList = async (params) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/content_brain/brain",
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const processFile = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/content_brain/brain",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getStatusOfCurrentFile = async (contentId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/content_brain/brain/${contentId}/status`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getGeneratedContent = async (contentId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/content_brain/brain/${contentId}/content`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateTitle = async (contentId, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/content_brain/brain/${contentId}/title`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateContent = async (contentId, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/content_brain/brain/${contentId}/content`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadContent = async (contentId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/content_brain/brain/${contentId}/download`,
      responseType: "blob",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const pendingFileStatus = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/content_brain/brain/status`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getBlogs = async (params) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/content_brain/brain/blogs`,
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
