import { useSelector } from "react-redux";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import NavbarPlanUsage from "DAI/Navigation/components/NavbarPlanUsage";
import { userInfoSelector } from "store/user";

export default function UploadCredits() {
  const { audioUsed, audioTotal } = useSelector(userInfoSelector);

  return (
    <Tooltip title="Your monthly upload allowance" placement="bottom" arrow>
      <Box
        component="aside"
        sx={{ mx: "32px" }}
      >
        <header style={visuallyHidden}>Your monthly upload allowance</header>

        <NavbarPlanUsage
          metric="Upload Mins"
          used={audioUsed}
          total={audioTotal}
        />
      </Box>
    </Tooltip>
  );
}
