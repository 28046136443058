import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import theme from "theme";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as SearchIcon } from "assets/search/search.svg";

const IconBox = styled("div")({
  paddingLeft: "10px",
  paddingRight: "8px",
  paddingBlock: "10px",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)({
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Satoshi",
  marginRight: "16px",
  borderRadius: 999,
  border: "2px solid",
  borderColor: "#E4E6EF",
  overflow: "hidden",
  transition: "all 0.3s",
  width: "44px",

  "&:hover": {
    borderColor: theme.palette.primary[500],
  },

  "&.Mui-focused": {
    borderColor: theme.palette.primary[500],
    width: "400px",
    transition: "all 0.3s",
  },

  "& .MuiInputBase-input": {
    paddingBlock: "8px",
    width: "44px",
    transition: "all 0.4s",

    "&:focus": {
      width: "400px",
    },

    "&::placeholder": {
      color: theme.palette.gray[500],
      opacity: 0.7,
    },
  },
});

export default function SearchBar({ expanded = false }) {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q") ?? "";
  const inputRef = useRef();

  const onKeyDown = (event) => {
    const trimmed = value.trim();

    if (event.key === "Enter" && !event.repeat && trimmed !== query) {
      event.preventDefault();

      navigate(`/library/search?q=${trimmed}`);
    }
  };

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onClear = () => {
    setValue("");
    inputRef.current.focus();
  };

  const onClick = () => {
    inputRef.current.focus();
  };

  useEffect(() => {
    setValue(query);
  }, [query]);

  return (
    <StyledInputBase
      placeholder="Search Library"
      inputProps={{ "aria-label": "Search your DAI Library" }}
      value={value}
      inputRef={inputRef}
      startAdornment={(
        <IconBox>
          <SearchIcon style={{ color: "#000", height: "20px", width: "20px" }} />
        </IconBox>
      )}
      endAdornment={(
        <IconButton
          sx={{
            mx: "4px",
            opacity: value !== "" ? 1 : 0,
            visibility: value !== "" ? "visible" : "hidden",
          }}
          onClick={onClear}
          aria-label="Clear search"
        >
          <CloseIcon sx={{ fontSize: "20px", color: theme.palette.gray[500] }} />
        </IconButton>
      )}
      sx={{
        width: {
          xs: expanded ? "100%" : "44px",
          xmd: "44px",
        },

        "@media (min-width: 900px)": {
          width: "44px",
        },

        "&.Mui-focused": {
          width: {
            xs: expanded ? "100%" : "44px",
            xmd: "400px",
          },

          "@media (min-width: 900px)": {
            width: "400px",
          },
        },

        "& .MuiInputBase-input": {
          width: {
            xs: expanded ? "100%" : "44px",
            xmd: "44px",
          },

          "&:focus": {
            width: {
              xs: expanded ? "100%" : "44px",
              xmd: "400px",
            },

            "@media (min-width: 900px)": {
              width: "400px",
            },
          },
        },
      }}
      onChange={onChange}
      onClick={onClick}
      // onFocus={() => setFocused(true)}
      onKeyDown={onKeyDown}
    />
  );
}

SearchBar.propTypes = {
  expanded: PropTypes.bool,
};

SearchBar.defaultProps = {
  expanded: false,
};
