import { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import SignupTestimonials from "DAI/Auth/containers/SignupTestimonials";

const AuthV2 = () => {
  const urLocation = useLocation();
  const [isConfirmEmail, setIsConfirmEmail] = useState(false);

  useEffect(() => {
    if (urLocation.search.split("&").length > 1) {
      urLocation.search.split("&").forEach((v) => {
        if (v.includes("mode")) {
          setIsConfirmEmail(v.replace("?mode=", "") === "verifyEmail");
        }
      });
    } else {
      setIsConfirmEmail(false);
    }
  }, [urLocation]);

  if (isConfirmEmail) {
    return <Outlet />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        height: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <SignupTestimonials />

        <Box
          sx={{
            width: { xs: "100%", md: "45%" },
            maxWidth: { xs: "unset", md: "480px" },

            position: "relative",
            display: "flex",
            justifyContent: "center",
            padding: {
              xs: "40px 24px",
              lg: "40px 56px",
            },
            background: "#FFF",
            boxShadow: "-10px 4px 80px 0px rgba(19, 5, 118, 0.25)",

            "@media screen and (max-width: 599px)": {
              boxShadow: "none",
              maxWidth: "100%",
            },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthV2;
