import { FREE_PLAN, plans } from "DAI/Subscription/services/plans";

export const getPlan = (planId, billingPeriod = "monthly") => {
  const plan = plans.find((item) => item.id === planId) || plans[0];
  const tiers = plan.pricing[`${billingPeriod}Plans`].filter((item) => item.planType === "standard");

  return {
    name: plan.name,
    shortName: plan.shortName,
    description: plan.description,
    defaultAmount: tiers[0].amountPerMonth || tiers[0].amount,
    defaultMinutes: tiers[0].minutes,
    planCode: tiers[0].planCode,
    isPopular: plan.isPopular || false,
    isLimitedTimeOffer: plan.isLimitedTimeOffer || false,
    tiers,
    features: plan.featureHighlights,
    icon: plan.icon,
  };
};

export const getTrialPlan = () => {
  const plan = plans.find((item) => item.id === 1);
  const tiers = plan.pricing.monthlyPlans.filter((item) => item.planType === "standard");

  return {
    name: plan.name,
    shortName: plan.shortName,
    description: plan.description,
    defaultAmount: tiers[0].amountPerMonth || tiers[0].amount,
    defaultMinutes: tiers[0].minutes,
    planCode: tiers[0].planCode,
    isPopular: plan.isPopular || false,
    isLimitedTimeOffer: plan.isLimitedTimeOffer || false,
    tiers,
    features: plan.featureHighlights,
    icon: plan.icon,
  };
};

export const getUserPlan = (planCode = "FREE") => {
  const plan = plans.find((item) => planCode.startsWith(item.monthlyPrefix) || planCode.startsWith(item.yearlyPrefix));

  if (plan) {
    const { monthlyPlans, yearlyPlans } = plan.pricing;
    let userPlan;
    let duration = "monthly";

    userPlan = monthlyPlans.find((item) => item.planCode === planCode);

    if (!userPlan) {
      duration = "yearly";
      userPlan = yearlyPlans.find((item) => item.planCode === planCode);
    }

    if (!userPlan) {
      duration = null;

      return null;
    }

    return {
      ...plan,
      pricing: { ...userPlan, duration },
      formatted: {
        name: plan.id === 1 ? plan.name : `${plan.name} ${userPlan.minutes}`,
        duration,
      },
    };
  }

  return {
    ...FREE_PLAN,
    pricing: { ...FREE_PLAN.pricing.monthlyPlans[0], duration: "monthly" },
    formatted: {
      name: FREE_PLAN.name,
      duration: "monthly",
    },
  };
};

export const getNextHigherPlan = (currentPlanCode) => {
  const currentPlan = getUserPlan(currentPlanCode);
  const currentPlanId = currentPlan.id;
  const nextHigherPlan = plans.find((plan) => plan.id === (currentPlanId + 1));

  if (nextHigherPlan) {
    return nextHigherPlan;
  }

  return null;
};

export const isUpgradeOrDowngrade = (oldPlanCode, newPlanCode) => {
  const oldPlan = getUserPlan(oldPlanCode);
  const newPlan = getUserPlan(newPlanCode);

  // The old plan and the new plan are the same
  if (oldPlan.id === newPlan.id) {
    // Check billing cycle - monthly
    if (oldPlan.pricing.duration === "monthly") {
      if (newPlan.pricing.duration === "yearly") {
        return "upgrade";
      }

      // Same billing cycle, compare the minutes credits
      return (newPlan.pricing.minutes > oldPlan.pricing.minutes) ? "upgrade" : "downgrade";
    }

    // Check billing cycle - yearly
    if (oldPlan.pricing.duration === "yearly") {
      if (newPlan.pricing.duration === "monthly") {
        return "downgrade";
      }

      // Same billing cycle, compare the minutes credits
      return (newPlan.pricing.minutes > oldPlan.pricing.minutes) ? "upgrade" : "downgrade";
    }
  }

  if (oldPlan.id > newPlan.id) {
    return "downgrade";
  }

  if (oldPlan.id < newPlan.id) {
    return "upgrade";
  }

  return null;
};

export const validateStripePayload = (name, country) => {
  const errors = {};

  if (!name) {
    errors.name = "Enter the name on card";
  }

  if (!country) {
    errors.country = "Select your country";
  }

  return (Object.keys(errors).length > 0) ? errors : null;
};

export const getPaidPlanPrefixes = () => {
  const paidPlans = plans.filter((plan) => plan.monthlyPrefix !== "FREE");
  const prefixes = paidPlans.map((plan) => [plan.monthlyPrefix, plan.yearlyPrefix]).flat();

  return prefixes;
};
