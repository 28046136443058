import { combineReducers, configureStore } from "@reduxjs/toolkit";
import contentSlice from "DAI/ContentDetails/store/contentSlice";
import assistantReducer from "DAI/MarkdownEditor/store";
import audiogramEditorReducer from "DAI/MediaEditor/store/audiogramEditorSlice";
import videoReelEditorSlice from "DAI/MediaEditor/store/videoReelEditorSlice";
import headerNavSlice from "DAI/Navigation/store/headerNavSlice";
import cardsSlice from "DAI/PaymentCards/store";
import pricingSlice from "DAI/PricingV2/store/pricingSlice";
import askDeciphrReducer from "DAI/ProcessedData/store/askDeciphrSlice";
import writtenContentSlice from "DAI/ProcessedData/store/writtenContentSlice";
import regenerateSlice from "DAI/Regenerate/store";
import subscriptionSlice from "DAI/Subscription/store/subscriptionSlice";
import audiogramReducer from "store/audiogramSlice";
import audioPlayerReducer from "store/audioPlayerSlice";
import createContent from "store/contentBrain/createContentSlice";
import dictionarySlice from "store/contentBrain/dictionarySlice";
import pageSlice from "store/contentBrain/pagesSlice";
import plannerSlice from "store/contentBrain/plannerSlice";
import searchSlice from "store/contentBrain/searchContentSlice";
import dashboardSlice from "store/dai/dashboardSlice";
import librarySearchReducer from "store/dai/librarySearchSlice";
import librarySlice from "store/dai/librarySlice";
import videoreels from "store/dai/videoreelSlice";
import downloadReducer from "store/downloadSlice";
import paymentSlice from "store/payment";
import seatUser from "store/seatUser";
import shareSlice from "store/shareSlice";
import toastReducer from "store/toastSlice";
import transcriptReducer from "store/transcriptSlice";
import uploadSlice from "store/upload";
import userReducer from "store/user";
import writingStyleSlice from "store/writingStyle/writingStyleSlice";

const resetDataMiddleware = ({ getState }) => (next) => {
  // Caching initial app state
  const initialAppState = getState();

  return (action) => {
    if (action.type === "user/reset") {
      // We will reset redux state to initial app state
      const actionWithInitialAppState = {
        ...action,
        payload: initialAppState,
      };

      return next(actionWithInitialAppState);
    }

    return next(action);
  };
};

const combinedReducer = combineReducers({
  // editor: editorReducer,
  content: contentSlice,
  writtenContent: writtenContentSlice,
  audio: audioPlayerReducer,
  toast: toastReducer,
  assistant: assistantReducer,
  transcript: transcriptReducer,
  audiogram: audiogramReducer,
  audiogramEditor: audiogramEditorReducer,
  user: userReducer,
  subscription: subscriptionSlice,
  download: downloadReducer,
  dashboard: dashboardSlice,
  payment: paymentSlice,
  pricing: pricingSlice,
  upload: uploadSlice,
  share: shareSlice,
  regenerate: regenerateSlice,
  cards: cardsSlice,
  videoreels,
  videoReelEditor: videoReelEditorSlice,
  createContent,
  dictionary: dictionarySlice,
  planner: plannerSlice,
  search: searchSlice,
  pages: pageSlice,
  writingStyle: writingStyleSlice,
  librarySearch: librarySearchReducer,
  askDeciphr: askDeciphrReducer,
  seatUser,
  library: librarySlice,
  headerNav: headerNavSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "user/reset") {
    return combinedReducer(action.payload, action);
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().concat(resetDataMiddleware)
  ),
});

export default store;
