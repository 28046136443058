import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getShareOutput, createUpdateShareOutput } from "utils/apis";
import { setToast } from "./toastSlice";
import { analytics } from "utils/GTM";

const shareSlice = createSlice({
  name: "share",
  initialState: {
    shareList: {
      abstract: false,
      insight: false,
      quotes: false,
      show_notes: false,
      article: false,
      tweet: false,
      caption: false,
      display_transcript: false,
      audiogram: false,
      videogram: false,
      serving_url: false,
    },
    isSharing: false,
    showUrl: false,
    shareableId: null,
    isSharePopupOpen: false,
  },

  reducers: {
    toggle: (state) => {
      state.isSharePopupOpen = !state.isSharePopupOpen;
    },
    setShareOptions: (state, action) => {
      state.shareList = {
        abstract: false,
        insight: false,
        quotes: false,
        show_notes: false,
        article: false,
        tweet: false,
        caption: false,
        display_transcript: false,
        audiogram: false,
        videogram: false,
        serving_url: false,
      };
      const shareList = action.payload;
      shareList?.map((item) => {
        state.shareList[item] = true;
        return item;
      });
    },
    setSharingLoader: (state, action) => {
      state.isSharing = action.payload;
    },
    setShareableId: (state, action) => {
      state.shareableId = action.payload;
    },
    setUrl: (state, action) => {
      state.showUrl = action.payload;
    },
  },
});

export const {
  toggle,
  setShareOptions,
  setSharingLoader,
  setShareableId,
  setUrl,
} = shareSlice.actions;

export const isSharePopupOpen = (state) => state.share.isSharePopupOpen;
export const getShareList = (state) => state.share.shareList;
export const getSharingLoader = (state) => state.share.isSharing;
export const getShareableId = (state) => state.share.shareableId;
export const getShowUrl = (state) => state.share.showUrl;

export const getSharedContent = createAsyncThunk(
  "share/getUserShareContentList",
  async (docId, thunkAPI) => {
    const { dispatch } = thunkAPI;
    try {
      const response = await getShareOutput(docId);
      dispatch(setShareOptions(response?.share?.shared_items));
      dispatch(setShareableId(response?.share?.id));
    } catch (e) {
      console.log(e);
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
  }
);

export const updateSharedContent = createAsyncThunk(
  "share/updateUserShareContentList",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { docId, selectedOptions } = payload;

    dispatch(setSharingLoader(true));

    try {
      const response = await createUpdateShareOutput({
        doc_id: docId,
        shared_items: selectedOptions,
      });
      const sharedDomain = process.env.REACT_APP_SHARE_URL;

      if (response.id) {
        dispatch(setShareableId(response.id));
        dispatch(setUrl(true));
        dispatch(setShareOptions(selectedOptions));
        analytics.track("Successfully Shared Content", {
          shareable_id: response.id,
          share_options: selectedOptions,
        });
        window.open(`${sharedDomain}/${response.id}`, "_blank");
      }
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
      dispatch(toggle());
    } finally {
      dispatch(setSharingLoader(false));
    }
  }
);

export default shareSlice.reducer;
