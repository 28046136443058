import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function TestimonialHeading({ children }) {
  return (
    <Typography
      component="h1"
      sx={{
        fontSize: {
          xs: "20px",
          md: "24px",
          lg: "40px",
          // xl: "40px",
        },
        color: "#FFFFFF",
        fontFamily: "Inter",
        fontWeight: "600",
        lineHeight: "130%",
        mb: "10px",
      }}
    >
      {children}
    </Typography>
  );
}

TestimonialHeading.propTypes = {
  children: PropTypes.node.isRequired,
};
