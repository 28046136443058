import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as TrophyIcon } from "assets/icons/trophy.svg";
import { ReactComponent as ShoppingTagIcon } from "assets/icons/shopping-tag.svg";
import { ReactComponent as ExperimentIcon } from "assets/icons/experiment.svg";
import { ReactComponent as CallIcon } from "assets/icons/call.svg";

export const items = [
  {
    name: "Profile Settings",
    icon: UserIcon,
    to: "/profile?source=dropdown_profile_settings_button",
  },
  {
    name: "Upgrade",
    icon: TrophyIcon,
    to: "/plans?source=dropdown_upgrade_button",
    hideToSeatAccount: true,
  },
  {
    name: "Plan and Billing",
    icon: ShoppingTagIcon,
    to: "/profile?tab=2&source=dropdown_menu",
    hideToSeatAccount: true,
  },
  {
    name: "Feature Lab",
    icon: ExperimentIcon,
    to: "https://www.deciphr.ai/labs",
    target: "_blank",
  },
  {
    name: "Speak to a Human",
    icon: CallIcon,
    to: "https://calendly.com/d/d5d-pcq-5ry/deciphr-user-call",
    target: "_blank",
  },
];
