import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import App from "./App";
import GlobalDataProvider from "./context";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const captchaKey = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <GoogleReCaptchaProvider reCaptchaKey={captchaKey} useEnterprise>
          <GlobalDataProvider>
            <App />
          </GlobalDataProvider>
        </GoogleReCaptchaProvider>
      </Elements>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
