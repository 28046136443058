import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { badges } from "DAI/Profile/data";
import { Avatar, Badge } from "@mui/material";
import { getUserPlan } from "DAI/Subscription/services/utils";
import { userInfoSelector } from "store/user";

// size = "sm" | "lg"
export default function UserAvatar({ size = "sm" }) {
  const { firstName, plan } = useSelector(userInfoSelector);
  const planName = getUserPlan(plan)?.name;
  const sizeMap = {
    avatar: {
      sm: 40,
      lg: 70,
    },

    initial: {
      sm: 16,
      lg: 24,
    },

    badge: {
      sm: 16,
      lg: 24,
    },
  };

  return (
    <Badge
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={
        badges[planName]?.icon && (
          <img
            style={{ width: badges[planName].iconSizes[size] }}
            src={badges[planName].icon}
            alt=""
            aria-hidden
          />
        )
      }
      sx={{
        "& .MuiBadge-badge": {
          border: planName !== "Flexi Free" && "1px solid #777E90",
          padding: 0,
          minWidth: 0,
          width: planName !== "Flexi Free" && sizeMap.badge[size],
          height: planName !== "Flexi Free" && sizeMap.badge[size],
          borderRadius: 999,
          transform: "none",
          bgcolor: "#FFF",
        },
      }}
    >
      <Avatar
        sx={{
          width: sizeMap.avatar[size],
          height: sizeMap.avatar[size],
          backgroundColor: "#585858",
          color: "#FFF",
          fontWeight: 700,
          fontSize: sizeMap.initial[size],
        }}
      >
        {firstName[0]?.toUpperCase()}
      </Avatar>
    </Badge>
  );
}

UserAvatar.propTypes = {
  size: PropTypes.oneOf(["sm", "lg"]),
};

UserAvatar.defaultProps = {
  size: "sm",
};
