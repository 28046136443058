import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export default function HamburgerIcon({ open, onClick }) {
  return (
    <Box
      aria-label="user menu"
      className={open ? "open" : undefined}
      role="button"
      sx={{
        cursor: "pointer",
        height: "24px",
        mr: "12px",
        position: "relative",
        transform: "rotate(0deg)",
        transition: "0.5s ease-in-out",
        width: "18px",

        "& span": {
          bgcolor: "#000",
          display: "block",
          height: "3px",
          opacity: 1,
          position: "absolute",
          transform: "rotate(0deg)",
          transition: ".25s ease-in-out",
          width: "100%",

          "&:nth-child(even)": {
            borderRadius: "0 999px 999px 0",
            left: "50%",
          },

          "&:nth-child(odd)": {
            borderRadius: "999px 0 0 999px",
            left: 0,
          },

          "&:nth-child(1), &:nth-child(2)": {
            top: 0,
          },

          "&:nth-child(3), &:nth-child(4)": {
            top: "9px",
          },

          "&:nth-child(5), &:nth-child(6)": {
            top: "18px",
          },
        },

        "&.open": {
          "& span:nth-child(1), & span:nth-child(6)": {
            transform: "rotate(45deg)",
          },

          "& span:nth-child(2), & span:nth-child(5)": {
            transform: "rotate(-45deg)",
          },

          "& span:nth-child(1)": {
            left: "2px",
            top: "11px",
          },

          "& span:nth-child(2)": {
            left: "calc(50% - 1px)",
            top: "11px",
          },

          "& span:nth-child(3)": {
            left: "50%",
            opacity: 0,
          },

          "& span:nth-child(4)": {
            left: "100%",
            opacity: 0,
          },

          "& span:nth-child(5)": {
            left: "3px",
            top: "16px",
          },

          "& span:nth-child(6)": {
            left: "calc(50% - 2px)",
            top: "16px",
          },
        },
      }}
      onClick={onClick}
    >
      <span />

      <span />

      <span />

      <span />

      <span />

      <span />
    </Box>
  );
}

HamburgerIcon.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
