import Dana from "assets/dana-biberston.png";
import Jordyne from "assets/jordyne-carmack.png";
import Nolan from "assets/nolan.png";
import Unrefined from "assets/unrefined-podcast.png";

export const userReviews = [
  {
    user: {
      name: "Brandon Spain",
      image: Unrefined,
      company: "Unrefined Podcast",
    },
    text: "Deciphr has been super helpful in my hectic schedule to get our podcast details DONE!",
  },
  {
    user: {
      name: "Nolan McCoy",
      image: Nolan,
      company: "Chili Piper",
    },
    text: "Deciphr [pieces] a 45-minute conversation into five paragraphs. And then I'm using "
      + "that source of truth to dictate the creative building blocks of all these different things.",
  },
  {
    user: {
      name: "Jordyn Carmack",
      image: Jordyne,
      company: "University of the Cumberlands",
    },
    text: "Smooth, easy to use, accurate transcriptions. Speed for a 30 min podcast "
      + "episode was under five minutes.",
  },
  {
    user: {
      name: "Dana Biberston",
      image: Dana,
      company: "City Council of Eagle, ID",
    },
    text: "The BEST audio transcription service I've for podcasting... It offers the "
      + "most output options with one file",
  },
];
