import { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, Menu, Stack, SvgIcon, Typography } from "@mui/material";
import { seatInfoSelector } from "store/seatUser";
import { userInfoSelector } from "store/user";
import UserMenuItemCtn from "../../containers/UserMenuItemCtn";
import UserAvatar from "../UserAvatar";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

export default function ProfileMenu({ show }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { firstName, email, loginInfo } = useSelector(userInfoSelector);
  const seatInfo = useSelector(seatInfoSelector);
  const handleClose = () => setAnchorEl(null);

  const getFirstname = () => {
    if (loginInfo.isSeatAccount) {
      return seatInfo.firstName;
    }

    return firstName;
  };

  const getEmail = () => {
    if (loginInfo.isSeatAccount) {
      return seatInfo.email;
    }

    return email;
  };

  if (show) {
    return (
      <>
        <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)} className="file-profile">
          <UserAvatar size="sm" />
        </IconButton>

        <Menu
          id="primary-search-account-menu"
          keepMounted
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            padding: "12px",
            "& .MuiMenu-paper": {
              borderRadius: "20px",
              maxWidth: "300px",
              width: "100%",
              overflow: "visible",
              boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.16)",
            },
            "& .MuiList-root": {
              padding: 0,
            },
          }}
          open={anchorEl !== null}
          onClose={handleClose}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: "16px", right: "16px" }}
          >
            <SvgIcon
              sx={{ fontSize: 16 }}
              component={CloseIcon}
              inheritViewBox
            />
          </IconButton>

          <Stack gap="16px" sx={{ px: "30px", py: "20px" }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "16px" }}>
              <Typography fontSize="14px" fontFamily="DM Sans">
                {getEmail()}
              </Typography>

              <UserAvatar size="lg" />

              <Typography fontSize="24px" fontWeight={700} fontFamily="DM Sans">
                Hi {getFirstname()},
              </Typography>
            </div>

            {/* Items for navigation */}
            <UserMenuItemCtn onClose={handleClose} />
          </Stack>
        </Menu>
      </>
    );
  }

  return null;
}
