import axios from "utils/axiosConfig";

export const addPaymentCard = async (token) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/v3/payment/setup_intent",
      data: {
        payment_method_id: token,
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const setDefaultCard = async (cardId) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/v3/payment/card",
      data: {
        id: cardId,
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteCard = async (cardId) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: "/v3/payment/card",
      data: {
        id: cardId,
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
