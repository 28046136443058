/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  checkVideoReelStatus,
  createNewReel as createNewReelApi, getSubtitles,
  updateVideReelTranscript,
  uploadReelLogo,
} from "DAI/ProcessedData/services/apis";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";

export const CreateReelFlowState = {
  Base: "Base",
  Create: "Create",
  Generating: "Generating",
  Error: "Error",
  Done: "Done",
};

const videoReelSlice = createSlice({
  name: "videoreels",
  initialState: {
    videoReels: [],
    selectedVideo: null,
    pendingVideoReelsIds: [],
    downloadableVideoReelsLinks: [],
    loading: false,
    selectedVideoSubtitle: {},
    isUpdatingSubtitles: false,
    exitModal: false,
    totalRangeInMillis: [0, 0],
    trimmedRangeInMillis: [0, 0],
    playerRangeInMillis: [0, 0],
    trimmedWordRange: {
      start: 0,
      end: 0,
    },
    highlightedWordRange: {
      start: 0,
      end: 0,
    },
    highlightedRangeInMillis: {
      start: 0,
      end: 0,
    },
    currentTab: null,
    createReelFlowState: CreateReelFlowState.Base,
  },

  reducers: {
    setVideoReels: (state, action) => {
      state.videoReels = action.payload;
    },

    resetVideoReels: (state) => {
      state.videoReels = [];
    },

    addNewReel: (state, action) => {
      state.videoReels.unshift(action.payload);
    },

    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload;
    },

    setPendingVideoReelsIds: (state, action) => {
      state.pendingVideoReelsIds = action.payload;
    },

    setDownloadableLinks: (state, action) => {
      state.downloadableVideoReelsLinks = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setIsUpdatingSubtitles: (state, action) => {
      state.isUpdatingSubtitles = action.payload;
    },

    updateSelectedVideoTranscript: (state, action) => {
      const { startTime, text } = action.payload;
      const { grouped_words } = state.selectedVideo;
      const newGroupedWords = grouped_words.map((item) => {
        if (item.start === startTime) {
          return { ...item, text };
        }

        return item;
      });
      state.selectedVideo.grouped_words = newGroupedWords;
    },

    updateSelectedVideoAdditionalPayload: (state, action) => {
      const { style, branded_content } = action.payload.videoAdditionalPayload;

      state.selectedVideo.font = style.font;
      state.selectedVideo.font_color = style.font_color;
      state.selectedVideo.font_size = style.font_size;
      state.selectedVideo.position = style.position;
      state.selectedVideo.branded_content = branded_content;
    },

    updateVideoReelTranscript: (state, action) => {
      const {
        grouped_words: changedTranscriptList,
        id: videoReelId,
        ...additionalPayload
      } = action.payload;

      const updateVideoReels = state.videoReels.map((reel) => {
        if (reel.id !== videoReelId) {
          return reel;
        }

        const constructTextFromGroupedWords = changedTranscriptList
          .map((v) => v.text)
          .join(" ");

        return {
          ...reel,
          ...additionalPayload,
          grouped_words: changedTranscriptList,
          text: constructTextFromGroupedWords,
        };
      });

      state.videoReels = updateVideoReels;
    },

    updateALlReelTemplate: (state, action) => {
      const { payload } = action;
      const updatedReels = state.videoReels.map((reel) => ({
        ...reel,
        ...payload,
      }));

      state.videoReels = updatedReels;
    },

    setSelectedVideoSubtitle: (state, action) => {
      state.selectedVideoSubtitle = action.payload;
    },

    setExitModal: (state, action) => {
      state.exitModal = action.payload;
    },

    setTrimmedRange(state, action) {
      const startWordIndex = state.selectedVideo?.grouped_words.findIndex(
        (word) => {
          if (action.payload[0] >= word.start && action.payload[0] < word.end) {
            return true;
          }

          return false;
        },
      );

      const endWordIndex = state.selectedVideo?.grouped_words.findIndex(
        (word) => {
          if (action.payload[1] <= word.end) {
            return true;
          }

          return false;
        },
      );

      if (startWordIndex !== -1 && endWordIndex !== -1) {
        state.trimmedWordRange = {
          start: startWordIndex,
          end: endWordIndex,
        };

        state.trimmedRangeInMillis = [
          state.selectedVideo.grouped_words[startWordIndex].start,
          state.selectedVideo.grouped_words[endWordIndex].end,
        ];
        state.playerRangeInMillis = [
          state.selectedVideo.grouped_words[endWordIndex].start,
          state.selectedVideo.grouped_words[endWordIndex].start,
        ];
      }
    },

    setTrimmedWordRange(state, action) {
      if (!action.payload) {
        state.trimmedWordRange = {
          start: 0,
          end: 0,
        };
        state.trimmedRangeInMillis = [0, 0];
      }

      state.trimmedWordRange = {
        start: action.payload.start || 0,
        end: action.payload.end || state.selectedVideo.grouped_words.length - 1,
      };
      state.trimmedRangeInMillis = [
        state.selectedVideo.grouped_words[action.payload.start]?.start
        || state.totalRangeInMillis[0],
        state.selectedVideo.grouped_words[action.payload.end]?.end
        || state.totalRangeInMillis[1],
      ];
    },

    setHighlightedWordRange(state, action) {
      if (!action.payload) {
        state.highlightedWordRange = {
          start: 0,
          end: 0,
        };

        state.highlightedRangeInMillis = {
          start: 0,
          end: 0,
        };

        return;
      }

      state.highlightedWordRange = {
        start: action.payload.start || 0,
        end: action.payload.end || state.selectedVideo.grouped_words.length - 1,
      };

      state.highlightedRangeInMillis = {
        start:
          state.selectedVideo.grouped_words[action.payload.start]?.start
          || state.totalRangeInMillis[0],
        end:
          state.selectedVideo.grouped_words[action.payload.end]?.end
          || state.totalRangeInMillis[1],
      };
    },

    setPlayerRange(state, action) {
      state.playerRangeInMillis = action.payload;
    },

    setTotalRange(state, action) {
      state.totalRangeInMillis = action.payload;
    },

    resetTrimmer(state) {
      state.playerRangeInMillis = [
        state.highlightedRangeInMillis.start,
        state.highlightedRangeInMillis.end,
      ];
      state.trimmedRangeInMillis = [
        state.highlightedRangeInMillis.start,
        state.highlightedRangeInMillis.end,
      ];
      state.trimmedWordRange = state.highlightedWordRange;
    },

    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },

    addNewPendingReelId(state, action) {
      state.pendingVideoReelsIds.push(action.payload);
    },

    setCreateReelFlowState(state, action) {
      state.createReelFlowState = action.payload;
    },

    updateVideoReelsFromStatusCheckRes(state, action) {
      const statusMap = action.payload.reduce((acc, status) => {
        acc[status.id] = status;

        return acc;
      }, {});

      state.videoReels = state.videoReels.map((reel) => {
        if (reel.video_url || !statusMap[reel.id]) {
          return reel;
        }

        return {
          ...reel,
          video_url: statusMap[reel.id].video_url,
        };
      });
    },
  },
});

export const {
  setVideoReels,
  resetVideoReels,
  addNewReel,
  setSelectedVideo,
  updateSelectedVideoTranscript,
  updateSelectedVideoAdditionalPayload,
  updateVideoReelTranscript,
  setPendingVideoReelsIds,
  setDownloadableLinks,
  setLoading,
  setSelectedVideoSubtitle,
  setIsUpdatingSubtitles,
  setExitModal,
  updateALlReelTemplate,
  setTrimmedRange,
  setTrimmedWordRange,
  setHighlightedWordRange,
  setPlayerRange,
  setTotalRange,
  resetTrimmer,
  setCurrentTab,
  addNewPendingReelId,
  setCreateReelFlowState,
  updateVideoReelsFromStatusCheckRes,
} = videoReelSlice.actions;

export const videoReelsSelector = (state) => state.videoreels.videoReels;
export const selectedVideoSelector = (state) => state.videoreels.selectedVideo;
export const pendingVideoReelsIdsSelector = (state) => state.videoreels.pendingVideoReelsIds;
export const loadingSelector = (state) => state.videoreels.loading;
export const downloadableVideoReelsLinksSelector = (state) => state.videoreels.downloadableVideoReelsLinks;
export const selectedVideoSubtitleSelector = (state) => state.videoreels.selectedVideoSubtitle;
export const isUpdatingSubtitlesSelector = (state) => state.videoreels.isUpdatingSubtitles;
export const exitModalSelector = (state) => state.videoreels.exitModal;
export const videoReelsSliceSelector = (state) => state.videoreels;
export const isVideoReelsAvailable = (state) => {
  const { videoReels } = state.videoreels;

  return videoReels !== null && videoReels?.length > 0;
};

export const checkAllVideoReelsStatus = createAsyncThunk("videoreels/status", async (docId, thunkAPI) => {
  const { dispatch } = thunkAPI;

  try {
    const { status } = await checkVideoReelStatus(docId);

    if (!status) return;

    const pendingList = status
      .filter((v) => v.status === "Pending" || v.status === "Reprocessing")
      .map((v) => v.id);

    dispatch(setPendingVideoReelsIds(pendingList));
    dispatch(setDownloadableLinks(status));
    dispatch(updateVideoReelsFromStatusCheckRes(status));
  } catch (e) {
    dispatch(setToast({ message: e.message, severity: "error" }));
  }
});

export const initVideoReels = createAsyncThunk("videoreels/init", async (payload, thunkAPI) => {
  const { reels, docId } = payload;
  const { dispatch } = thunkAPI;

  dispatch(setVideoReels(reels));

  if (reels && reels.length > 0) {
    dispatch(checkAllVideoReelsStatus(docId));
  }
});

export const saveVideoReelTranscript = createAsyncThunk(
  "videoreels/saveVideoReelTranscript",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    dispatch(setLoading(payload.id));

    const state = getState();
    const { docId } = state.transcript;

    const fixfontColor = (color) => color.replace("#", "");

    const formatBrandedContent = (branded_content) => {
      const obj = {
        ...branded_content,
        font_color: fixfontColor(branded_content.font_color),
        highlight_color: fixfontColor(branded_content.highlight_color),
      };
      return obj;
    };

    const newPayload = {
      id: payload.id,
      // aspect_ratio: payload.aspectRatio,
      apply_to_all: payload.apply_to_all,
      background_id: payload.background_id,
      platforms: payload.platforms,
      grouped_words: payload.grouped_words,
      font: payload.font,
      start: payload.start,
      end: payload.end,
      font_color: fixfontColor(payload?.font_color),
      highlight_color: fixfontColor(payload?.highlight_color),
      font_size: payload.font_size,
      monotone_platforms: payload.monotone_platforms,
      branded_content: formatBrandedContent(payload?.branded_content),
    };

    try {
      if (payload.logoBlob) await uploadReelLogo(docId, payload.id, payload.logoBlob);

      await updateVideReelTranscript(docId, newPayload);

      dispatch(
        updateVideoReelTranscript({
          ...newPayload,
          logo: { default_logo_url: payload.logo },
          grouped_words: payload.new_grouped_words,
        }),
      );

      if (payload.apply_to_all) {
        const { grouped_words, start, end, id, ...payloadWithoutGroupedWords } = newPayload;
        dispatch(
          updateALlReelTemplate({
            ...payloadWithoutGroupedWords,
            logo: { default_logo_url: payload.logo },
          }),
        );
        analytics.track("Apply to all video reel");
      } else {
        analytics.track("Video Reel Changes Saved");
      }

      setTimeout(() => {
        dispatch(checkAllVideoReelsStatus(docId));
        dispatch(setPendingVideoReelsIds([newPayload.id]));
      }, 100);
      dispatch(
        setToast({
          message: "Successfully Updated",
          severity: "success",
          autoClose: true,
        }),
      );
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }

    dispatch(setLoading(false));
    dispatch(setExitModal(true));
  },
);

export const getVideoSubtitle = createAsyncThunk(
  "videoreels/getVideoSubtitle",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { media_type, id, content } = payload;
    dispatch(setIsUpdatingSubtitles(true));

    const fixfontColor = (color) => color.replace("#", "");

    const formatBrandedContent = (branded_content) => {
      const obj = {
        ...branded_content,
        font_color: fixfontColor(branded_content.font_color),
        highlight_color: fixfontColor(branded_content.highlight_color),
      };
      return obj;
    };

    const updatedContent = {
      id: content.id,
      // aspect_ratio: payload.aspectRatio,
      background_id: content.background_id,
      grouped_words: content.grouped_words,
      font: content.font,
      font_color: fixfontColor(content.font_color),
      highlight_color: fixfontColor(content.highlight_color),
      font_size: content.font_size,
      branded_content: formatBrandedContent(content.branded_content),
    };

    try {
      const subtitles = await getSubtitles(media_type, id, updatedContent);
      // Set subtitle track
      window?.octopusVideoReelSubtitleInstance?.freeTrack();
      window?.octopusVideoReelBrandInstance?.freeTrack();
      window?.octopusVideoReelSubtitleInstance?.setTrack(
        subtitles.main_subtitle,
      );
      window?.octopusVideoReelBrandInstance?.setTrack(subtitles.brand_subtitle);
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    } finally {
      dispatch(setIsUpdatingSubtitles(false));
    }
  },
);

export const createNewReel = createAsyncThunk(
  "videoReels/createNewReel",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { docId, excerpt } = payload;

    const res = await createNewReelApi(docId, excerpt);

    if (res instanceof AxiosError) {
      return new Error(res.response.data.message);
    }

    dispatch(addNewReel(res));
    dispatch(addNewPendingReelId(res.id));
    dispatch(
      setToast({
        message:
          "Success! Your new video reel is now being generated.",
        severity: "success",
      }),
    );

    return res;
  },
);

export default videoReelSlice.reducer;
