import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { differenceBy } from "lodash";
// import { setAttributes } from "store/transcriptSlice";
import { setToast } from "store/toastSlice";
// import { reprocessFile } from "DAI/Library/services/apis";
// import { getStatus } from "DAI/Library/components/LibraryFileCard/statuses";
import {
  // DelInsights,
  // getUnprocessedFileIds,
  searchFiles,
} from "utils/apis";

const librarySearchSlice = createSlice({
  name: "librarySearch",
  initialState: {
    files: [],
    sort: "date_desc",
    isLoading: false,
    allFetched: false,
    // isInitialMount: false,
  },

  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload;
    },

    removeFileFromSearch: (state, action) => {
      state.files = state.files.filter((file) => file.id !== action.payload);
    },

    removeFilesFromSearch(state, action) {
      state.files = differenceBy(
        state.files,
        action.payload,
        (item) => item.id,
      );
    },

    setSort: (state, action) => {
      state.sort = action.payload;
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setAllFetched: (state, action) => {
      state.allFetched = action.payload;
    },
  },
});

export const {
  setFiles,
  removeFileFromSearch,
  setSort,
  setSearchTerm,
  setIsLoading,
  setAllFetched,
} = librarySearchSlice.actions;

/** ************
 * Selectors
 ************* */
export const filesSelector = (state) => state.librarySearch.files;
export const sortSelector = (state) => state.librarySearch.sort;
export const isLoadingSelector = (state) => state.librarySearch.isLoading;
export const allFetchedSelector = (state) => state.librarySearch.allFetched;

const selectParams = (state) => new URLSearchParams({
  sort: state.librarySearch.sort,
  start: state.librarySearch.files.length,
  limit: 20,
});

/** **************
 * Async Thunks
 *************** */
export const searchLibrary = createAsyncThunk(
  "librarySearch/search",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { q, fromStart } = payload;

    if (fromStart) {
      dispatch(setFiles([]));
    }

    const params = selectParams(getState());
    const query = q.trim();

    dispatch(setIsLoading(true));

    try {
      if (query !== "") {
        params.set("query", query);

        if (fromStart) {
          params.set("start", 0);
        }

        const data = await searchFiles(params);

        if (data.length < 20) {
          dispatch(setAllFetched(true));
        }

        dispatch(setFiles(data));
      }
    } catch (e) {
      dispatch(setToast({ message: "Error fetching files", type: "error" }));
    } finally {
      dispatch(setIsLoading(false));
    }
  },
);

export default librarySearchSlice.reducer;
