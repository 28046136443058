import { useEffect } from "react";
import { analytics } from "./GTM";

export const initServices = () => {
  /**
   *
   * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
   */
  const options = {
    api_base: process.env.REACT_APP_INTERCOM_API_BASE,
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  };
  window.Intercom("boot", options);

  /**
   * Posthog
   * https://posthog.com/docs/product-analytics/installation
   *
   */
  analytics.init();
};

/**
 * update Intercom on every page change
 */
export const updateIntercom = () => {
  window.Intercom("update");
};

/**
 * Hide intercom bubble on mounting the page and
 * showing it again on page change
 */
export const useHideIntercomBubbleInPage = () => {
  useEffect(() => {
    const bubbleEl = document.querySelector("#intercom-container");

    if (bubbleEl) {
      bubbleEl.style.display = "none";
    }

    return () => {
      if (bubbleEl) {
        bubbleEl.style.display = "block";
      }
    };
  }, []);
};

/**
 * This function is used to update the user data on Intercom
 * @param {*object} param0
 * @returns void
 *
 */
export const updateIntercomUserData = ({
  email,
  firstName,
  plan,
  audioUsed,
  audioTotal,
  companyName,
  lastName,
  transcriptUsed,
  transcriptTotal,
  regenRemaining,
}) => {
  // get user Id and isEmailVerified from local storage
  const { user } = JSON.parse(localStorage.getItem("userInfo")) || {};
  const { emailVerified, localId } = user || {};

  if (email && localId) {
    // calculate audio usage left
    const audioUsageLeft = audioTotal - audioUsed;
    const transcriptUsageLeft = transcriptTotal - transcriptUsed;

    // update user data on Intercom
    window.Intercom("update", {
      user_id: localId,
      email,
      name: firstName,
      "Subscribed Plan": plan || "",
      plan: plan || "",
      "First Name": firstName || "",
      "Last Name": lastName || "",
      "Company name": companyName || "",
      "Audio Usage Left from Plan": audioUsageLeft,
      "Audio Usage Left from Plan - Document Uploads": transcriptUsageLeft,
      "AI Assistant Regenerates Left": regenRemaining,
      email_verified: emailVerified,
    });
  }
};

/**
 * @param {*string} path
 * @param {*string} search
 * This function is used to track the page view
 * and send the page name to analytics
 *
 */
export const analyticsUpdate = () => {
  analytics.track("$pageview");
};
