import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { rollbar } from "Core/helpers/rollbar";
import { cancelSubscription } from "DAI/Subscription/services/apis";
import { getUserPlan, isUpgradeOrDowngrade } from "DAI/Subscription/services/utils";
import { applyDiscount, payment, resumePlanApi } from "utils/apis";
import { analytics, GTM } from "utils/GTM";
import { setToast } from "./toastSlice";
import { getUserInfo } from "./user";

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    selectedPlan: {},
    planDuration: "",
    billingAmount: null,
    showingCheckoutModel: false,
    showingSuccessModel: false,
    invoice: {},
    loading: false,
    isSuccessful: false,
    globalMonthlyCheck: true,
    isPayAsYouGo: false,
    openCancelSubscriptionModel: false,
    openChangeplanModel: false,
    addons: 0,
    mediaFileModal: false,
    errorMsgType: "",
    cardError: null,
    isCancelled: false,
    cancelledSubscription: false,
  },

  reducers: {
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },

    setPlanDuration: (state, action) => {
      state.planDuration = action.payload;
    },

    setBillingAmount: (state, action) => {
      state.billingAmount = action.payload;
    },

    setShowCheckoutModel: (state, action) => {
      state.showingCheckoutModel = action.payload.show;
      state.selectedPlan = action.payload.plan;
      state.isPayAsYouGo = action.payload.isPayAsYouGo;

      if (action.payload.show === false) {
        state.cardError = null;
        state.planDuration = "";
      }
    },

    setShowSuccessModel: (state, action) => {
      state.showingSuccessModel = action.payload.show;
      state.invoice = action.payload.invoice;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setIsSuccessful: (state, action) => {
      state.isSuccessful = action.payload;
    },

    setGloablMonthlyCheck: (state, action) => {
      state.globalMonthlyCheck = action.payload;
    },

    setOpenCancelSubscriptionModel: (state, action) => {
      state.openCancelSubscriptionModel = action.payload;
    },

    setOpenChangeplanModel: (state, action) => {
      state.openChangeplanModel = action.payload;
    },

    setAddons: (state, action) => {
      state.addons = action.payload;
    },

    setMediaFileModal: (state, action) => {
      state.mediaFileModal = action.payload.open;
      state.errorMsgType = action.payload.type;
    },

    setCardError: (state, action) => {
      state.cardError = action.payload;
    },

    setIsCancelled: (state, action) => {
      state.isCancelled = action.payload;
    },
    setCancelledSubscription: (state, action) => {
      state.cancelledSubscription = action.payload;
    },
  },
});

export const {
  setSelectedPlan,
  setPlanDuration,
  setBillingAmount,
  setShowCheckoutModel,
  setShowSuccessModel,
  setLoading,
  setIsSuccessful,
  setGloablMonthlyCheck,
  setOpenCancelSubscriptionModel,
  setOpenChangeplanModel,
  setAddons,
  setMediaFileModal,
  setCardError,
  setIsCancelled,
  setCancelledSubscription,
} = paymentSlice.actions;

export const planSelector = (state) => state.payment.plans;
export const planSpricingSelector = (state) => state.payment.plansPricing;
export const selectPlanDuration = (state) => state.payment.planDuration;
export const getBillingAmount = (state) => state.payment.billingAmount;
export const payAsYouGoSelector = (state) => state.payment.payAsYouGo;
export const selectedPlanSelector = (state) => state.payment.selectedPlan;
export const showingCheckoutModelSelector = (state) => state.payment.showingCheckoutModel;
export const showingSuccessModelSelector = (state) => state.payment.showingSuccessModel;
export const invoiceSelector = (state) => state.payment.invoice;
export const loadingSelector = (state) => state.payment.loading;
export const getIsSuccessful = (state) => state.payment.isSuccessful;
export const globalMonthlyCheckSelector = (state) => state.payment.globalMonthlyCheck;
export const isPayAsYouGoSelector = (state) => state.payment.isPayAsYouGo;
export const openCancelSubscriptionModelSelector = (state) => state.payment.openCancelSubscriptionModel;
export const openChangeplanModelSelector = (state) => state.payment.openChangeplanModel;
export const addonsSelector = (state) => state.payment.addons;
export const mediaFileModalSelector = (state) => state.payment.mediaFileModal;
export const errorMsgTypeSelector = (state) => state.payment.errorMsgType;
export const getCardError = (state) => state.payment.cardError;
export const getIsCancelled = (state) => state.payment.isCancelled;
export const getIsCancelledSubscription = (state) => state.payment.cancelledSubscription;

const trackSuccessfulPayment = ({ plan, isPayG, addOn, oldPlan }) => {
  if (isPayG) {
    analytics.track(`PAYG ${addOn.name} Successfully Purchased`, {
      current_plan: oldPlan,
    });
  } else {
    // track if plan is upgraded or downgraded
    const actionType = isUpgradeOrDowngrade(oldPlan, plan);

    if (actionType === "downgrade") {
      analytics.track("Plan Downgraded Successfully", {
        addons: addOn?.id || null,
        addonPrice: addOn?.amount || 0,
        old_plan: oldPlan,
      });
    } else {
      analytics.track("Plan Upgraded Successfully", {
        addons: addOn?.id || null,
        addonPrice: addOn?.amount || 0,
        old_plan: oldPlan,
      });
    }
  }
};

export const pay = createAsyncThunk(
  "payment/payment",
  async (payload, thunkAPI) => {
    const { dispatch, getState, rejectWithValue } = thunkAPI;
    const { plan, coupon } = payload;
    const oldPlan = getUserPlan(getState().user.plan)?.formatted.name;

    dispatch(setLoading(true));

    analytics.track("Payment Initiated", {
      plan: plan.planCode || "",
      add_on: "",
    });

    rollbar.info("Payment initiation", {
      plan,
      add_on: null,
      isPayAsYouGo: false,
    });

    try {
      const data = await payment({ plan: plan.planCode, coupon });

      return { data, plan, oldPlan };
    } catch (e) {
      let { message } = e;

      if (e.response && e.response.data) {
        if (e.response.data.error) {
          message = e.response.data.error;

          dispatch(setCardError(message));
        }
      }

      dispatch(setToast({ message, severity: "error" }));
      dispatch(setOpenCancelSubscriptionModel(false));

      rollbar.error("Payment error", {
        plan,
        add_on: null,
        isPayAsYouGo: false,
        cancel: plan.name === "FREE",
        error: message,
      });

      return rejectWithValue(e);
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const confirmPayment = createAsyncThunk("payment/confirm", async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { plan, oldPlan } = payload;

  dispatch(setIsSuccessful(true));

  setTimeout(() => {
    // We need to wait a bit for Stripe to trigger a successful
    // webhook so the backend can update the user info.
    dispatch(getUserInfo());
    dispatch(setIsSuccessful(false)); // reset
  }, 5000);

  const options = {
    plan: plan.planCode,
    amount: plan.amount,
    isPayG: false,
    addOn: null,
    oldPlan,
  };

  // to track revenue event in FB and GTM
  trackSuccessfulPayment(options);
  GTM.trackPurchasedItems(plan, null, false);

  rollbar.info("Payment successful", { plan, add_on: null, isPayAsYouGo: false });
});

export const cancel = createAsyncThunk("payment/cancel", async (payload, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const oldPlan = getState().user.planType;

  dispatch(setLoading(true));
  dispatch(setCardError(null));

  rollbar.info("Subscription cancellation initiated", {
    oldPlan,
  });

  try {
    if (payload) {
      await applyDiscount({ coupon_type: payload });
      const reason = { "25POFF": "25% Lifetime Discount Applied", "99POFF": "99% Off Discount Applied" }[payload]
        || "Subscription paused successfully.";

      dispatch(setToast({ message: reason, severity: "success", autoClose: true }));
      analytics.track(reason);
    } else {
      await cancelSubscription();

      dispatch(setToast({ message: "Subscription plan cancelled", severity: "success", autoClose: true }));
      dispatch(setCancelledSubscription(payload || "CANCELLED"));

      analytics.track("Canceled Subscription Successfully", {
        plan: oldPlan,
      });
    }

    setTimeout(() => {
      // We need to wait a bit for Stripe to trigger a successful
      // webhook so the backend can update the user info.
      dispatch(getUserInfo()).finally(() => {
        dispatch(setLoading(false));
      });
      dispatch(setIsCancelled(true));

      setTimeout(() => {
        dispatch(setIsCancelled(false));
      }, 1500);
    }, 5000);

    rollbar.info("Subscription plan cancelled", { oldPlan });
  } catch (e) {
    let { message } = e;

    if (e.response && e.response.data) {
      if (e.response.data.error) {
        message = e.response.data.error;
      }
    }

    dispatch(setCardError(message));
    dispatch(
      setToast({
        message,
        severity: "error",
        autoClose: true,
      }),
    );

    rollbar.error("Subscription cancellation error", {
      plan: oldPlan,
      cancel,
      error: message,
    });
    dispatch(setLoading(false));
  }
});

export const resumePlan = createAsyncThunk("payment/resumePlan", async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(setLoading(true));

  try {
    await resumePlanApi();
    dispatch(setToast({ message: "Subscription plan resumed", severity: "success", autoClose: true }));

    setTimeout(() => {
      dispatch(getUserInfo()).finally(() => {
        dispatch(setLoading(false));
      });
    }, 5000);
  } catch (e) {
    let { message } = e;

    if (e.response && e.response.data) {
      if (e.response.data.error) {
        message = e.response.data.error;
      }
    }

    dispatch(setLoading(false));
    dispatch(
      setToast({
        message,
        severity: "error",
        autoClose: true,
      }),
    );
  }
});

export default paymentSlice.reducer;
