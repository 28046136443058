import { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useRollbarPerson } from "@rollbar/react";
import DAIRoutes from "DAI/routes";
import LazyLoadingFallback from "Core/components/LazyLoadingFallback";
import CoreRoutes from "Core/routes";
import OnboardingNagger from "DAI/UserOnboarding/containers/OnboardingNagger";
import Wrapper from "hoc/wrapper";
import ScrollToTop from "components/ScrollToTop";
import { hideToast } from "store/toastSlice";

const RenewalChecker = lazy(() => import("DAI/Subscription/containers/RenewalChecker"));
const SubscriptionLock = lazy(() => import("DAI/Subscription/containers/SubscriptionLock"));
const AppVersionChecker = lazy(() => import("Core/components/AppVersionChecker"));

export default function DeciphrRoutes() {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const { search } = location;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isDAIAccount = userInfo ? !userInfo.org_role : false;

  useRollbarPerson(userInfo?.user);

  // Hide non-auto-closing toasts on route change
  useEffect(() => {
    dispatch(hideToast());
  }, [path, search, dispatch]);

  return (
    <Suspense fallback={<LazyLoadingFallback />}>
      <ScrollToTop />

      <RenewalChecker />

      <SubscriptionLock />

      <OnboardingNagger />

      <AppVersionChecker />

      <Wrapper>
        <CoreRoutes isEnabled={userInfo === null} />

        <DAIRoutes isEnabled={userInfo !== null && isDAIAccount} />
      </Wrapper>
    </Suspense>
  );
}
