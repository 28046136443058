import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addDictionary,
  deleteDictionary,
  getDictionary,
  updateBoost,
  updateDictionary,
} from "DAI/contentBrain/service/dictionaryAPIs";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";

const dictionarySlice = createSlice({
  name: "dictionary",
  initialState: {
    isloading: false,
    updateLoading: false,
    filteredPhrases: [],
    phrases: [],
    sort: "newest",
    boost: "default",
  },

  reducers: {
    setDictionaryLoading: (state, action) => {
      state.isloading = action.payload;
    },
    setUpdateLoading: (state, action) => {
      state.updateLoading = action.payload;
    },
    setDictionaryList: (state, action) => {
      state.filteredPhrases = action.payload || [];
      state.phrases = action.payload || [];
    },
    deleteDictionaryFromList: (state, action) => {
      const newList = state.filteredPhrases.filter(
        (item) => item.id !== action.payload,
      );
      state.filteredPhrases = newList;
      state.phrases = newList;
    },
    updateDictionaryInList: (state, action) => {
      const newList = state.filteredPhrases.map(
        (item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        },
      );
      state.filteredPhrases = newList;
      state.phrases = newList;
    },
    addDictionaryToList: (state, action) => {
      const newList = [action.payload, ...state.filteredPhrases];
      state.filteredPhrases = newList;
      state.phrases = newList;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    searchDictionaryList: (state, action) => {
      if (!action.payload) {
        state.filteredPhrases = state.phrases;

        return;
      }

      const newList = state.phrases.filter(
        (item) => item.phrase.toLowerCase().includes(action.payload.toLowerCase()),
      );
      state.filteredPhrases = newList;
    },
    setBoost: (state, action) => {
      state.boost = action.payload;
    },
  },
});

export const {
  setDictionaryLoading,
  setUpdateLoading,
  setDictionaryList,
  deleteDictionaryFromList,
  updateDictionaryInList,
  addDictionaryToList,
  setSort,
  searchDictionaryList,
  setBoost,
} = dictionarySlice.actions;

export const dictionaryListSelector = (state) => state.dictionary.filteredPhrases;
export const dictionaryLoadingSelector = (state) => state.dictionary.isloading;
export const updateLoadingSelector = (state) => state.dictionary.updateLoading;
export const sortSelector = (state) => state.dictionary.sort;
export const boostSelector = (state) => state.dictionary.boost;

export const addDictionaryAction = createAsyncThunk(
  "dictionary/addDictionary",
  async (payload, { dispatch }) => {
    dispatch(setUpdateLoading(true));

    try {
      const data = await addDictionary(payload);
      dispatch(
        addDictionaryToList({
          ...payload,
          id: data.id,
        }),
      );
      dispatch(
        setToast({
          message: "Dictionary added successfully",
          autoClose: true,
          severity: "success",
        }),
      );
      const eventNames = {
        new_term: "Deciphr Tools Dictionary - Added New Term",
        custom_spelling: "Deciphr Tools Dictionary - Added Custom Spelling",
      };
      analytics.track(eventNames[payload.type], {
        phrase: payload.phrase,
        custom_spelling: payload.custom_spelling,
      });
    } catch (e) {
      dispatch(
        setToast({ message: e.message, severity: "error", autoClose: true }),
      );
    } finally {
      dispatch(setUpdateLoading(false));
    }
  },
);

export const updateDictionaryAction = createAsyncThunk(
  "dictionary/updateDictionary",
  async (payload, { dispatch }) => {
    dispatch(setUpdateLoading(true));

    try {
      await updateDictionary(payload.id, payload.data);
      dispatch(updateDictionaryInList({ id: payload.id, ...payload.data }));
      dispatch(
        setToast({
          message: "Dictionary updated successfully",
          autoClose: true,
          severity: "success",
        }),
      );
      analytics.track("dictionary_updated", {
        dictionary_name: payload.data.name,
        dictionary_id: payload.id,
      });
    } catch (e) {
      dispatch(
        setToast({ message: e.message, severity: "error", autoClose: true }),
      );
    } finally {
      dispatch(setUpdateLoading(false));
    }
  },
);

export const deleteDictionaryAction = createAsyncThunk(
  "dictionary/deleteDictionary",
  async (payload, { dispatch }) => {
    dispatch(setUpdateLoading(true));

    try {
      await deleteDictionary(payload.id);
      dispatch(deleteDictionaryFromList(payload.id));
      dispatch(
        setToast({
          message: "Dictionary deleted successfully",
          severity: "success",
          autoClose: true,
        }),
      );
      const eventNames = {
        new_term: "Deciphr Tools Dictionary - Deleted New Term",
        custom_spelling: "Deciphr Tools Dictionary - Deleted Custom Spelling",
      };
      analytics.track(eventNames[payload.type]);
    } catch (e) {
      dispatch(
        setToast({ message: e.message, severity: "error", autoClose: true }),
      );
    } finally {
      dispatch(setUpdateLoading(false));
    }
  },
);

export const getDictionaryAction = createAsyncThunk(
  "dictionary/getDictionary",
  async (_, { dispatch, getState }) => {
    const { dictionary } = getState();
    const { sort } = dictionary;
    dispatch(setDictionaryLoading(true));

    try {
      const data = await getDictionary({ sort });
      dispatch(setDictionaryList(data.dictionary));
      dispatch(setBoost(data.boost));
    } catch (e) {
      dispatch(
        setToast({ message: e.message, severity: "error", autoClose: true }),
      );
    } finally {
      dispatch(setDictionaryLoading(false));
    }
  },
);

export const updateBoostAction = createAsyncThunk(
  "dictionary/updateBoost",
  async (payload, { dispatch }) => {
    dispatch(setUpdateLoading(true));

    try {
      await updateBoost(payload);
      dispatch(
        setToast({
          message: "Boost updated successfully",
          severity: "success",
          autoClose: true,
        }),
      );
      analytics.track("dictionary_boost_updated", {
        boost: payload.boost,
      });
    } catch (e) {
      dispatch(
        setToast({ message: e.message, severity: "error", autoClose: true }),
      );
    } finally {
      dispatch(setUpdateLoading(false));
    }
  },
);

export default dictionarySlice.reducer;
