import axios from "utils/axiosConfig";

export const createStyle = async () => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/writing-style",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getStyles = async (params) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/writing-style",
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getOneStyle = async (styleId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/writing-style/${styleId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const editStyle = async (styleId, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/writing-style/${styleId}`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteStyle = async (styleId) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: `/writing-style/${styleId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const uploadFile = async (styleId, file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const { data } = await axios({
      method: "POST",
      url: `/writing-style/${styleId}/upload`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getStyleOutput = async (styleId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/writing-style/samples/${styleId}`,
    });

    return data;
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

// export const setDefaultStyle = async (payload) => {}

export const getUrlMetadata = async (url) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/writing-style/get-metadata",
      params: { url },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
