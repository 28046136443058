import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { selectToast, hideToast } from "store/toastSlice";
import { styles } from "./styles";
import errorIcn from "assets/errorIcn.svg";

export default function ErrorBar() {
  const toast = useSelector(selectToast);
  const isOpen = toast?.show && toast?.severity === "error";
  const ref = useRef(null);
  const closeButtonRef = useRef(null);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideToast());
  };

  //hide banner after 0.5s when click close button
  //css animation doesn't work with display none
  useEffect(() => {
    let timeoutId;

    if (!isOpen) {
      if (ref.current.style.display === "") {
        ref.current.style.display = "none";

        return;
      }

      timeoutId = setTimeout(() => {
        ref.current.style.display = "none";
      }, 500);
    } else {
      ref.current.style.display = "flex";

      closeButtonRef.current.focus();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  return (
    <Box
      ref={ref}
      sx={{
        ...styles.root,
        animation: !isOpen ? "closeBanner 0.3s ease-in-out forwards" : "openBanner 0.3s ease-in-out forwards",
        "@keyframes closeBanner": {
          "0%": {
            transform: "translateY(0)",
            opacity: 1,
          },
          "100%": {
            transform: "translateY(-100%)",
            opacity: 0,
            height: 0,
          },
        },
        "@keyframes openBanner": {
          "0%": {
            transform: "translateY(-100%)",
            opacity: 0,
            height: 0,
          },
          "100%": {
            transform: "translateY(0)",
            opacity: 1,
          },
        },
      }}
    >
      <Box>
        <Typography sx={styles.text}>
          <img src={errorIcn} alt="" aria-hidden="true" />

          <span>
            {toast.message}
          </span>
        </Typography>
      </Box>

      <Box>
        <IconButton
          aria-label="close"
          ref={closeButtonRef}
          onClick={handleClose}
        >
          <CloseIcon sx={styles.icon} />
        </IconButton>
      </Box>
    </Box>
  );
}
