import { createTheme, responsiveFontSizes } from "@mui/material";

const themeDefinition = createTheme({
  palette: {
    primary: {
      main: "rgba(101, 82, 243, 1)",
      contrastText: "rgba(252, 252, 253, 1)",
      100: "#F5F4FF",
      200: "#DDD9FF",
      300: "#B5B3FF",
      400: "#8980ff",
      500: "#6552F3",
      600: "#5C4CD8",
      700: "#3E2FAF",
      800: "#261672",
    },
    gray: {
      main: "rgba(238, 238, 238, 1)",
      contrastText: "rgba(32, 34, 37, 1)",
      100: "#FCFCFD",
      200: "#F2F4F7",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#1B2730",
      800: "#10181E",
    },
    brand: {
      100: "#F5F4FF",
      200: "#DDD9FF",
      300: "#b5b3ff",
      400: "#8980ff",
      500: "#6552F3",
      600: "#5C4CD8",
      700: "#3E2FAF",
      800: "#261672",
    },
    light: {
      100: "#333F4E",
    },
    neutral: {
      200: "#23262F",
    },
    error: {
      100: "#FEF3F2",
      200: "#FECDCA",
      300: "#FDA29B",
      400: "#F97066",
      500: "#F04438",
      600: "#D92D20",
      700: "#B42318",
      800: "#912018",
    },
    warning: {
      100: "#FFFCF5",
      200: "#FEF0C7",
      300: "#FEDF89",
      400: "#FEC84B",
      500: "#FDB022",
      600: "#F79009",
      700: "#DC6803",
      800: "#B54708",
    },
    success: {
      100: "#ECFDF3",
      200: "#D1FADF",
      300: "#6CE9A6",
      400: "#32D583",
      500: "#12B76A",
      600: "#039855",
      700: "#027A48",
      800: "#05603A",
    },
    blg: {
      100: "#ECFEFF",
      200: "#CFFCFF",
      300: "#AFF0F4",
      400: "#84EBF0",
      500: "#4FDDE4",
      600: "#21C6CE",
      700: "#15ACB3",
      800: "#077A80",
    },
    ind: {
      100: "#EEF4FF",
      200: "#C7D7FE",
      300: "#A4BCFD",
      400: "#8098F9",
      500: "#6172F3",
      600: "#444CE7",
      700: "#2D31A6",
      800: "#2D3282",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      xmd: 1080,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          borderRadius: 999,
          fontFamily: "DM Sans",
          fontSize: "0.875rem",
          lineHeight: "1rem",
          padding: "0.75rem 1rem",
          minWidth: "150px",
          boxShadow: "none",

          "&:hover": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          },
        },
      },
      variants: [
        {
          props: { variant: "light" },
          style: {
            outline: "1.5px solid #D9D9D9",
            fontWeight: 400,
            color: "#23262F",
            outlineOffset: "-1.5px",

            "&:hover": {
              boxShadow: "none",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            outline: "1px solid",
            outlineColor: "primary.main",
            outlineOffset: "-1px",
          },
        },
        {
          props: { variant: "ghost" },
          style: {
            backgroundColor: "#eee",
            color: "#23262F",
            "&:hover": {
              backgroundColor: "#eee",
            },
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "inherit",
          fontFamily: "inherit",
          color: "inherit",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0",
          color: "#C4C4C4",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "DM Sans", "Poppins"],
    button: {
      textTransform: "capitalize",
    },
  },
});

const theme = responsiveFontSizes(themeDefinition);

export default theme;
