import { createSlice } from "@reduxjs/toolkit";

const downloadSlice = createSlice({
  name: 'download',
  initialState: {
    summary: true,
    quotes: true,
    chapters: true,
    transcript: true,
    audiogram: false,
    videoreels: false,
    isPopupOpen: false,
  },

  reducers: {
    toggleOpen: (state) => {
      state.isPopupOpen = !state.isPopupOpen;
    },

    selectDefault: (state) => {
      state.summary = true;
      state.quotes = true;
      state.chapters = true;
      state.transcript = true;
      state.audiogram = false;
      state.videoreels = false;
    },

    selectDefaultWithAudiogram: (state) => {
      state.summary = true;
      state.quotes = true;
      state.chapters = true;
      state.transcript = true;
      state.audiogram = true;
      state.videoreels = false;
    },

    selectDefaultWithMedia: (state) => {
      state.summary = true;
      state.quotes = true;
      state.chapters = true;
      state.transcript = true;
      state.audiogram = true;
      state.videoreels = true;
    },

    selectMedia: (state) => {
      state.summary = false;
      state.quotes = false;
      state.chapters = false;
      state.transcript = false;
      state.audiogram = true;
      state.videoreels = true;
    },

    selectOptions: (state, action) => {
      Object.entries(action.payload).forEach((option) => {
        state[option[0]] = option[1];
      });
    },

    selectAudiogramOnly: (state) => {
      state.audiogram = true;
      state.summary = false;
      state.quotes = false;
      state.chapters = false;
      state.transcript = false;
      state.videoreels = false;
    },

    selectVideoReelsOnly: (state) => {
      state.videoreels = true;
      state.summary = false;
      state.quotes = false;
      state.chapters = false;
      state.transcript = false;
      state.audiogram = false;
    },
  },
});

export const {
  toggleOpen,
  selectDefault,
  selectOptions,
  selectAudiogramOnly,
  selectVideoReelsOnly,
  selectMedia,
  selectDefaultWithAudiogram,
  selectDefaultWithMedia,
} = downloadSlice.actions;

export const isPopupOpen = (state) => state.download.isPopupOpen;

export const getSelectedOptions = (state) => {
  const selected = {
    summary: state.download.summary,
    quotes: state.download.quotes,
    chapters: state.download.chapters,
    transcript: state.download.transcript,
    audiogram: state.download.audiogram,
    videoreels: state.download.videoreels,
    article: false,
    show_notes: false,
    tweet: false,
    caption: false,
  };

  return selected;
};

export default downloadSlice.reducer;
