import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getPlannerContent,
  getPlannerList,
  getStatus,
  pendingFileStatus,
  uploadRecordedAudio,
} from "DAI/contentBrain/service/plannerAPIs";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";

const plannerSlice = createSlice({
  name: "planner",
  initialState: {
    plannerContent: {},
    uploadAudioLoader: false,
    getPlannerContentLoader: false,
    uploadedAudioId: null,
    uploadedFileProcessed: null,
    isError: false,
    sort: "date_desc",
    limit: 20,
    areAllFilesFetched: false,
    plannerList: [],
    isLoading: false,
    isInitalMount: false,
  },

  reducers: {
    setPlannerContent: (state, action) => {
      state.plannerContent = action.payload;
    },
    setUploadAudioLoader: (state, action) => {
      state.uploadAudioLoader = action.payload;
    },
    contentLoader: (state, action) => {
      state.getPlannerContentLoader = action.payload;
    },
    setUploadedAudioId: (state, action) => {
      state.uploadedAudioId = action.payload;
    },
    setUploadedFileProcessed: (state, action) => {
      state.uploadedFileProcessed = action.payload;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    updateSort: (state, action) => {
      if (state.sort !== action.payload) {
        state.sort = action.payload;
        state.plannerList = [];
        state.areAllFilesFetched = false;
      }
    },
    setAllFilesFetched: (state, action) => {
      state.areAllFilesFetched = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateFilesList: (state, action) => {
      const { plannerList, opType } = action.payload;
      const operation = opType || "append"; // default

      if (plannerList) {
        if (operation === "append") {
          state.plannerList = state.plannerList.concat(plannerList);
        } else {
          state.plannerList = plannerList;
        }
      }
    },
    updateFileStatusToProcessed: (state, action) => {
      const index = state.plannerList.findIndex(
        (file) => file.id === action.payload.id,
      );

      if (index > -1) {
        state.plannerList[index].processed = action.payload.processed;
        state.plannerList[index].title = action.payload.title;
      }
    },
    setInitialMount: (state, action) => {
      state.isInitalMount = action.payload;
    },
  },
});

export const {
  setPlannerContent,
  setUploadAudioLoader,
  contentLoader,
  setUploadedAudioId,
  setUploadedFileProcessed,
  setIsError,
  updateSort,
  setAllFilesFetched,
  setIsLoading,
  updateFilesList,
  updateFileStatusToProcessed,
  setInitialMount,
} = plannerSlice.actions;

export const plannerContentSelector = (state) => state.planner.plannerContent;
export const uploadAudioLoaderSelector = (state) => state.planner.uploadAudioLoader;
export const getPlannerContentLoaderSelector = (state) => state.planner.getPlannerContentLoader;
export const uploadedAudioIdSelector = (state) => state.planner.uploadedAudioId;
export const uploadedFileProcessedSelector = (state) => state.planner.uploadedFileProcessed;
export const isErrorSelector = (state) => state.planner.isError;
export const selectParams = (state) => new URLSearchParams({
  sort: state.planner.sort,
  start: state.planner.plannerList.length,
  limit: state.planner.limit,
});
export const plannerListSelector = (state) => state.planner.plannerList;
export const areAllFilesFetched = (state) => state.planner.areAllFilesFetched;
export const isLoading = (state) => state.planner.isLoading;
export const isInitialMountSelector = (state) => state.planner.isInitalMount;
export const sortSelector = (state) => state.planner.sort;

export const uploadRecordedAudioAction = createAsyncThunk(
  "planner/uploadRecordedAudio",
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setUploadAudioLoader(true));

    try {
      const { file_id } = await uploadRecordedAudio(payload);
      dispatch(
        setToast({
          message: "Audio file uploaded successfully",
          autoClose: true,
          severity: "success",
        }),
      );
      analytics.track("Deciphr Tools Plan - Recorded Audio Successfully");
      dispatch(setUploadedAudioId(file_id));
      dispatch(getAllPlannerFiles({ fromStart: true }));
    } catch (e) {
      dispatch(setIsError(true));
      dispatch(
        setToast({
          message: e.response.data.error || e.message,
          severity: "error",
          autoClose: true,
        }),
      );
      return rejectWithValue(e);
    } finally {
      dispatch(setUploadAudioLoader(false));
    }
  },
);

export const getStatusAction = createAsyncThunk(
  "planner/getStatus",
  async (audioId, { rejectWithValue, dispatch }) => {
    try {
      const { processed } = await getStatus(audioId);

      if (processed === "Y") {
        dispatch(setUploadedAudioId(null));
        dispatch(
          setToast({
            message: "Audio file processed successfully",
            autoClose: true,
            severity: "success",
          }),
        );
        setTimeout(() => {
          dispatch(setUploadedFileProcessed(audioId));
        }, 1000);
      }
    } catch (e) {
      dispatch(
        setToast({ message: e.message, severity: "error", autoClose: true }),
      );
      return rejectWithValue(e);
    }
  },
);

export const getPlannerContentAction = createAsyncThunk(
  "planner/getPlannerContent",
  async (audioId, { dispatch }) => {
    dispatch(contentLoader(true));

    try {
      const data = await getPlannerContent(audioId);
      dispatch(setPlannerContent(data));
    } catch (e) {
      dispatch(
        setToast({ message: e.message, severity: "error", autoClose: true }),
      );
    } finally {
      dispatch(contentLoader(false));
    }
  },
);

export const getAllPlannerFiles = createAsyncThunk(
  "planner/getAllPlannerFiles",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { planner } = getState();
    const { plannerList, limit, areAllFilesFetched, isLoading } = planner;
    const currLimit = plannerList.length ? plannerList.length + 1 : null;
    const params = selectParams(getState());

    if (payload?.fromStart) {
      params.set("start", 0);
      params.set("limit", currLimit || limit);
    }

    if (areAllFilesFetched && !payload?.fromStart) {
      return;
    }

    if (isLoading) {
      return;
    }

    dispatch(setIsLoading(true));

    try {
      const { data } = (await getPlannerList(params)) || [];
      const opType = payload?.fromStart ? "overwrite" : "append";

      dispatch(updateFilesList({ plannerList: data, opType }));

      if (data.length < limit) {
        // No more files to fetch
        dispatch(setAllFilesFetched(true));
      }
    } catch (e) {
      dispatch(
        setToast({
          message: "Files could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        }),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  },
);

export const statusCheckAndUpdate = createAsyncThunk(
  "planner/statusCheckAndUpdate",
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { planner } = getState();
    const { plannerList } = planner;
    const unprocessedFiles = plannerList.filter(
      (file) => file.processed === "N" || file.status === "processing",
    );

    try {
      const { data } = await pendingFileStatus();
      unprocessedFiles.forEach((content) => {
        const index = data.findIndex((file) => file.id === content.id);

        if (index > -1) {
          dispatch(updateFileStatusToProcessed(data[index]));
        }
      });
    } catch (e) {
      dispatch(
        setToast({
          message: "Files could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        }),
      );
    }
  },
);

export default plannerSlice.reducer;
