import PropTypes from "prop-types";

export default function BrandLogo({ url, name }) {
  return (
    <img
      src={url}
      alt={name}
      title={name}
      loading="lazy"
      height="28"
      style={{ margin: "12px 8px" }}
    />
  );
}

BrandLogo.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
