import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  position: { top: 0, left: 0 },
  show: false,
  selectedBlocks: [],
  selection: null,
};

export const assistantSlice = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    setPosition: (state, action) => {
      state.position = action.payload;
    },

    setShow: (state, action) => {
      state.show = action.payload;
    },

    setSelectionInfo: (state, action) => {
      const keys = ["anchorOffset", "focusOffset"];
      const selection = {};

      state.selectedBlocks = action.payload.blocks;

      if (action.payload.blocks.length === 1) {
        // We only care about storing selection offsets if
        // the selection is within a single block
        const sel = window.getSelection();

        // eslint-disable-next-line no-restricted-syntax
        for (const p in sel) {
          if (keys.includes(p)) {
            selection[p] = sel[p];
          }
        }

        selection.text = sel.toString().trim();
        state.selection = selection;
      } else if (action.payload.blocks.length === 0) {
        // Edge cases where blocks.length === 0
        const sel = window.getSelection();

        // eslint-disable-next-line no-restricted-syntax
        for (const p in sel) {
          if (keys.includes(p)) {
            selection[p] = sel[p];
          }
        }

        selection.text = sel.toString().trim();
        state.selection = selection;
      } else {
        // We don't care about selection offsets and nodes.
        // All text in each selected block will be replaced
        // by the AI Assistant output.
        state.selection = null;
      }
    },

    resetSelection: (state) => {
      state.selectedBlocks = [];
      state.selection = null;
      state.show = false;
    },
  },
});

export const {
  setPosition,
  setShow,
  setSelectionInfo,
  resetSelection,
} = assistantSlice.actions;

export const getPosition = (state) => state.assistant.position;
export const isVisible = (state) => state.assistant.show;
export const getSelectedBlocks = (state) => state.assistant.selectedBlocks;
export const isMultiBlockSelection = (state) => state.assistant.selectedBlocks.length > 1;
export const getSelectedOffsets = (state) => state.assistant.selection;

export default assistantSlice.reducer;
