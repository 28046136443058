import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPage,
  getPagesFiles,
  createPage,
  updatePage,
  deletePage,
} from "DAI/contentBrain/service/pagesAPIs";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";

const pageSlice = createSlice({
  name: "pages",
  initialState: {
    pageData: {},
    pageFiles: [],
    isLoading: false,
    areAllFilesFetched: false,
    isInitalMount: false,
    sort: "desc",
    limit: 20,
    filter: "all",
  },

  reducers: {
    updateSort: (state, action) => {
      if (state.sort !== action.payload) {
        state.sort = action.payload;
        state.pageFiles = [];
        state.areAllFilesFetched = false;
      }
    },
    updateFilter: (state, action) => {
      if (state.filter !== action.payload) {
        state.filter = action.payload;
        state.pageFiles = [];
        state.areAllFilesFetched = false;
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateFilesList: (state, action) => {
      const { pageFiles, opType } = action.payload;
      const operation = opType || "append"; // default
      if (pageFiles) {
        if (operation === "append") {
          state.pageFiles = state.pageFiles.concat(pageFiles);
        } else {
          state.pageFiles = pageFiles;
        }
      }
    },
    setInitialMount: (state, action) => {
      state.isInitalMount = action.payload;
    },
    setAllFilesFetched: (state, action) => {
      state.areAllFilesFetched = action.payload;
    },
    setPageData: (state, action) => {
      state.pageData = action.payload;
    },
  },
});

export const {
  updateFilesList,
  setIsLoading,
  setInitialMount,
  updateSort,
  setAllFilesFetched,
  setPageData,
  updateFilter,
} = pageSlice.actions;

export const selectParams = (state) => {
  return new URLSearchParams({
    sort: state.pages.sort,
    start: state.pages.pageFiles.length,
    limit: state.pages.limit,
  });
};
export const pageFileSelector = (state) => state.pages.pageFiles;
export const isLoadingSelector = (state) => state.pages.isLoading;
export const areAllFilesFetchedSelector = (state) =>
  state.pages.areAllFilesFetched;
export const isInitalMountSelector = (state) => state.pages.isInitalMount;
export const sortSelector = (state) => state.pages.sort;
export const filterSelector = (state) => state.pages.filter;
export const pageDataSelector = (state) => state.pages.pageData;

export const getAllPageFiles = createAsyncThunk(
  "pages/getAllPageFiles",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { pages } = getState();
    const { pageFiles, limit, areAllFilesFetched, isLoading } = pages;
    const params = selectParams(getState());

    if (payload?.fromStart) {
      params.set("start", 0);
      params.set("limit", pageFiles.length || limit);
    }
    if (areAllFilesFetched && !payload?.fromStart) {
      return;
    }
    if (isLoading) {
      return;
    }

    dispatch(setIsLoading(true));

    try {
      const { files } = (await getPagesFiles(params)) || []; //api change
      const opType = payload?.fromStart ? "overwrite" : "append";

      dispatch(updateFilesList({ pageFiles: files, opType }));

      if (files.length < limit) {
        // No more files to fetch
        dispatch(setAllFilesFetched(true));
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setToast({
          message: "Files could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getPageData = createAsyncThunk(
  "pages/getPageData",
  async (_, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { pages } = getState();
    const { pageData } = pages;

    if (pageData?.id) return;

    try {
      const { pages } = (await getPage()) || {};
      if (pages?.length > 0) {
        const pageData = pages[0];
        const { files, ...data } = pageData;

        const pagesNewData = {
          ...data,
          doc_ids: files,
        };
        dispatch(setPageData(pagesNewData));
      }
    } catch (e) {
      dispatch(
        setToast({
          message: "Page data could not be retrieved. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    }
  }
);

export const publishPageHandler = createAsyncThunk(
  "pages/publishPageHandler",
  async (payload, thunkAPI) => {
    const { files, value } = payload;
    const { dispatch } = thunkAPI;
    try {
      const data = await createPage(value);
      value.id = data.id;
      value.doc_ids = files;
      
      dispatch(setPageData(value));
      analytics.track("publishPage", {
        category: "Pages",
        action: "Publish Page",
        label: data?.podcast_name,
      });
    } catch (e) {
      dispatch(
        setToast({
          message: e?.response?.data?.errors[0] || "Page could not be published. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    }
  }
);

export const updatePageHandler = createAsyncThunk(
  "pages/updatePageHandler",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { value, id, files } = payload;
    try {
      const data = await updatePage(id, value);
      value.id = id;
      value.doc_ids = files;

      dispatch(setPageData(value));
      analytics.track("updatePage", {
        category: "Pages",
        action: "Update Page",
        label: data?.podcast_name,
      });
    } catch (e) {
      console.log(e);
      dispatch(
        setToast({
          message: "Page could not be updated. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    }
  }
);

export const unPublishPageHandler = createAsyncThunk(
  "pages/unPublishPageHandler",
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { pages } = getState();
    const { pageData } = pages;
    
    try {
      await deletePage(pageData.id);
      analytics.track("unPublishPage", {
        category: "Pages",
        action: "Unpublish Page",
        label: pageData?.podcast_name,
      });
      dispatch(setPageData({}));
    } catch (e) {
      console.log(e);
      dispatch(
        setToast({
          message: "Page could not be unpublished. Please try again.",
          severity: "error",
          autoClose: true,
        })
      );
    }
  }
);

export default pageSlice.reducer;
