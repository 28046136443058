import axios from "utils/axiosConfig";

export const addDictionary = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/dictionary",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateDictionary = async (dictionaryId, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: "/dictionary/" + dictionaryId,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteDictionary = async (dictionaryId) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: "/dictionary/" + dictionaryId,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDictionary = async (sort) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/dictionary",
      params: sort,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateBoost = async (payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: "/dictionary/boost",
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
