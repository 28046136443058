import { useEffect, useRef } from "react";
import { Provider } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  ErrorBoundary,
  historyContext,
  Provider as RollbarProvider,
} from "@rollbar/react";
import { createBrowserHistory } from "history";
import { PostHogProvider } from "posthog-js/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { rollbar } from "Core/helpers/rollbar";
import DeciphrRoutes from "routes";
import store from "store";
import { history } from "utils/history";
import { updateIntercom } from "utils/services";

const Analytics = {};
const browserHistory = createBrowserHistory();

// Lazy load to reduce the main bundle size
async function importAnalytics() {
  try {
    const module = await import("utils/services");

    Analytics.initServices = module.initServices;
    Analytics.analyticsUpdate = module.analyticsUpdate;

    Analytics.initServices();
  } catch (e) {
    //
  }
}

function App() {
  const isInitialized = useRef(false);

  history.navigate = useNavigate();
  history.location = useLocation();

  const POSTHOG_API_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
  const POSTHOG_HOST = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;
  const POSTHOG_OPTIONS = { api_host: POSTHOG_HOST };
  const path = history.location.pathname;
  const { search } = history.location;

  useEffect(() => {
    const initialize = async () => {
      if (isInitialized.current === false) {
        await importAnalytics();

        Analytics?.initServices();

        isInitialized.current = true;
      }

      updateIntercom();
      Analytics?.analyticsUpdate(path, search);
    };

    initialize();
  }, [path, search]);

  useEffect(() => {
    const unlisten = browserHistory.listen(historyContext(rollbar));

    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, []);

  return (
    <Provider store={store}>
      <PostHogProvider apiKey={POSTHOG_API_KEY} options={POSTHOG_OPTIONS}>
        <HelmetProvider>
          <Helmet>
            <title>Deciphr AI</title>
          </Helmet>

          <RollbarProvider instance={rollbar}>
            <ErrorBoundary>
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <DeciphrRoutes />
              </GoogleOAuthProvider>
            </ErrorBoundary>
          </RollbarProvider>
        </HelmetProvider>
      </PostHogProvider>
    </Provider>
  );
}

export default App;
