import { Navigate, Outlet } from "react-router-dom";

export default function DAIProtectedRoute() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo?.token;
  const payload = token ? JSON.parse(atob(token.split(".")[1])) : null;
  const expiry = payload ? new Date(payload.expiry * 1000) : null;

  // Check logged in status and token validity
  if (expiry < new Date()) {
    const state = {
      referrer: window.location.pathname,
      message: "You must sign in to your Deciphr account to continue.",
    };

    localStorage.removeItem("userInfo");

    return <Navigate to="/auth/login" state={state} />;
  }

  return <Outlet />;
}
