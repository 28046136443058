import PropTypes from "prop-types";
import theme from "theme";
import LinearProgress from "@mui/material/LinearProgress";

export default function CreditsProgressBar({ value, color, height = "8px" }) {
  return (
    <LinearProgress
      value={value || 0}
      variant={value ? "determinate" : "indeterminate"}
      aria-hidden
      sx={{
        height,
        width: "100%",
        borderRadius: 999,
        bgcolor: "#E6E8EC",

        "& .MuiLinearProgress-bar": {
          borderRadius: 999,
        },

        "& > span": {
          bgcolor: color || theme.palette.primary[500],
        },
      }}
    />
  );
}

CreditsProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  height: PropTypes.string,
};

CreditsProgressBar.defaultProps = {
  color: theme.palette.primary[500],
  height: "8px",
};
