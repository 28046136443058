import dayjs from "dayjs";

export const statuses = {
  processing: {
    title: "Processing...",
    bgcolor: "#777E90",
    key: "processing",
  },

  reprocessing: {
    title: "Reprocessing...",
    bgcolor: "#F58647",
    key: "reprocessing",
  },

  failed: {
    title: "Processing Failed",
    bgcolor: "#F95E5E",
    key: "failed",
  },
};

export const getStatus = (timestamp) => {
  if (!timestamp) {
    return null;
  }

  const timePassedInMinutes = dayjs().diff(timestamp, "minutes");
  let status = "";

  if (timePassedInMinutes < 20) {
    status = "processing";
  } else if (timePassedInMinutes > 20 && timePassedInMinutes < 60) {
    status = "reprocessing";
  } else {
    status = "failed";
  }

  return statuses[status];
};
