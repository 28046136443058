import uniqBy from "lodash/uniqBy";

export const presetContentMap = {
  podcast: {
    fileId: "file_id",
    showNotes: "show_notes",
    summary: "summary",
    quotes: "quotes",
    chapters: "chapters",
    transcript: "transcript",
    article: "article",
    tweetThread: "tweet_thread",
    captions: "captions",
    focusKeywords: "focus_keywords",
    titleSuggestions: "title_suggestions",
    audiograms: "audiograms",
    reels: "reels",
  },

  webinar: {
    fileId: "file_id",
    sessionRecap: "session_recap",
    quotes: "quotes",
    chapters: "chapters",
    article: "article",
    whitePaper: "white_paper",
    faqs: "faqs",
    newsletter: "newsletter",
    landingPage: "landing_page",
    captions: "captions",
    audiograms: "audiograms",
    reels: "reels",
  },

  meeting: {
    fileId: "file_id",
    meetingMinutes: "meeting_minutes",
    summary: "summary",
    chapters: "chapters",
    actionItems: "action_items",
    decisionLog: "decision_log",
    importantDates: "important_dates",
    qAndA: "q_and_a",
    nextAgenda: "next_agenda",
    followUpEmails: "follow_up_emails",
    audiograms: "audiograms",
    reels: "reels",
  },
};

export function getSnakeCaseVersion(content) {
  if (presetContentMap.podcast[content]) {
    return presetContentMap.podcast[content];
  }

  if (presetContentMap.meeting[content]) {
    return presetContentMap.meeting[content];
  }

  if (presetContentMap.webinar[content]) {
    return presetContentMap.webinar[content];
  }

  return null;
}

// A map of content types that can be created for each preset in the Create Content flow
export const createContentPresetMap = {
  podcast: [
    { label: "White Paper", value: "white_paper" },
    { label: "FAQs", value: "faqs" },
    { label: "Newsletter", value: "newsletter" },
    { label: "Landing Page", value: "landing_page" },
    { label: "Q&A", value: "q_and_a" },
  ],

  webinar: [
    { label: "Show Notes", value: "show_notes" },
    { label: "Summary", value: "summary" },
    { label: "Tweet Thread", value: "tweet_thread" },
    { label: "Focus Keywords", value: "focus_keywords" },
    { label: "Title Suggestions", value: "title_suggestions" },
    { label: "Action Items", value: "action_items" },
    { label: "Important Dates", value: "important_dates" },
    { label: "Q&A", value: "q_and_a" },
    { label: "Follow Up Emails", value: "follow_up_emails" },
  ],

  meeting: [
    { label: "Quotes", value: "quotes" },
    { label: "Session Recap", value: "session_recap" },
    { label: "FAQs", value: "faqs" },
    { label: "Newsletter", value: "newsletter" },
  ],
};

export const getContentTypeLabel = (contentType) => {
  if (!contentType) {
    return null;
  }

  return contentType.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
};

export const getWrittenContentTypesList = (presetsToExclude = []) => {
  const extraneousTypes = ["audiograms", "reels", "file_id", "transcript"];
  const presets = Object.keys(presetContentMap);
  let contentTypes = [];

  presets.forEach((preset) => {
    if (presetsToExclude.includes(preset)) {
      return;
    }

    contentTypes = contentTypes.concat(
      Object.values(presetContentMap[preset])
        .filter((t) => !extraneousTypes.includes(t))
        .map((t) => ({ label: getContentTypeLabel(t), value: t })),
    );
  });

  const sortedTypes = contentTypes.sort((a, b) => a.label.localeCompare(b.label));

  return uniqBy(sortedTypes, "value");
};

export const getCreateContentTypesListFor = (preset) => {
  const contentTypes = [...createContentPresetMap[preset]];

  return contentTypes.sort((a, b) => a.label.localeCompare(b.label));
};

export const mapContentTypeToSnakeCase = (contentType, preset) => {
  if (!contentType || !preset) {
    return null;
  }

  return presetContentMap[preset]?.[contentType] || null;
};
