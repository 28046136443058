import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { GlobalData } from "../../context";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} variant="outlined" {...props} />;
});

export default function TransitionsSnackbar() {
  const {
    alertState,
    setAlertState,
    message,
    severity,
    autoClose,
  } = React.useContext(GlobalData);
  const handleClose = () => {
    setAlertState({
      ...alertState,
      open: false,
    });
  };
  return (
    <div>
      <Snackbar
        autoHideDuration={autoClose ? 4000 : null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertState.open}
        TransitionComponent={alertState.Transition}
        key={alertState.Transition.name}
        onClose={handleClose}
        sx={{
          "&.MuiSnackbar-anchorOriginBottomRight": {
            right: "80px",
          },
        }}
      >
        <Alert
          onClose={handleClose}
          severity={severity || "error"}
          sx={{ width: "100%", borderRadius: "9px", bgcolor: "#fff" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
