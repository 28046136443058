import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { badges } from "DAI/Profile/data";
import theme from "theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CreditsProgressBar from "DAI/Upload/components/CreditsTracker/progressBar";
import { getUserPlan } from "DAI/Subscription/services/utils";
import { getSubscriptionPlan } from "store/user";
import infiniteIcon from "assets/icons/infinite.png";

export default function NavbarPlanUsage({ metric, used, total }) {
  const planCode = useSelector(getSubscriptionPlan);
  const userPlan = getUserPlan(planCode);
  const isUnlimited = (total === -1);
  const color = badges[userPlan?.name]?.color;
  let progress = 100;
  let usedUpText = "";

  if (!isUnlimited) {
    progress = ((total - used) / total) * 100;
    usedUpText = `${Math.floor(total - used)} / ${Math.floor(total)}`;
  } else {
    usedUpText = "Unlimited";
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "12px",
        marginBottom: "4px",
      }}
    >
      <Box sx={{ maxWidth: "184px", minWidth: "84px", position: "relative", width: "100%" }}>
        <CreditsProgressBar color={color} value={progress || 0.01} />

        {isUnlimited && (
          <img
            src={infiniteIcon}
            alt="unlimited uploads"
            aria-hidden="true"
            style={{ position: "absolute", right: -1, top: "50%", transform: "translateY(-50%)" }}
            width="26"
            height="13"
          />
        )}
      </Box>

      <Typography
        aria-label={`${Math.floor(total - used)} ${metric} remaining`}
        sx={{
          color: theme.palette.gray[500],
          fontFamily: "Satoshi",
          fontSize: {
            xs: "14px",
            md: "12px",
          },

          fontWeight: 500,
        }}
      >
        <span style={{ color: "#000", fontWeight: 700 }}>{usedUpText}</span>

        {isUnlimited ? "" : ` ${metric}`}
      </Typography>
    </div>
  );
}

NavbarPlanUsage.propTypes = {
  metric: PropTypes.string.isRequired,
  used: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};
