import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSavedSessions, saveSession as saveSessionAPI } from "DAI/Library/services/apis";
import { setToast } from "store/toastSlice";

export const Sort = {
  asc: "asc",
  desc: "desc",
};

export const Filter = {
  all: "all",
  library: "library",
  file: "file",
};

const askDeciphrSlice = createSlice({
  name: "askDeciphr",
  initialState: {
    hasOutput: false, // used to reset UI state to initial
    savedThreadIds: [],
    savedSessions: [],
    loadMore: true,
    sort: Sort.desc,
    filter: {
      library: false,
      files: false,
    },
    startIndex: 0,
    searchQuery: "",
    isFetching: false,
  },
  reducers: {
    setHasOutput: (state, action) => {
      state.hasOutput = action.payload;
    },
    saveThreadId(state, action) {
      state.savedThreadIds.push(action.payload);
    },
    unsaveThreadId(state, action) {
      state.savedThreadIds = state.savedThreadIds.filter((threadId) => (threadId !== action.payload));
    },
    setSavedSessions(state, action) {
      state.savedSessions = action.payload;
    },
    setLoadMore(state, action) {
      state.loadMore = action.payload;
    },
    toggleSort(state) {
      state.startIndex = 0;

      if (state.sort === Sort.desc) {
        state.sort = Sort.asc;
      } else {
        state.sort = Sort.desc;
      }
    },
    setFilter(state, action) {
      state.filter = action.payload;
      state.startIndex = 0;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
      state.startIndex = 0;
    },
    setStartIndex(state, action) {
      state.startIndex = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const {
  setHasOutput,
  saveThreadId,
  unsaveThreadId,
  setSavedSessions,
  setLoadMore,
  toggleSort,
  setFilter,
  setSearchQuery,
  setStartIndex,
  setIsFetching,
} = askDeciphrSlice.actions;

export const selectHasOutput = (state) => state.askDeciphr.hasOutput;
export const selectAskDeciphrSlice = (state) => state.askDeciphr;

export const saveSession = createAsyncThunk(
  "askDeciphr/saveSession",
  async (threadId, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    try {
      dispatch(saveThreadId(threadId));
      const res = await saveSessionAPI(threadId);
      dispatch(
        setToast({
          message: res.message,
          severity: "success",
        }),
      );

      return res;
    } catch (err) {
      dispatch(
        setToast({
          message: err?.response?.data?.error || err.message,
          severity: "error",
        }),
      );
      dispatch(unsaveThreadId(threadId));

      return rejectWithValue(err);
    }
  },
);

export const getSavedSessions = createAsyncThunk(
  "askDeciphr/getSavedSessions",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    try {
      const { askDeciphr: state } = getState();
      let appliedFilter = Filter.all;

      if (state.isFetching) {
        return null;
      }

      dispatch(setIsFetching(true));

      if (state.filter.library && state.filter.files) {
        appliedFilter = Filter.all;
      } else if (state.filter.library) {
        appliedFilter = Filter.library;
      } else if (state.filter.files) {
        appliedFilter = Filter.file;
      } else {
        appliedFilter = Filter.all;
      }

      const res = await fetchSavedSessions({
        sort: state.sort,
        filter: appliedFilter,
        query: state.searchQuery,
        startIndex: state.startIndex,
        limit: 20,
      });

      if (payload?.append) {
        dispatch(
          setSavedSessions([...state.savedSessions, ...res.sessions]),
        );
      } else {
        dispatch(setSavedSessions(res.sessions));
      }

      dispatch(setLoadMore(res.hasMore));
      dispatch(setIsFetching(false));

      return res;
    } catch (err) {
      dispatch(setToast({
        message: err?.response?.data?.error || err.message,
        severity: "error",
      }));
      dispatch(setIsFetching(false));

      return [];
    }
  },
);

export default askDeciphrSlice.reducer;
