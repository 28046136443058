import axios from "utils/axiosConfig";


export const getAllGeneratedContent = async (params) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/search/content",
      params
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const pendingFileStatus = async () => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `/search/content/status`,
      });
  
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  export const getFileStatus = async (content_id) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `/search/content/${content_id}/status`,
      });
  
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  export const createContent = async (payload) => {
    try {
      const { data } = await axios({
        method: "POST",
        url: "/search/content",
        data: payload,
      });
  
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  export const searchContent = async (params) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: "/search",
        params,
      });
  
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  export const getContent = async (content_id) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `/search/content/${content_id}`,
      });
  
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  export const updateTitle = async (content_id, payload) => {
    try {
      const { data } = await axios({
        method: "PUT",
        url: `/search/content/${content_id}/title`,
        data: payload,
      });
  
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  export const updateDescription = async (content_id, payload) => {
    try {
      const { data } = await axios({
        method: "PUT",
        url: `/search/content/${content_id}/content`,
        data: payload,
      });
  
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  export const downloadContent = async (content_id) => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `/search/content/${content_id}/download`,
      });
  
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };