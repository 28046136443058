export const styles = {
    root: {
      position: "fixed",
      top: "80px",
      zIndex: 200,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: " 56px",
      overflow: "hidden",
      backgroundColor: "#EF466F",
    },
    text: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "140%",
      color: "#ffffff",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      gap: 2,
      px: "15px",
    },
    highlight: {
      fontWeight: "700",
      marginRight:4
    },
    icon: {
      color: "#ffffff",
    },
  };
