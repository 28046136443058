import axios, { getReqUrl, mapKeysToCamelCase } from "utils/axiosConfig";

export const reprocessFile = async (fileId) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/pipeline/reprocess/${fileId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export async function askDeciphr(query, threadId = null) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const url = getReqUrl("/up/library/ask");

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query, thread_id: threadId }),
  });

  return res;
}

export const askDeciphrFolder = async (query, threadId, folderId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const url = getReqUrl(`/up/folder/ask/${folderId}`);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query, thread_id: threadId || null }),
  });

  return response;
};

/**
 * Get the reference details
 *
 * @param {string} refIds Ids of the references
 */
export async function getReferences(refIds) {
  const { data } = await axios.post("/up/library/references", {
    ref_ids: refIds,
  });

  return data?.references || [];
}

/**
 * @param {Object} params
 * @param {number} [params.startIndex=0] Index of the item to start listing at
 * @param {number} [params.limit=10] Number of results per page
 * @param {number} [params.startDate=0] Start date in unix ms
 * @param {number} [params.endDate] End date in unix ms. Default is current timestamp
 * @param {"asc" | "desc"} [params.sort] Sorting order. Defaults to "all"
 * @param {"all" | "file" | "library"} [params.filter] Results filter. Defaults to "all"
 * @param {query} [params.query] Search query
 */
export async function fetchSavedSessions(params = {}) {
  const { data } = await axios.get("/up/session", {
    params: {
      start: params.startIndex,
      limit: params.limit,
      start_date: params.startDate,
      end_date: params.endDate,
      sort: params.sort,
      filter: params.filter,
      query: params.query,
    },
  });

  data.sessions = data.sessions.map(mapKeysToCamelCase);

  return mapKeysToCamelCase(data);
}

export async function fetchSavedSession(threadId) {
  const { data } = await axios.get(`/up/session/${threadId}`);
  data.session = mapKeysToCamelCase(data.session);

  return data;
}

export async function saveSession(threadId) {
  const { data } = await axios.post("/up/session/save", {
    thread_id: threadId,
  });

  return data;
}

export async function deleteSession(threadId) {
  const { data } = await axios.delete(`/up/session/${threadId}`);

  return data;
}
