import PropTypes from "prop-types";
import theme from "theme";
import Typography from "@mui/material/Typography";

export default function PageTitle({ title }) {
  return (
    <Typography
      component="h1"
      sx={{
        color: theme.palette.gray[700],
        display: {
          xs: "none",
          sm: "block",
        },

        fontFamily: "Satoshi",
        fontSize: {
          xs: "20px",
          xmd: "28px",
        },
        fontWeight: 900,
        lineHeight: "48px",
        px: {
          xs: "24px",
          xmd: "48px",
        },
      }}
    >
      {title}
    </Typography>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
};

PageTitle.defaultProps = {
  title: "",
};
