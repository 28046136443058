import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { getStatus } from "DAI/Library/components/LibraryFileCard/statuses";
import { reprocessFile } from "DAI/Library/services/apis";
import { setToast } from "store/toastSlice";
import { setAttributes } from "store/transcriptSlice";
import {
  DelInsights,
  getFilesList,
  getUnprocessedFileIds,
} from "utils/apis";
import { removeFileFromSearch } from "./librarySearchSlice";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    files: [],
    filter: "all",
    sort: "date_desc",
    limit: 20,
    searchResults: [],
    isLoading: false,
    areAllFilesFetched: false,
    isInitialMount: false,
  },

  reducers: {
    updateFilesList: (state, action) => {
      const { files, opType } = action.payload;
      const operation = opType || "append"; // default

      if (files) {
        if (operation === "append") {
          state.files = state.files.concat(files);
        } else {
          // overwrite
          state.files = files;
        }
      }
    },

    updateSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },

    removeFileFromList: (state, action) => {
      state.files = state.files.filter((file) => file.id !== action.payload);
    },

    clearFilesList: (state) => {
      state.files = [];
    },

    updateFileStatusToPartiallyProcessed: (state, action) => {
      const index = state.files.findIndex((file) => file.id === action.payload);

      if (index > -1) {
        state.files[index].viewable = true;
      }
    },

    updateFileStatusToProcessed: (state, action) => {
      const index = state.files.findIndex((file) => file.id === action.payload);

      if (index > -1) {
        state.files[index].processed = "Y";
      }
    },

    updateFilter: (state, action) => {
      if (state.filter !== action.payload) {
        state.filter = action.payload;
        state.files = [];
        state.areAllFilesFetched = false;
      }
    },

    updateSort: (state, action) => {
      if (state.sort !== action.payload) {
        state.sort = action.payload;
        state.files = [];
        state.areAllFilesFetched = false;
      }
    },

    updateHeadline: (state, action) => {
      state.files = state.files.map((file) => {
        if (file.id === action.payload.id) {
          file.headline = action.payload.headline;
        }

        return file;
      });
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setAllFilesFetched: (state, action) => {
      state.areAllFilesFetched = action.payload;
    },

    setInitialMount: (state, action) => {
      state.isInitialMount = action.payload;
    },

    updateReprocessedTime: (state, action) => {
      const file = state.files.find((f) => f.id === action.payload.id);

      if (file) {
        state.files = state.files.map((f) => {
          if (f.id === file.id) {
            file.reprocessed_at = dayjs().subtract("30", "minutes").toDate().getTime();

            return file;
          }

          return f;
        });
      }
    },
  },
});

export const {
  updateFilesList,
  updateSearchResults,
  removeFileFromList,
  clearFilesList,
  updateFileStatusToPartiallyProcessed,
  updateFileStatusToProcessed,
  updateFilter,
  updateSort,
  updateHeadline,
  setIsLoading,
  setAllFilesFetched,
  setInitialMount,
  updateReprocessedTime,
} = dashboardSlice.actions;

/** ************
 * Selectors
 ************* */
export const selectFiles = (state) => state?.dashboard?.files || [];
export const selectFilters = (state) => ({
  filter: state.dashboard.filter,
  sort: state.dashboard.sort,
});
export const selectParams = (state) => new URLSearchParams({
  sort: state.dashboard.sort,
  filter: state.dashboard.filter,
  start: state.dashboard.files.length,
  limit: state.dashboard.limit,
});

export const selectSearchResults = (state) => state.dashboard.searchResults;
export const isLoading = (state) => state.dashboard.isLoading;
export const areAllFilesFetched = (state) => state.dashboard.areAllFilesFetched;
export const isInitialMountSelector = (state) => state.dashboard.isInitialMount;
export const sortSelector = (state) => state.dashboard.sort;
export const filterSelector = (state) => state.dashboard.filter;

/** **************
 * Async Thunks
 *************** */
export const getFiles = createAsyncThunk(
  "dashboard/getFiles",
  async (payload, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const { dashboard } = getState();
    // eslint-disable-next-line no-shadow
    const { files, limit, areAllFilesFetched, isLoading } = dashboard;
    const params = selectParams(getState());

    if (payload?.fromStart) {
      // Fetch from the beginning, but update the limit to retain existing files
      params.set("start", 0);
      params.set("limit", files.length || limit);
    }

    if (areAllFilesFetched && !payload?.fromStart) {
      return;
    }

    if (isLoading) {
      return;
    }

    dispatch(setIsLoading(true));

    try {
      const data = await getFilesList(params);
      const opType = payload?.fromStart ? "overwrite" : "append";
      const isLibrary = window.location.pathname === "/library";

      dispatch(updateFilesList({ files: data, opType }));

      if (data.length < limit) {
        // No more files to fetch
        dispatch(setAllFilesFetched(true));
      }

      if (data.length && isLibrary) {
        const failedFiles = data.filter((f) => {
          const status = getStatus(f.reprocessed_at);
          const daysPassed = dayjs().diff(f.reprocessed_at, "days");

          return f.processed === "N" && status?.key === "failed" && daysPassed <= 3;
        });

        if (failedFiles.length) {
          dispatch(
            setToast({
              message:
                "One or more of your files failed to process. Don't "
                + "worry, your upload credits weren't used.",
              severity: "error",
            }),
          );
        }
      }
    } catch (e) {
      dispatch(
        setToast({
          message: "Files could not be retrieved. Please try again.",
          severity: "error",
        }),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  },
);

export const setFileAttributes = createAsyncThunk(
  "dashboard/setFile",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(
      setAttributes({
        name: "",
        date: payload.display_datetime,
        title: payload.headline,
        headline: payload.headline,
        fileName: payload.title,
        fileType: payload.file_type,
      }),
    );
  },
);

export const deleteFile = createAsyncThunk(
  "dashboard/deleteFile",
  async (id, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(removeFileFromSearch(id));
    dispatch(removeFileFromList(id));

    try {
      await DelInsights(id);

      dispatch(
        setToast({
          message: "File was deleted successfully.",
          severity: "success",
          autoClose: true,
        }),
      );
    } catch (e) {
      //
    }
  },
);

export const statusCheck = createAsyncThunk(
  "dashboard/status",
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { dashboard } = getState();
    const { files } = dashboard;
    const unprocessedFiles = files.filter((file) => file.processed === "N");
    const semiProcessed = files.filter(
      (f) => f.processed === "Y" && f.viewable,
    );

    try {
      const fileIds = await getUnprocessedFileIds();

      unprocessedFiles.forEach((file) => {
        // Transition any files with processed = "N" to processed = "Y"
        if (fileIds.unprocessed.indexOf(file.id) === -1) {
          // File ID is not in unprocessed file IDs list, update it to processed
          dispatch(updateFileStatusToProcessed(file.id));
        }

        // Transition unprocessed files to partially processed
        if (fileIds.partially_processed.indexOf(file.id) > -1) {
          dispatch(updateFileStatusToPartiallyProcessed(file.id));
        }
      });

      semiProcessed.forEach((file) => {
        // Transition any files with processed = "N" && viewable = true to processed = "Y"
        if (fileIds.partially_processed.indexOf(file.id)) {
          // File ID is not in partially processed file IDs list, update it to processed
          dispatch(updateFileStatusToProcessed(file.id));
        }
      });
    } catch (e) {
      //
    }
  },
);

export const reprocess = createAsyncThunk(
  "dashboard/reprocess",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    // const { dashboard } = getState();
    // const { files } = dashboard;
    // const updatedFiles = files.map((file) => {
    //   if (file.id === payload.id) {
    //     file.reprocessed_at = dayjs().subtract("30", "minutes").toDate().getTime();
    //   }

    //   return file;
    // });

    // // set file status to "reprocessing"
    // dispatch(updateFilesList({ files: updatedFiles, opType: "overwrite" }));

    try {
      await reprocessFile(payload.id);

      dispatch(updateReprocessedTime({ id: payload.id }));
    } catch (e) {
      //
    }
  },
);

export default dashboardSlice.reducer;
