import purpleShadesBg from "assets/images/purple-shades.webp";

const styles = {
  root: {
    minHeight: "100vh",
    backgroundImage: `url(${purpleShadesBg})`,
    bgcolor: "#fff",
    backgroundSize: "fit",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
  },
  logoLink: {
    display: "flex",
    gap: "2px",
    alignItems: "center",
    width: "max-content",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    pt: "32px",
    pb: "80px",
    gap: "56px",
  },
  flexColumn: {
    flex: 1,
    width: "100%",
    maxWidth: "512px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "31px",

    "@media screen and (max-width: 800px)": {
      minWidth: "100%",
    },
  },
  actionBox: {
    padding: { xs: "30px", lg: "56px" },
    border: "1px solid #F2F4F7",
    borderRadius: "20px",
    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.06)",
    width: "100%",
    bgcolor: "#fff",
  },
  termsAndPolicy: {
    fontFamily: "Satoshi",
    fontSize: { xs: "12px", sm: "14px" },
    fontWeight: 500,
    lineHeight: "145%",
    color: "#98A2B3",
    maxWidth: "350px",
    textAlign: "center",
  },
  sideBox: {
    position: "relative",
    padding: "32px",
    width: "100%",
    maxWidth: "450px",
    border: "1px solid #DDD9FF",
    borderRadius: "16px",
    bgcolor: "#fff",
  },
  logoContainer: {
    justifyContent: "center",
    maxWidth: "420px",
    mt: "16px",
    textAlign: "center",
  },
};

export default styles;
