import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import securityIcon from "assets/security.png";

export default function SecurityBadge() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "12px",
        gap: "12px",
        borderRadius: "24px",
        bgcolor: "#604DEB",
        position: "absolute",
        top: { xs: "100%", md: 120 },
        right: { xs: "unset", md: 0 },
        left: { xs: 24, md: "unset" },
        transform: { xs: "translateY(25%)" },
        zIndex: 4,
        boxShadow: "-16px 16px 100px -20px rgba(19, 5, 118, 0.8)",

        "&::after": {
          content: "''",
          position: "absolute",
          height: 32,
          width: 32,
          bgcolor: "#6552F3",
          left: { xs: "50%", md: "unset" },
          top: { xs: "unset", md: "50%" },
          bottom: { xs: 0, md: "unset" },
          right: { xs: "unset", md: 0 },
          clipPath: "polygon(50% 0, 50% 100%, 100% 50%)",
          transform: {
            xs: "translate(-50%, 50%) rotate(90deg)",
            md: "translate(50%, -50%)",
          },
        },
      }}
    >
      <img src={securityIcon} alt="" loading="lazy" width="56" height="56" />

      <Typography
        component="div"
        sx={{
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: 600,
          color: "#FFF",
          textTransform: "uppercase",
          lineHeight: "140%",
        }}
      >
        Data <br />
        Protection <br />
        Security
      </Typography>
    </Box>
  );
}
