import axiosClient from "utils/axiosConfig";

export const getBackgrounds = async () => {
  try {
    const { data } = await axiosClient({
      method: "GET",
      url: "/audiogram/backgrounds",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const a = "";
