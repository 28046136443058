import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import camelCase from "lodash/camelCase";
import mapKeys from "lodash/mapKeys";
import {
  addCollabSeat as addCollabSeatAPI,
  fetchCollabSeats,
  removeCollabSeat as removeCollabSeatAPI,
  signupCollabUser,
} from "DAI/Profile/services/apis";
import { setCards } from "DAI/PaymentCards/store";
import { initializeSubscriptionData, setCredits } from "DAI/Subscription/store/subscriptionSlice";
import { getSeatProfile } from "store/seatUser";
import { getPromoCodes, updateUserInfo, userInfo } from "utils/apis";
import { updateIntercomUserData } from "utils/services";
import { setToast } from "./toastSlice";

dayjs.extend(customParseFormat);

let posthog = {};

import("utils/GTM")
  .then(({ analytics }) => {
    posthog = analytics;
  })
  .catch(() => { });

function loadLoginInfoFromLocalStorage() {
  try {
    const loginInfo = mapKeys(
      JSON.parse(localStorage.getItem("userInfo")),
      (_, key) => (camelCase(key)),
    );

    delete loginInfo.token;

    return loginInfo;
  } catch (err) {
    return {};
  }
}

const initialState = {
  showUnverifiedAlert: false,
  isVerifyModalOpen: false,
  isFetchingUserInfo: false,
  firstName: "",
  lastName: "",
  email: "",
  podcastName: "",
  companyName: "",
  bio: "",
  userType: "",
  industry: "",
  contentTypes: [],
  website: null,
  plan: "",
  planType: "",
  createdAt: null,
  transcriptUsed: 0,
  transcriptTotal: 0,
  audioUsed: 0,
  audioTotal: 0,
  regenerateUsed: 0,
  regenerateTotal: 0,
  mediaContent: false,
  planStartDate: "",
  nextBillingDate: "",
  cancelAt: null,
  amount: 0,
  invoices: [],
  isYearly: false,
  onboardingCoupon: null,
  isOnboardingCouponVisible: false,
  completedOnboarding: false,
  finishedOnboardingSteps: false,
  usedCancellationCoupon: false,
  planPaused: false,
  pausedPlan: "",
  seats: [],
  loginInfo: loadLoginInfoFromLocalStorage(),
  maxSeats: 0,
  isSeatAccount: false,
  wasSubscriber: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: () => initialState,

  reducers: {
    loadLoginInfo(state) {
      state.loginInfo = loadLoginInfoFromLocalStorage();
      state.isSeatAccount = state.loginInfo.isSeatAccount;
    },

    toggleIsFetchingUserInfo: (state) => {
      state.isFetchingUserInfo = !state.isFetchingUserInfo;
    },

    setUserInfo: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.podcastName = action.payload.podcastName;
      state.companyName = action.payload.companyName;
      state.bio = action.payload.bio;
      state.userType = action.payload.userType;
      state.industry = action.payload.industry;
      state.contentTypes = action.payload.contentTypes;
      state.website = action.payload.website;
      state.plan = action.payload.plan;
      state.createdAt = action.payload.createdAt;
      state.transcriptUsed = action.payload.transcriptUsed;
      state.transcriptTotal = action.payload.transcriptTotal;
      state.audioUsed = action.payload.audioUsed;
      state.audioTotal = action.payload.audioTotal;
      state.regenerateUsed = action.payload.regenerateUsed;
      state.regenerateTotal = action.payload.regenerateTotal;
      state.mediaContent = action.payload.mediaContent;
      state.planStartDate = action.payload.planStartDate;
      state.nextBillingDate = action.payload.nextBillingDate;
      state.cancelAt = action.payload.cancelAt;
      state.amount = action.payload.amount;
      state.invoices = action.payload.invoices;
      state.isYearly = action.payload.plan.includes("yearly");
      state.planType = action.payload.plan;
      state.completedOnboarding = action.payload.completedOnboarding;
      state.usedCancellationCoupon = action.payload.usedCancellationCoupon;
      state.planPaused = action.payload.planPaused;
      state.pausedPlan = action.payload.pausedPlan;
      state.maxSeats = action.payload.maxSeats;
      state.wasSubscriber = action.payload.wasSubscriber;
    },

    incrementRegeneratesUsed: (state) => {
      if (state.regenerateTotal > state.regenerateUsed) {
        state.regenerateUsed += 1;
      }
    },

    setIsSeatAccount: (state, action) => {
      state.isSeatAccount = action.payload;
    },

    setShowAlert: (state, action) => {
      state.showUnverifiedAlert = action.payload;
    },

    setIsVerifyModalOpen: (state, action) => {
      state.isVerifyModalOpen = action.payload;
    },

    // FIXME: WTF is this shit?
    setUserInfo2: (state, action) => {
      state.userInfo = action.payload;
    },

    setOnboardingCoupon: (state, action) => {
      state.onboardingCoupon = action.payload;
    },

    setIsOnboardingCouponVisible: (state, action) => {
      state.isOnboardingCouponVisible = action.payload;
    },

    setIsOnboardingComplete: (state, action) => {
      state.completedOnboarding = action.payload;
    },

    setFinishedOnboardingSteps: (state, action) => {
      state.finishedOnboardingSteps = action.payload;
    },

    reset: () => {
      // This resets the redux store when a user signs out. This is handled
      // in store/index.js as a catch-all reset mechanism.
    },

    setSeats: (state, action) => {
      state.seats = action.payload.map((seat) => {
        let status = "";

        if (seat.is_seat_accepted) {
          status = "Active";
        } else {
          status = "Pending";
        }

        return {
          email: seat.email,
          firstName: seat.first_name,
          lastName: seat.last_name,
          createdAt: seat.created_at,
          isBlocked: seat.is_blocked,
          isSeatAccepted: seat.is_seat_accepted,
          isSeatAccount: seat.is_seat_account,
          status,
        };
      });
    },
  },
});

export const {
  loadLoginInfo,
  setUserInfo,
  incrementRegeneratesUsed,
  setIsSeatAccount,
  setShowAlert,
  setIsVerifyModalOpen,
  toggleIsFetchingUserInfo,
  setUserInfo2,
  setOnboardingCoupon,
  setIsOnboardingCouponVisible,
  setIsOnboardingComplete,
  setFinishedOnboardingSteps,
  reset,
  setSeats,
} = userSlice.actions;

export const isAlertOpen = (state) => state.user.showUnverifiedAlert;
export const isVerifyOpen = (state) => state.user.isVerifyModalOpen;
export const userInfoSelector = (state) => state?.user;
export const selectUserType = (state) => state.user.userType;
export const selectContentTypes = (state) => state.user.contentTypes;
export const isUserLoadingSelector = (state) => state.user.isFetchingUserInfo;
export const getSubscriptionPlan = (state) => state?.user?.plan;
export const getCreditsRemaining = (state) => ({
  media: state.user.audioTotal - state.user.audioUsed,
  transcript: state.user.transcriptTotal - state.user.transcriptUsed,
});
export const getAIAssistantCreditsRemaining = (state) => {
  if (state.user.regenerateTotal === -1) {
    return state.user.regenerateTotal;
  }

  return state.user.regenerateTotal - state.user.regenerateUsed;
};
export const isOnboardingComplete = (state) => state.user.completedOnboarding;
export const hasFinishedOnboarding = (state) => state.user.finishedOnboardingSteps;
export const selectInvoices = (state) => state.user.invoices;
export const pausedPlanSelector = (state) => state.user.pausedPlan;
export const planPausedSelector = (state) => state.user.planPaused;
export const selectIsSeatAccount = (state) => state.user.isSeatAccount;

export const getUserInfo = createAsyncThunk(
  "user/fetchUserInfo",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { user } = getState();

    dispatch(toggleIsFetchingUserInfo());

    try {
      const response = await userInfo();
      const {
        user: {
          first_name: firstName,
          email,
          last_name: lastName,
          podcast_name: podcastName,
          company_name: companyName,
          user_type: userType,
          industry,
          content_types: contentTypes,
          website,
          bio,
          plan,
          created_at: createdAt,
          completed_onboarding_quiz: completedOnboarding,
          transcript_used: transcriptUsed,
          transcript_total: transcriptTotal,
          audio_used: audioUsed,
          audio_total: audioTotal,
          media_content: mediaContent,
          regenerate_used: regenerateUsed,
          regenerate_total: regenerateTotal,
          used_cancellation_coupon: usedCancellationCoupon,
          plan_paused: planPaused,
          paused_plan: pausedPlan,
          was_subscriber: wasSubscriber,
        },
        plan: {
          start_date: planStartDate,
          next_billing_date: nextBillingDate,
          cancel_at: cancelAt,
          amount,
        },
        invoices,
        cards,
        max_seats: maxSeats,
      } = response;
      dispatch(
        setUserInfo({
          firstName,
          lastName,
          email,
          podcastName,
          companyName,
          bio,
          userType,
          industry,
          contentTypes,
          website,
          completedOnboarding,
          transcriptTotal,
          transcriptUsed,
          audioUsed,
          audioTotal,
          plan,
          createdAt,
          mediaContent,
          planStartDate,
          nextBillingDate,
          cancelAt,
          amount,
          invoices,
          regenerateUsed,
          regenerateTotal,
          usedCancellationCoupon,
          planPaused,
          pausedPlan,
          maxSeats,
          wasSubscriber,
        }),
      );

      dispatch(loadLoginInfo());

      dispatch(
        initializeSubscriptionData({
          invoices,
          paused_plan: pausedPlan,
          plan: response.plan,
          plan_paused: planPaused,
          used_cancellation_coupon: usedCancellationCoupon,
        }),
      );
      dispatch(
        setCredits({
          audio_used: audioUsed,
          audio_total: audioTotal,
          transcript_used: transcriptUsed,
          transcript_total: transcriptTotal,
          regenerate_used: regenerateUsed,
          regenerate_total: regenerateTotal,
        }),
      );
      dispatch(setCards(cards));

      if (user.loginInfo.isSeatAccount) {
        // If this is a collaborator account, get those details too
        dispatch(getSeatProfile());
      }

      if (response.error) {
        dispatch(setToast({ message: response.error, severity: "error" }));
      } else {
        // Update Intercom user data  with the latest user info
        updateIntercomUserData({
          email,
          firstName,
          plan: response.plan.status === "active" ? response.plan.plan : "FREE",
          audioUsed,
          audioTotal,
          companyName,
          lastName,
          transcriptUsed,
          transcriptTotal,
          regenRemaining: regenerateTotal - regenerateUsed,
        });

        if (payload && payload.identify) {
          const identity = JSON.parse(localStorage.getItem("userInfo"));

          if (identity && identity.user) {
            posthog.identify(identity.user.localId, {
              email,
              name: `${firstName} ${lastName}`,
            });
          }
        }

        posthog.track("$set", {
          $set: {
            upload_minutes_total: audioTotal,
            upload_minutes_used: audioUsed,
            docs_uploaded: transcriptUsed,
            plan: response.plan.status === "active" ? response.plan.plan : "FREE",
          },
        });
      }
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }

    dispatch(toggleIsFetchingUserInfo(false));
  },
);

export const updateUserDetails = createAsyncThunk(
  "user/updateUserInfo",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    dispatch(toggleIsFetchingUserInfo());

    try {
      const {
        firstName: first_name,
        lastName: last_name,
        podcastName: podcast_name,
        companyName: company_name,
        bio,
        userType: user_type,
        industry,
        contentTypes: content_types,
        website,
        hideNotification,
      } = payload;

      const response = await updateUserInfo({
        first_name,
        last_name,
        podcast_name,
        company_name,
        bio,
        user_type: user_type || null,
        industry: industry || null,
        content_types,
        website: website || null,
      });

      if (response.success) {
        dispatch(
          setUserInfo({
            ...getState().user,
            ...payload,
          }),
        );

        if (user_type && content_types) {
          dispatch(setFinishedOnboardingSteps(true));
          dispatch(setIsOnboardingComplete(true));
        } else {
          dispatch(setFinishedOnboardingSteps(false));
        }

        if (!hideNotification) {
          dispatch(
            setToast({
              message: response.success,
              severity: "success",
              autoClose: true,
            }),
          );
        }
      } else {
        dispatch(setToast({ message: response.error, severity: "error" }));
      }
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    } finally {
      dispatch(toggleIsFetchingUserInfo(false));
    }
  },
);

export const getPromoCode = createAsyncThunk("user/promoCode", async (_, thunkAPI) => {
  const { dispatch } = thunkAPI;

  dispatch(toggleIsFetchingUserInfo(true));
  dispatch(setOnboardingCoupon(null));

  try {
    const data = await getPromoCodes();

    if (data.coupon) {
      dispatch(setOnboardingCoupon(data.coupon));
      dispatch(setIsOnboardingCouponVisible(true));
      dispatch(setIsOnboardingComplete(true));
      dispatch(setFinishedOnboardingSteps(true));
    } else {
      dispatch(setOnboardingCoupon(""));
    }
  } catch (e) {
    dispatch(setOnboardingCoupon(""));
  } finally {
    dispatch(toggleIsFetchingUserInfo(false));
  }
});

export const getCollabSeats = createAsyncThunk(
  "user/getCollabSeats",
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const seats = await fetchCollabSeats();
      dispatch(setSeats(seats));
    } catch (err) {
      dispatch(setToast({
        message: "Something went wrong",
        severity: "error",
      }));
    }
  },
);

export const addCollabSeat = createAsyncThunk(
  "user/addCollabSeat",
  async (email, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    try {
      const res = await addCollabSeatAPI(email);

      dispatch(setToast({
        message: res.message,
        severity: "success",
        autoClose: true,
      }));

      await dispatch(getCollabSeats()).unwrap();

      return res.message;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const removeCollabSeat = createAsyncThunk(
  "user/removeCollabSeat",
  async (email, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    try {
      const res = await removeCollabSeatAPI(email);

      dispatch(setToast({
        message: res.message,
        severity: "success",
      }));

      await dispatch(getCollabSeats()).unwrap();

      return res.message;
    } catch (err) {
      dispatch(setToast({
        message: err?.response?.data?.error || err.message,
        severity: "error",
      }));

      return rejectWithValue(err);
    }
  },
);

export const collabSignup = createAsyncThunk(
  "user/collabSignup",
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    try {
      const {
        email,
        firstName,
        lastName,
        password,
      } = payload;

      const res = await signupCollabUser({
        email,
        firstName,
        lastName,
        password,
      });

      const loginInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

      localStorage.setItem("userInfo", JSON.stringify({
        ...loginInfo,
        token: res.token,
        user: res.user,
        isSeatAccount: res.is_seat_account || true,
      }));

      dispatch(setIsSeatAccount(res.is_seat_account || true));

      dispatch(
        setToast({
          message: "Sign up successful",
          severity: "success",
        }),
      );

      return res;
    } catch (error) {
      dispatch(
        setToast({
          message: error?.response?.data?.error || error?.message,
          severity: "error",
        }),
      );

      return rejectWithValue(error);
    }
  },
);

export default userSlice.reducer;
