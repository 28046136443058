import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import throttle from "lodash/throttle";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import UploadCredits from "DAI/Navigation/containers/UploadCredits";
import LibrarySearchBar from "DAI/Library/components/LibrarySearchBar";
import HeaderLogo from "DAI/Navigation/components/HeaderLogo";
import PageTitle from "DAI/Navigation/components/HeaderNav/pageTitle";
import MobileUserMenu from "DAI/Navigation/components/MobileUserMenu";
import ProfileMenu from "DAI/Profile/components/ProfileMenu";
import { reset, selectPageTitle } from "DAI/Navigation/store/headerNavSlice";

export default function HeaderNav({ showLogo, drawerWidth }) {
  const pageTitle = useSelector(selectPageTitle);
  // const isBackButtonVisible = useSelector(selectShowBackButton);
  const [hasBorder, setHasBorder] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const isHidden = useMediaQuery("(max-width: 899px)");

  // Reset page titles on unmount
  useEffect(() => () => {
    dispatch(reset());
  }, [location.pathname, dispatch]);

  // Add a bottom border to the navbar when scrolling
  useEffect(() => {
    const onScroll = throttle(() => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        setHasBorder(true);
      } else {
        setHasBorder(false);
      }
    }, 1484);

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <Box
      component="header"
      sx={{
        borderBottom: hasBorder ? "thin solid #E3E6EA" : "none",
        height: "90px",
        position: "fixed",
        left: {
          xs: 0,
          md: showLogo ? 0 : `${drawerWidth}px`,
        },
        right: 0,
        top: 0,
        zIndex: 184,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          alignItems: "center",
          bgcolor: "#FFF",
          display: "flex",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <HeaderLogo show={showLogo} />

          <PageTitle title={pageTitle} />
        </Box>

        <Box
          sx={{
            display: isHidden ? "none" : "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <UploadCredits />

          <LibrarySearchBar />

          <ProfileMenu show />
        </Box>

        <MobileUserMenu />
      </Container>
    </Box>
  );
}

HeaderNav.propTypes = {
  showLogo: PropTypes.bool.isRequired,
  drawerWidth: PropTypes.number.isRequired,
};
