import { useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";
import DAIButton from "Core/components/DAIButton";
import FormInput from "DAI/Auth/components/FormInput";
import { GlobalData } from "context";
import { init, schema, ForgotFieldMap } from "utils/constants";
import { forgotInitApi } from "utils/authSeq";
import EmailIcon from "assets/email.svg";

const GTM = {};

import("utils/GTM")
  .then((module) => {
    GTM.analytics = module.analytics;
  })
  .catch(() => { });

const IconMap = {
  email: EmailIcon,
};

const ForgotPassword = () => {
  const [loader, setLoader] = useState(false);
  const { openAlert, setMessage, setSeverity } = useContext(GlobalData);
  const formik = useFormik({
    initialValues: init("forgotInit"),
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema("forgotInit")),
    onSubmit: (value) => SubmitHandler(value),
  });


  const SubmitHandler = async (value) => {
    setLoader(true);
    await forgotInitApi(
      value,
      () => {
        GTM.analytics.track("User initiated forgot password");
        sucessHandler("Password reset request sent. Please check your e-mail!");
      },
      errorHandler
    );
    setLoader(false);
  };

  const sucessHandler = (res) => {
    setSeverity("success");
    openAlert();
    setMessage(res);
  };

  const errorHandler = (err) => {
    setSeverity("error");
    openAlert();
    setMessage(err);
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", lg: "405px", xl: "100%" },
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: "Satoshi",
            fontSize: { xs: "28px", lg: "36px" },
            fontWeight: 900,
            color: "#000",
            marginBottom: "12px",
          }}
        >
          Forgot password?
        </Typography>

        <Typography
          sx={{
            color: "#475467",
            fontFamily: "Satoshi",
            fontSize: { xs: "15px", lg: "18px" },
            fontWeight: 500,
            lineHeight: "24px",
            margin: "24px 0",
          }}
        >
          Please enter your email address to reset your password.
        </Typography>
      </Box>

      <form name="forgot-password" onSubmit={formik.handleSubmit}>
        <Box sx={{ my: 3 }}>
          {Object.keys(formik.initialValues).map((v) => (
            <FormInput
              placeholder={ForgotFieldMap.forgotInit[v]}
              formik={formik}
              label={v}
              name={v}
              key={v}
              id={v}
              icon={IconMap[v]}
            />
          ))}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <DAIButton
            type="submit"
            sx={{
              borderRadius: "16px",
              color: "#fff",
              fontFamily: "Satoshi",
              fontSize: "18px",
              fontWeight: 700,
              width: "100%",
            }}
            loading={loader}
            loadingPosition="start"
          >
            Submit
          </DAIButton>
        </Box>
      </form>
    </Box>
  );
};

export default ForgotPassword;
