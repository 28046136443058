import * as Yup from "yup";

export const init = (field, email) => {
  if (field === "login") {
    return {
      email: "",
      password: "",
    };
  } else if (field === "earlyAccess") {
    return {
      name: "",
      email: "",
      upload_file: "",
    };
  } else if (field === "media") {
    return {
      email: "",
    };
  } else if (field === "signup") {
    return {
      name: "",
      podcastName: "",
      email: "",
      password: "",
    };
  } else if (field === "newSignup") {
    return {
      email: "",
      firstName: "",
      lastName: "",
      podcastName: "",
      password: "",
      isAgree: false,
    };
  } else if (field === "oldsignup") {
    return {
      email: email || "",
      password: "",
      confirmPassword: "",
    };
  } else if (field === "forgotInit") {
    return {
      email: "",
    };
  } else if (field === "forgot") {
    return {
      password: "",
      confirmPassword: "",
    };
  }
};

export const schema = (field) => {
  if (field === "login") {
    return {
      email: Yup.string()
        .email("Enter a valid email address")
        .required("This field is required"),
      password: Yup.string().required("This field is required"),
    };
  } else if (field === "media") {
    return {
      email: Yup.string().email("Invalid").required("This field is required"),
    };
  } else if (field === "forgotInit") {
    return {
      email: Yup.string().email("Invalid").required("This field is required"),
    };
  } else if (field === "forgot") {
    return {
      password: Yup.string().required("This field is required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    };
  } else if (field === "earlyAccess") {
    return {
      email: Yup.string().email("Invalid").required("This field is required"),
      name: Yup.string().required("This field is required"),
      upload_file: Yup.mixed().required("File is required"),
    };
  } else if (field === "signup") {
    return {
      name: Yup.string().required("This field is required"),
      email: Yup.string()
        .email("Enter a valid email address")
        .required("This field is required"),
      password: Yup.string().required("This field is required"),
    };
  } else if (field === "newSignup") {
    return {
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().required("This field is required"),
      email: Yup.string()
        .email("Enter a valid email address")
        .required("This field is required"),
      password: Yup.string().required("This field is required"),
    };
  } else if (field === "oldsignup") {
    return {
      email: Yup.string().email("Invalid").required("This field is required"),
      password: Yup.string().required("This field is required"),
      confirmPassword: Yup.string().required("This field is required"),
    };
  }
};

export const ForgotFieldMap = {
  forgotInit: {
    email: "Enter your email",
  },
  forgot: {
    password: "New password",
    confirmPassword: "Retype new password",
  },
};

export const loginFieldMap = {
  label: {
    email: "Email",
    password: "Password",
  },
  placeholder: {
    email: "Email address",
    password: "Enter your Password",
  },
  type: {
    email: "text",
    password: "password",
  },
};

export const newSignupFields = {
  label: {
    firstName: "First Name",
    lastName: "Last Name ",
    podcastName: "Company/Podcast Name",
    email: "Email",
    password: "Password",
  },
  placeholder: {
    firstName: "Your first name",
    lastName: "Your last name",
    podcastName: "Your company/podcast name",
    email: "Email address",
    password: "Enter your password",
  },
  type: {
    firstName: "text",
    lastName: "text",
    podcastName: "text",
    email: "text",
    password: "password",
  },
};

// ========================= Web Version 3.0 ========================= //
export const form = {
  login: {
    fields: [
      {
        id: "email",
        size: 1,
        label: "Email",
        type: "text",
        placeholder: "Email address",
        required: true,
        disabled: false,
      },
      {
        id: "password",
        size: 1,
        label: "Password",
        type: "password",
        placeholder: "Enter your Password",
        required: true,
        disabled: false,
      },
    ],

    init: () => ({
      email: "",
      password: "",
    }),

    schema: Yup.object().shape({
      email: Yup.string()
        .email("Enter a valid email address")
        .required("This field is required"),
      password: Yup.string().required("This field is required"),
    }),
  },
  signup: {
    fields: [
      {
        id: "email",
        size: 1,
        label: "Email",
        type: "text",
        placeholder: "Email address",
        required: true,
        disabled: false,
      },
    ],

    init: () => ({ email: "" }),

    schema: Yup.object().shape({
      email: Yup.string()
        .email("Enter a valid email address")
        .required("This field is required"),
    }),
  },
  "create-account": {
    fields: [
      {
        id: "firstName",
        size: 0.5,
        label: "First Name",
        type: "text",
        placeholder: "Your first name",
        required: true,
        disabled: false,
      },
      {
        id: "lastName",
        size: 0.5,
        label: "Last Name",
        type: "text",
        placeholder: "Your last name",
        required: true,
        disabled: false,
      },
      {
        id: "email",
        size: 1,
        label: "Email",
        type: "text",
        placeholder: "Email address",
        required: false,
        disabled: false,
      },
      {
        id: "password",
        size: 1,
        label: "Password",
        type: "password",
        placeholder: "Enter your password",
        required: true,
        disabled: false,
      },
      {
        id: "isAgree",
        size: 1,
        label: "I Agree to the terms & conditions & Privacy Policies",
        type: "checkbox",
        required: true,
        disabled: false,
      },
    ],

    init: () => ({
      firstName: "",
      lastName: "",
      password: "",
      isAgree: false,
    }),

    schema: Yup.object().shape({
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().required("This field is required"),
      password: Yup.string().required("This field is required").min(6, "Password must contain at least 6 characters"),
      isAgree: Yup.boolean().oneOf([true], "Must Accept Terms and Conditions"),
    }),
  },
};
