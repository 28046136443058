export const downloadVideo = async (videoUrl, filename) => {
  try {
    const response = await fetch(videoUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const tag = document.createElement("a");
    tag.href = url;
    tag.download = filename + ".mp4";
    tag.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
    // handle error
  }
};
