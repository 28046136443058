import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingLogo from "Core/components/LoadingLogo";

export default function LazyLoadingFallback() {
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "rgba(255, 255, 255, 0.9)",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2000,
      }}
    >
      <LoadingLogo />

      <Typography
        sx={{
          color: "#6552F3",
          fontSize: "15px",
          fontWeight: "600",
          textAlign: "center",
          mt: "12px",
        }}
      >
        One moment please...
      </Typography>
    </Box>
  );
}
