import ElitePlanIcon from "assets/pricing/elite-plan-icon.png";
import ProPlanIcon from "assets/pricing/pro-plan-icon.png";

export const FEATURES = {
  showNotes: "showNotes",
  summary: "summary",
  quotes: "quotes",
  chapters: "chapters",
  transcript: "transcript",
  article: "article",
  tweetThread: "tweetThread",
  captions: "captions",
  focusKeywords: "focusKeywords",
  titleSuggestions: "titleSuggestions",
  headlines: "headlines",
  audiograms: "audiograms",
  reels: "reels",
  clipFinder: "clipFinder",
  meetingMinutes: "meetingMinutes",
  actionItems: "actionItems",
  decisionLog: "decisionLog",
  importantDates: "importantDates",
  qAndA: "qAndA",
  nextAgenda: "nextAgenda",
  followUpEmails: "followUpEmails",
  sessionRecap: "sessionRecap",
  whitePaper: "whitePaper",
  faqs: "faqs",
  newsletter: "newsletter",
  landingPage: "landingPage",
  dictionary: "dictionary",
  createContent: "createContent",
  audioUpload: "audioUpload",
  videoUpload: "videoUpload",
  documentUpload: "documentUpload",
  rawTextUpload: "rawTextUpload",
  urlUpload: "urlUpload",
  editContent: "editContent",
  audioPlayer: "audioPlayer",
  sharedPages: "sharedPages",
  findAndReplace: "findAndReplace",
  aiAssistant: "aiAssistant",
  askDeciphrLibrary: "askDeciphrLibrary",
  askDeciphrFile: "askDeciphrFile",
  askDeciphrFolder: "askDeciphrFolder",
  deciphrBrain: "deciphrBrain",
  dbmChat: "dbmChat",
  dbmAnalytics: "dbmAnalytics",
};

export const PRO_FEATURES = [
  FEATURES.showNotes,
  FEATURES.summary,
  FEATURES.quotes,
  FEATURES.chapters,
  FEATURES.transcript,
  FEATURES.article,
  FEATURES.tweetThread,
  FEATURES.captions,
  FEATURES.focusKeywords,
  FEATURES.titleSuggestions,
  FEATURES.audiograms,
  FEATURES.reels,
  FEATURES.clipFinder,
  FEATURES.meetingMinutes,
  FEATURES.actionItems,
  FEATURES.decisionLog,
  FEATURES.importantDates,
  FEATURES.qAndA,
  FEATURES.nextAgenda,
  FEATURES.followUpEmails,
  FEATURES.sessionRecap,
  FEATURES.whitePaper,
  FEATURES.faqs,
  FEATURES.newsletter,
  FEATURES.landingPage,
  FEATURES.createContent,
  FEATURES.audioUpload,
  FEATURES.videoUpload,
  FEATURES.documentUpload,
  FEATURES.rawTextUpload,
  FEATURES.urlUpload,
  FEATURES.editContent,
  FEATURES.audioPlayer,
  FEATURES.sharedPages,
  FEATURES.findAndReplace,
  FEATURES.aiAssistant,
  FEATURES.askDeciphrLibrary,
  FEATURES.askDeciphrFile,
  FEATURES.askDeciphrFolder,
];

export const ELITE_FEATURES = [
  ...PRO_FEATURES,
  FEATURES.dictionary,
  FEATURES.deciphrBrain,
  FEATURES.dbmChat,
  FEATURES.dbmAnalytics,
];

export const plans = [
  {
    id: 1,
    name: "Professional",
    shortName: "Pro",
    description: "For pros who need share-ready multimedia content in a minute.",
    isPopular: true,
    isLimitedTimeOffer: true,
    maxFileLength: 600, // minutes = 10 hours
    maxFileSize: 10737418240, // 10 GB
    monthlyPrefix: "PM",
    yearlyPrefix: "PY",
    pricing: {
      monthlyPlans: [
        {
          id: 1,
          minutes: 80,
          amount: 5, // USD
          planCode: "PM80",
          planType: "standard",
        },
        {
          id: 2,
          minutes: 300,
          amount: 20, // USD
          planCode: "PM300",
          planType: "standard",
        },
        {
          id: 3,
          minutes: 800,
          amount: 54, // USD
          planCode: "PM800",
          planType: "standard",
        },
        {
          id: 4,
          minutes: 2500,
          amount: 169, // USD
          planCode: "PM2500",
          planType: "standard",
        },
      ],

      yearlyPlans: [
        {
          id: 1,
          minutes: 80,
          amount: 39, // USD
          amountPerMonth: 3.25, // USD
          planCode: "PY80",
          planType: "standard",
        },
        {
          id: 2,
          minutes: 300,
          amount: 146, // USD
          amountPerMonth: 12.17, // USD
          planCode: "PY300",
          planType: "standard",
        },
        {
          id: 3,
          minutes: 800,
          amount: 389, // USD
          amountPerMonth: 32.42, // USD
          planCode: "PY800",
          planType: "standard",
        },
        {
          id: 4,
          minutes: 2500,
          amount: 1215, // USD
          amountPerMonth: 101.25, // USD
          planCode: "PY2500",
          planType: "standard",
        },
      ],
    },

    availableFeatures: PRO_FEATURES,
    featureHighlights: {
      title: null,
      features: [
        "Accepts Widely Used File Types",
        "Upload Directly via URL",
        "Ready Made Content Presets",
        "Instant Audiograms & Reels",
      ],

      includes: [
        "1 FREE Collaborator Seat",
        "Automated Written Content",
        "Access to Old Library and Uploads",
        "Easy In-App Editor",
      ],
    },
    icon: ProPlanIcon,
  },
  {
    id: 2,
    name: "Elite",
    shortName: "Elite",
    description: "For A-listers ready to amplify their content with analytics and interactive AI.",
    isPopular: false,
    maxFileLength: 600, // minutes = 10 hours
    maxFileSize: 10737418240, // 10 GB
    monthlyPrefix: "EM",
    yearlyPrefix: "EY",
    pricing: {
      monthlyPlans: [
        {
          id: 1,
          minutes: 2500,
          amount: 169, // USD
          monthlyChatCredits: 500,
          planCode: "EM2500",
          planType: "standard",
        },
        {
          id: 2,
          minutes: 4000,
          amount: 270, // USD
          monthlyChatCredits: 1000,
          planCode: "EM4000",
          planType: "standard",
        },
        {
          id: 3,
          minutes: 5000,
          amount: 338, // USD
          monthlyChatCredits: 3000,
          planCode: "EM5000",
          planType: "standard",
        },
      ],

      yearlyPlans: [
        {
          id: 1,
          minutes: 2500,
          amount: 1215, // USD
          amountPerMonth: 101.25,
          monthlyChatCredits: 500,
          planCode: "EY2500",
          planType: "standard",
        },
        {
          id: 2,
          minutes: 4000,
          amount: 1944, // USD
          amountPerMonth: 162,
          monthlyChatCredits: 1000,
          planCode: "EY4000",
          planType: "standard",
        },
        {
          id: 3,
          minutes: 5000,
          amount: 2430, // USD
          amountPerMonth: 202.5,
          monthlyChatCredits: 3000,
          planCode: "EY5000",
          planType: "standard",
        },
      ],
    },

    availableFeatures: ELITE_FEATURES,
    featureHighlights: {
      title: "Everything in Professional plus...",
      features: [
        "Deciphr Brain Access",
        "Personalized Chatbot",
        "Real-Time Audience Analytics",
        "5 FREE Collaborator Seats",
      ],

      includes: [
        "WhatsApp Chat Support",
        "Priority Beta Access",
        "Custom AI Assistant Prompts",
        "Exclusive Weekly Content",
      ],
    },
    icon: ElitePlanIcon,
  },
];

export const FREE_PLAN = {
  id: 0,
  name: "Flexi Free",
  shortName: "Free",
  description: "",
  isPopular: false,
  isLimitedTimeOffer: false,
  maxFileLength: 0, // minutes = 10 hours
  maxFileSize: 0, // 10 GB
  monthlyPrefix: "FREE",
  yearlyPrefix: "FREE",
  pricing: {
    monthlyPlans: [
      {
        id: 1,
        minutes: 0,
        amount: 0,
        planCode: "FREE",
        planType: "standard",
      },
    ],

    yearlyPlans: [],
  },

  availableFeatures: [],
  featureHighlights: {
    title: null,
    features: [],
    includes: [],
  },
  icon: ProPlanIcon,
};

export const CREATE_FEATURES = [
  {
    id: 1,
    name: "Writing Engine",
    infoText: "Deciphr AI 2.0 has much more Processing Power compared to Deciphr AI 1.0. The writing engine "
      + "2.0 can turn your media files into an array of text-based outputs.",
    type: "string",
    professional: "Deciphr AI 2.0",
    elite: "Deciphr AI 2.0",
  },
  {
    id: 2,
    name: "Audio and Video Uploads",
    infoText: "Supported files: .txt, .doc, .docx, .mp3, .wav, .mp4, .mov or .webm file.",
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 3,
    name: "Direct Processing via YouTube Link",
    infoText: "Paste a YouTube URL to upload!",
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 4,
    name: "Content Presets",
    infoText: "Includes a ready-made preset for webinars, meetings, and podcast episodes.",
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 5,
    name: "Automated Content Generation (Written)",
    infoText: (
      <>
        Includes written content:

        <ul style={{ padding: "0 0 0 12px" }}>
          <li>Quotes</li>

          <li>Chapters</li>

          <li>Articles</li>

          <li>Captions</li>

          <li>Transcripts</li>

          <li>Focus Keywords</li>

          <li>Title Suggestions</li>

          <li>Tweet Thread</li>

          <li>Captions</li>

          <li>Meeting Minutes</li>

          <li>Summary</li>

          <li>Action Items</li>

          <li>Decision Logs</li>

          <li>Important Dates</li>

          <li>Q&A</li>

          <li>Next Agenda</li>

          <li>Follow-up Emails</li>
        </ul>
      </>
    ),
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 6,
    name: "Automated Content Generation (Media)",
    infoText: "Includes media content such as Audiograms and Video Reels",
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 7,
    name: "Easy In-App Editor",
    infoText: "Use the in-app editor to customize your articles, audiograms and video reels ensuring "
      + "your content always aligns with your brand.",
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 8,
    name: "In-App Audio Player",
    infoText: "Listen to your upload audios directly in the app.",
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 9,
    name: "Find & Replace",
    infoText: "Quickly search and replace text in your uploads",
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 10,
    name: "Auto Speaker Identification",
    infoText: "Automatically identify and label different speakers.",
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 11,
    name: "AI Assistant / Content Regenerator",
    infoText: "Revise and improve your content with our AI-powered assistant",
    type: "checkmark",
    professional: true,
    elite: true,
  },
];

export const SEARCH_FEATURES = [
  {
    id: 1,
    name: "Ask Deciphr",
    infoText: "The feature acts as your upload-specific search engine, delivering targeted answers "
      + "and actionable insights.",
    type: "checkmark",
    professional: true,
    elite: true,
  },
  {
    id: 2,
    name: "Deciphr Dictionary",
    infoText: "Create your own custom vocabulary. With custom spelling and entirely new words or "
      + "terms based on your brand.",
    type: "checkmark",
    professional: false,
    elite: true,
  },
];

export const ANALYZE_FEATURES = [
  {
    id: 1,
    name: "Deciphr Brain",
    infoText: "Instantly pull data across any unstructured documents",
    type: "checkmark",
    professional: false,
    elite: true,
  },
  {
    id: 2,
    name: "Custom Chatbot",
    infoText: "Let your customers interact with your content 24/7",
    type: "checkmark",
    professional: false,
    elite: true,
  },
  {
    id: 3,
    name: "Analytics",
    infoText: "Track customer chat interactions with real-time analytics",
    type: "checkmark",
    professional: false,
    elite: true,
  },
];
