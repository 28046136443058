import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchSeatProfile, updateSeatProfile as updateSeatProfileAPI } from "DAI/Profile/services/apis";
import { setToast } from "./toastSlice";

const seatUserSlice = createSlice({
  name: "seatUser",
  initialState: {
    userId: "",
    email: "",
    firstName: "",
    role: "",
    paymentProvided: false,
    createdAt: 0,
    updatedAt: 0,
    plan: "FREE",
    stripeCustomerId: "",
    subscriptionId: null,
    couponCode: null,
    lastName: "",
    companyName: null,
    podcastName: null,
    orgRole: null,
    orgId: null,
    bio: null,
    userType: null,
    industry: null,
    contentTypes: null,
    website: null,
    completedOnboardingQuiz: false,
    captchaScore: 0,
    transcriptUsed: 0,
    transcriptLimit: 0,
    additionalTranscriptLimit: 0,
    audioUsed: 0,
    audioLimit: 0,
    additionalAudioLimit: 0,
    audioWithMediaUsed: 0,
    audioWithMediaLimit: 0,
    additionalAudioWithMediaLimit: 0,
    regenerateUsed: 0,
    regenerateLimit: 0,
    additionalRegenerateLimit: 0,
    mediaContent: false,
    boost: "",
    styleId: null,
    usedCancellationCoupon: false,
    planPaused: false,
    isSeatAccount: false,
    teamOwner: "",
    isSeatAccepted: false,
    isBlocked: false,
  },

  reducers: {
    setSeatProfile(state, action) {
      state.userId = action.payload.user_id;
      state.email = action.payload.email;
      state.firstName = action.payload.first_name;
      state.role = action.payload.role;
      state.paymentProvided = action.payload.payment_provided;
      state.createdAt = action.payload.created_at;
      state.updatedAt = action.payload.updated_at;
      state.plan = action.payload.plan;
      state.stripeCustomerId = action.payload.stripe_customer_id;
      state.subscriptionId = action.payload.subscription_id;
      state.couponCode = action.payload.coupon_code;
      state.lastName = action.payload.last_name;
      state.companyName = action.payload.company_name;
      state.podcastName = action.payload.podcast_name;
      state.orgRole = action.payload.org_role;
      state.orgId = action.payload.org_id;
      state.bio = action.payload.bio;
      state.userType = action.payload.user_type;
      state.industry = action.payload.industry;
      state.contentTypes = action.payload.content_types;
      state.website = action.payload.website;
      state.completedOnboardingQuiz = action.payload.completed_onboarding_quiz;
      state.captchaScore = action.payload.captcha_score;
      state.transcriptUsed = action.payload.transcript_used;
      state.transcriptLimit = action.payload.transcript_limit;
      state.additionalTranscriptLimit = action.payload.additional_transcript_limit;
      state.audioUsed = action.payload.audio_used;
      state.audioLimit = action.payload.audio_limit;
      state.additionalAudioLimit = action.payload.additional_audio_limit;
      state.audioWithMediaUsed = action.payload.audio_with_media_used;
      state.audioWithMediaLimit = action.payload.audio_with_media_limit;
      state.additionalAudioWithMediaLimit = action.payload.additional_audio_with_media_limit;
      state.regenerateUsed = action.payload.regenerate_used;
      state.regenerateLimit = action.payload.regenerate_limit;
      state.additionalRegenerateLimit = action.payload.additional_regenerate_limit;
      state.mediaContent = action.payload.media_content;
      state.boost = action.payload.boost;
      state.styleId = action.payload.style_id;
      state.usedCancellationCoupon = action.payload.used_cancellation_coupon;
      state.planPaused = action.payload.plan_paused;
      state.isSeatAccount = action.payload.is_seat_account;
      state.teamOwner = action.payload.team_owner;
      state.isSeatAccepted = action.payload.is_seat_accepted;
      state.isBlocked = action.payload.is_blocked;
    },

    updateSeatProfile(state, payload) {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
    },
  },

});

export const {
  setSeatProfile,
  updateSeatProfile,
} = seatUserSlice.actions;

export const seatInfoSelector = (state) => state.seatUser;

export const getSeatProfile = createAsyncThunk(
  "seatUser/getSeatProfile",
  async (_, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const state = getState();

    try {
      const res = await fetchSeatProfile();
      dispatch(setSeatProfile(res));

      return state;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateSeatUserProfile = createAsyncThunk(
  "seatUser/updateSeatUserProfile",
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    dispatch(updateSeatProfile(payload));

    try {
      const res = await updateSeatProfileAPI(payload);

      dispatch(setToast({
        message: res.message,
        severity: "success",
      }));

      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default seatUserSlice.reducer;
