/* eslint-disable react/jsx-newline */
import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Auth from "pages/Auth";
import ForgotPassword from "pages/Auth/forgotPassword";

const NewSignup = lazy(() => import("DAI/Auth/pages/Signup"));
const NewLogin = lazy(() => import("DAI/Auth/pages/Login"));
const SignupV2 = lazy(() => import("pages/Auth/signupV2"));
const ResetPass = lazy(() => import("pages/Auth/resetPass"));
const PricingPage = lazy(() => import("DAI/Subscription/pages/Pricing"));
const CollaboratorSignup = lazy(() => import("DAI/Auth/pages/CollaboratorSignup"));

export default function CoreRoutes({ isEnabled }) {
  if (isEnabled) {
    return (
      <Routes>
        <Route path="auth" element={<Auth />}>
          {/* RESET OR EMAIL VERIFICATION ROUTE */}
          <Route path="password-reset" element={<ResetPass />} />
          <Route path="forgot" element={<ForgotPassword />} />
          <Route path="signup" element={<NewSignup />} />
          <Route path="collaborator-signup" element={<CollaboratorSignup />} />
          <Route path="login" element={<NewLogin />} />
          <Route path="sign-up" element={<SignupV2 />} />
        </Route>

        <Route path="/pricing" element={<PricingPage />} />
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
    );
  }

  return null;
}
