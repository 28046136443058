import EliteIcon from "assets/pricing/elite-plan-icon.png";
import ProfessionalIcon from "assets/pricing/pro-plan-badge.png";

export const badges = {
  "Flexi Free": {
    icon: null,
    color: "#777E90",
    variant: "outlined",
  },

  Hobbyist: {
    icon: ProfessionalIcon,
    color: "#6552F3",
    variant: "outlined",
    iconSizes: {
      sm: 16,
      lg: 20,
    },
  },

  Professional: {
    icon: ProfessionalIcon,
    color: "#0696FF",
    variant: "outlined",
    iconSizes: {
      sm: 14,
      lg: 20,
    },
  },

  Elite: {
    icon: EliteIcon,
    color: "#6552F3",
    variant: "outlined",
    iconSizes: {
      sm: 12,
      lg: 20,
    },
  },
};

export const a = "";
