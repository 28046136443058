import Axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { rollbar } from "Core/helpers/rollbar";
import { pause } from "DAI/helpers";
import axios from "utils/axiosConfig";
import { analytics } from "utils/GTM";

dayjs.extend(utc);

const getFileChunks = (file) => {
  const baseChunkSize = 1024 * 1024; // 1MB
  const chunks = [];
  let chunkSize = 1 * baseChunkSize; // Increases chunk size based on file size
  let start = 0;

  if (file.size < (1000 * baseChunkSize)) {
    chunkSize = 5 * baseChunkSize; // 5MB chunks
  } else {
    chunkSize = 10 * baseChunkSize; // 10MB chunks
  }

  while (start < file.size) {
    chunks.push(file.slice(start, start + chunkSize));

    start += chunkSize;
  }

  return chunks;
};

/**
 * Initiates the S3 multipart file upload process.
 *
 * @param {string} fileName The nam of the file to upload
 */
const initiateS3Upload = async (fileName) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/upload/start",
      data: {
        file_name: fileName,
      },
    });

    return data;
  } catch (e) {
    rollbar.error("S3 upload initiation failed", {
      error: e,
    });

    return Promise.reject(e);
  }
};

/**
 * Get the S3 upload url for a given file chunk
 *
 * @param {string} uploadId The S3 upload ID for the multipart upload
 * @param {string} blobPath The S3 file name of the file being uploaded
 * @param {number} partNumber The chunk/part number. 1-based indexing.
 *
 * @returns {Promise<any>|Promise<never>}
 */
const getSignedPartUrl = async (uploadId, blobPath, partNumber) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/upload/url",
      data: {
        upload_id: uploadId,
        blob_path: blobPath,
        part_number: partNumber,
      },
    });

    return data;
  } catch (e) {
    rollbar.error("Failed to get signed URL for S3 upload", {
      error: e,
    });

    return Promise.reject(e);
  }
};

const completeS3MultipartUpload = async (uploadId, blobPath, parts) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/upload/complete",
      data: {
        upload_id: uploadId,
        blob_path: blobPath,
        parts,
      },
    });

    return data;
  } catch (e) {
    rollbar.error("Failed to complete S3 multipart upload", {
      error: e?.response?.data,
    });

    return Promise.reject(e);
  }
};

const uploadChunk = async (url, fileChunk, retries = 10) => {
  const waitTimeInSeconds = [60, 50, 45, 40, 30, 20, 15, 15, 10, 5];

  try {
    const { headers } = await Axios({
      method: "PUT",
      url,
      data: fileChunk,
      headers: {
        Accept: "*/*",
        "Content-Type": "application/octet-stream",
      },
    });

    return headers.etag.replaceAll("\"", "");
  } catch (e) {
    if (retries > 0) {
      rollbar.warning("Retrying failed chunk upload", {
        error: e,
        attempt: (10 - retries) + 1,
      });

      await pause(waitTimeInSeconds[retries - 1] * 1000);

      return uploadChunk(url, fileChunk, retries - 1);
    }

    rollbar.error("Chunk upload retries failed", {
      error: e,
    });

    return Promise.reject(e);
  }
};

export const uploadMediaFile = async (file, onProgress) => {
  const chunks = getFileChunks(file);
  const parts = [];

  try {
    const response = await initiateS3Upload(file.name);
    const { upload_id, blob_path, file_id, gs_url } = response;

    // eslint-disable-next-line no-restricted-syntax
    for (const [index, value] of chunks.entries()) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const urlResponse = await getSignedPartUrl(upload_id, blob_path, index + 1);

        try {
          // eslint-disable-next-line no-await-in-loop
          const etag = await uploadChunk(urlResponse.signed_url, value);

          parts.push({ ETag: etag, PartNumber: index + 1 });

          onProgress(value.size);

          if (index === (chunks.length - 1)) {
            try {
              // eslint-disable-next-line no-await-in-loop
              await completeS3MultipartUpload(upload_id, blob_path, parts);
            } catch (e) {
              // S3 multipart upload completion failed
              return Promise.reject(e);
            }
          }
        } catch (e) {
          // All chunk upload retries failed
          return Promise.reject(e);
        }
      } catch (e) {
        // Did not receive signed URL from S3
        return Promise.reject(e);
      }
    }

    // eslint-disable-next-line camelcase
    return { file_name: file.name, file_id, gs_url };
  } catch (e) {
    // Initiate S3 upload failed
    return Promise.reject(e);
  }
};

export const triggerProcessingPipeline = async (fileId, speakers, preset) => {
  rollbar.info("Initiating file processing pipeline", {
    fileId,
    speakers,
    preset,
  });

  try {
    const { data } = await axios({
      method: "POST",
      url: "/up/process",
      data: {
        id: fileId,
        speakers,
        preset,
      },
    });

    analytics.track("Content Preset Used", { preset, fileId });

    return data;
  } catch (e) {
    rollbar.error("File processing pipeline failed", {
      fileId,
      error: e?.response?.data,
      errorMessage: e?.response?.data?.message || e.message,
      url: e?.config?.url,
    });

    return Promise.reject(e);
  }
};

export const saveTranscript = async (payload, filename, onProgress) => {
  rollbar.info("Uploading transcript file", { filename });

  try {
    const { data } = await axios({
      method: "POST",
      url: "/v1/up/save",
      data: payload,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (event) => {
        onProgress(event.loaded, event.total);
      },
    });

    rollbar.info("Transcript uploaded successfully", { filename });

    return data;
  } catch (e) {
    rollbar.error("Transcript upload failed", {
      filename,
      error: e?.response?.data,
      errorCode: e.status,
      errorMessage: e.message,
      url: e?.config?.url,
    });

    return Promise.reject(e);
  }
};

export const getProcessedStatus = async (docId) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/up/status/${docId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * Formats a given time, in seconds, to a human-readable format
 *
 * @param {number} time The time to be formatted, in seconds
 * @returns {string} A human-readable time format
 */
export const getFormattedTime = (time) => {
  let formattedTime = "";

  if (time >= 3600) {
    const hours = dayjs.utc(time * 1000).format("H");
    const minutes = dayjs.utc(time * 1000).format("m");
    let duration = (hours === "1") ? "hour" : "hours";

    formattedTime = `${hours} ${duration}`;

    if (minutes > 0) {
      duration = (minutes === "1") ? "minute" : "minutes";

      formattedTime += `, ${minutes} ${duration}`;
    }
  }

  if (time >= 60 && time < 3600) {
    const minutes = dayjs.utc(time * 1000).format("m");
    const seconds = dayjs.utc(time * 1000).format("s");
    let duration = minutes === "1" ? "minute" : "minutes";

    formattedTime = `${minutes} ${duration}`;

    if (seconds > 0) {
      duration = (seconds === "1") ? "second" : "seconds";

      formattedTime += `, ${seconds} ${duration}`;
    }
  }

  if (time < 60) {
    const duration = time === 1 ? "second" : "seconds";

    formattedTime = `${time.toFixed(0)} ${duration}`;
  }

  return formattedTime;
};

export const getFormattedSize = (sizeInBytes) => {
  const size = sizeInBytes / (1024 * 1024 * 1024);
  let formattedSize = `${(size).toFixed(1)} GB`;

  if (size < 1) {
    const sizeInMB = size * 1024;

    formattedSize = `${(size * 1024).toFixed(1)} MB`;

    if (sizeInMB < 1) {
      formattedSize = `${(size * 1024 * 1024).toFixed(1)} KB`;
    }
  }

  return formattedSize;
};

export const uploadLink = async (params) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/video_transcription/youtube`,
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const processingBatchFiles = async (files, duration, preset, styleId) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/video_transcription/youtube/process`,
      data: {
        videos: files,
        preset,
        style_id: styleId,
      },
    });

    analytics.track("User Uploaded Batch Files", {
      duration: dayjs.utc(duration * 1000).format("HH:mm:ss"),
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const trimFileName = (fileName, trimLength = 30) => {
  const lastIndex = fileName.lastIndexOf(".");
  const extension = fileName.substring(lastIndex) || "";

  if (fileName.length <= trimLength) {
    return fileName;
  }

  return `${fileName.substring(0, trimLength)}...${extension}`;
};

export const trimTitle = (title, trimLength = 30) => {
  if (title.length <= trimLength) {
    return title;
  }

  return `${title.substring(0, trimLength)}...`;
};

/**
 * Split a given duration, in seconds, into a 10-minute bucket.
 * For example, given 600 seconds, it will fit into the
 * "0-10 mins" bucket.
 *
 * @param {number} duration The duration, in seconds
 * @returns {string} bucket The bucket under which this duration fits
 */
export const getDurationBucket = (duration) => {
  const durationInMinutes = duration / 60;
  const nearestBucket = Math.ceil(durationInMinutes / 10) * 10;
  let bucket = "";

  if (nearestBucket <= 90) {
    const prevBucket = nearestBucket - 10;

    bucket = `${prevBucket}-${nearestBucket} mins`;
  } else {
    bucket = "90+ mins";
  }

  return bucket;
};
