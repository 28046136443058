export const stripHTML = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");

  return doc.body.textContent;
};

/**
 * Get a promise that resolves after millis which you can use to pause
 *
 * @param {number} millis Milliseconds to pause
 * @returns {Promise<undefined>} A Promise that resolves after `millils`
 */
export const pause = (millis) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, millis);
});
