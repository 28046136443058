/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchFileDetails, updateContent } from "DAI/ContentDetails/services/apis";
import {
  getCreatedContentTypes,
  initializeContent,
  resetContent,
  setIsInit,
} from "DAI/ProcessedData/store/writtenContentSlice";
import { initAudiograms, resetAudiogram } from "store/audiogramSlice";
import { initVideoReels, resetVideoReels } from "store/dai/videoreelSlice";
import { setToast } from "store/toastSlice";
import { initializeTanscripts } from "store/transcriptSlice";
import { analytics } from "utils/GTM";
import { history } from "utils/history";

const contentSlice = createSlice({
  name: "content",
  initialState: {
    fileId: null,
    fileName: null,
    fileType: null,
    headline: null,
    preset: null,
    contentList: [],
    createdAt: null,
    processed: null,
    version: null,
    viewable: null,
    hasMedia: false,
    isLoading: false,
    isRecheck: false,
  },

  reducers: {
    initializeFile: (state, action) => {
      const {
        fileId,
        fileName,
        fileType,
        headline,
        preset,
        contentList,
        createdAt,
        processed,
        version,
        viewable,
        hasMedia,
      } = action.payload;

      state.fileId = fileId;
      state.fileName = fileName;
      state.fileType = fileType;
      state.headline = headline;
      state.preset = preset;
      state.contentList = contentList;
      state.createdAt = createdAt;
      state.processed = processed;
      state.version = version;
      state.viewable = viewable;
      state.hasMedia = hasMedia;
    },

    resetFile: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = null;
      });

      state.contentList = [];
      state.hasMedia = false;
      state.isLoading = false;
      state.isRecheck = false;
    },

    setHeadline: (state, action) => {
      const { headline } = action.payload;

      state.headline = headline;
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setIsRecheck: (state, action) => {
      state.isRecheck = action.payload;
    },
  },
});

// Actions
export const { initializeFile, resetFile, setHeadline, setIsLoading, setIsRecheck } = contentSlice.actions;

// Selectors
export const selectFileId = (state) => state.content.fileId;
export const isFileProcessed = (state) => state.content.processed === "Y";
export const selectFileMetadata = (state) => ({
  fileId: state.content.fileId,
  fileName: state.content.fileName,
  fileType: state.content.fileType,
  headline: state.content.headline,
  preset: state.content.preset,
  processed: state.content.processed,
  createdAt: state.content.createdAt,
  viewable: state.content.viewable,
  hasMedia: state.content.hasMedia,
});
export const selectFileType = (state) => state.content.fileType;
export const selectHeadline = (state) => state.content.headline;
export const selectContentList = (state) => state.content.contentList;
export const selectHasMedia = (state) => state.content.hasMedia;
export const selectIsLoading = (state) => state.content.isLoading;
export const selectIsRecheck = (state) => state.content.isRecheck;
export const selectPreset = (state) => state.content.preset;
export const selectProcessed = (state) => state.content.processed;

// Async thunks
export const getFileDetails = createAsyncThunk("content/get", async (payload, thunkAPI) => {
  const { fileId, isRecheck } = payload;
  const { dispatch, getState } = thunkAPI;
  const { content } = getState();
  const { isLoading } = content;

  if (isLoading) {
    return;
  }

  dispatch(setIsLoading(true));
  dispatch(setIsRecheck(isRecheck || false));

  try {
    const data = await fetchFileDetails(fileId);
    const { file, generated_content, created_contents } = data;
    const {
      audiogram,
      created_at,
      display_transcript,
      file_type,
      generate_media,
      headline,
      id,
      preset,
      processed,
      serving_url,
      speakers,
      title,
      version,
      videogram,
      viewable,
      written_content_list,
      is_timestamped,
      raw_text,
    } = file;

    dispatch(
      initializeFile({
        fileId: id,
        fileName: title,
        fileType: file_type,
        headline,
        preset,
        contentList: written_content_list,
        createdAt: created_at,
        processed,
        version,
        viewable,
        hasMedia: generate_media,
      }),
    );

    dispatch(initializeContent({ fileId: id, preset, content: generated_content, createdContents: created_contents }));
    dispatch(initializeTanscripts({
      transcript: display_transcript,
      rawText: raw_text,
      isTimestamped: is_timestamped,
      docId: id,
      speakers,
    }));
    dispatch(initAudiograms({ audioFile: serving_url, audiogram, docId: id }));
    dispatch(initVideoReels({ reels: videogram, docId: id }));
    dispatch(getCreatedContentTypes({ fileId: id }));
    dispatch(setIsInit(true));
  } catch (e) {
    history.navigate("/library");
    dispatch(setToast({ severity: "error", message: e?.response?.data?.message || e.message }));
  } finally {
    dispatch(setIsLoading(false));
    dispatch(setIsRecheck(false));
  }
});

export const updateHeadline = createAsyncThunk("content/updateHeadline", async (payload, thunkAPI) => {
  const { headline } = payload;
  const { dispatch, getState } = thunkAPI;
  const { content } = getState();
  const { fileId } = content;

  try {
    await updateContent(fileId, "headline", headline);

    dispatch(setHeadline({ headline }));
    dispatch(
      setToast({
        message: "Headline updated successfully",
        severity: "success",
        autoClose: true,
      }),
    );

    analytics.track("headline edited successfully");
  } catch (e) {
    dispatch(
      setToast({
        message: "Headline could not be updated at this time. Please retry in a bit.",
        severity: "error",
      }),
    );
  }
});

export const resetAllTheThings = createAsyncThunk("content/reset", async (_, thunkAPI) => {
  const { dispatch } = thunkAPI;

  dispatch(resetFile());
  dispatch(resetContent());
  dispatch(resetAudiogram());
  dispatch(resetVideoReels());
});

export default contentSlice.reducer;
