import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDurationBucket,
  getProcessedStatus,
  processingBatchFiles,
  triggerProcessingPipeline,
  uploadLink,
  uploadMediaFile,
} from "DAI/Upload/services";
import { getYTLinks, processYTVideo } from "DAI/UserOnboarding/services";
import { getFolder } from "store/dai/librarySlice";
import { setToast } from "store/toastSlice";

let posthog;

import("utils/GTM").then(({ analytics }) => {
  posthog = analytics;
});

const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    fileName: null,
    fileSize: 0,
    fileType: null,
    duration: 0, // seconds
    preset: "podcast",
    uploadStartTime: null,
    uploadedSize: 0,
    timeRemaining: 0,
    isUploading: false,
    isDone: false,
    isURLUpload: false,
    fileId: null,
    isViewable: false,
    hasUploadErrors: false,
    batchUploadLoader: false,
    batchList: [],
    nextBatchPageToken: null,
    filelink: "",
    isBatchListLoading: false,
    skippedOBUpload: false,
  },

  reducers: {
    setFileToUpload: (state, action) => {
      state.fileName = action.payload.fileName;
      state.fileSize = action.payload.fileSize;
      state.fileType = action.payload.fileType || "document";
      state.duration = action.payload.duration / 60;
      state.isUploading = true;
      state.isDone = false;
      state.fileId = null;
      state.isURLUpload = action.payload.isURLUpload || false;
      state.isViewable = false;
      state.uploadStartTime = Date.now();
      state.hasUploadErrors = false;
    },

    setPreset: (state, action) => {
      const { preset } = action.payload;

      state.preset = preset;
    },

    updateUploadedSize: (state, action) => {
      state.uploadedSize = action.payload;
    },

    updateProgress: (state, action) => {
      state.uploadedSize += action.payload.size;
    },

    setIsDone: (state, action) => {
      state.isDone = action.payload;

      if (action.payload) {
        state.isUploading = false;
      }
    },

    setFileId: (state, action) => {
      state.fileId = action.payload;
    },

    setIsViewable: (state, action) => {
      state.isViewable = action.payload;
    },

    setHasUploadErrors: (state, action) => {
      state.hasUploadErrors = action.payload;
      state.isUploading = false;
    },

    setBatchList: (state, action) => {
      const { batchList, opType } = action.payload;
      const operation = opType || "append";

      if (batchList) {
        if (operation === "append") {
          state.batchList = state.batchList.concat(batchList);
        } else {
          state.batchList = batchList;
        }
      }
    },

    setNextBatchPageToken: (state, action) => {
      state.nextBatchPageToken = action.payload;
    },

    setBatchUploadLoader: (state, action) => {
      state.batchUploadLoader = action.payload;
    },

    setFileLink: (state, action) => {
      state.filelink = action.payload;
    },

    setBatchListLoading: (state, action) => {
      state.isBatchListLoading = action.payload;
    },

    reset: (state) => {
      state.fileName = null;
      state.fileSize = 0;
      state.fileType = null;
      state.preset = "podcast";
      state.uploadedSize = 0;
      state.duration = 0;
      state.timeRemaining = 0;
      state.isUploading = false;
      state.isDone = false;
      state.isURLUpload = false;
      state.fileId = null;
      state.isViewable = false;
      state.uploadStartTime = null;
      state.isBatchUpload = false;
      state.hasUploadErrors = false;
      state.batchUploadLoader = false;
      state.batchList = [];
      state.nextBatchPageToken = null;
      state.filelink = "";
    },

    setSkippedOBUpload: (state, action) => {
      state.skippedOBUpload = action.payload;
    },
  },
});

export const {
  setFileToUpload,
  setPreset,
  updateUploadedSize,
  updateProgress,
  setIsDone,
  setFileId,
  setIsViewable,
  setHasUploadErrors,
  reset,
  setBatchUploadLoader,
  setBatchList,
  setNextBatchPageToken,
  setFileLink,
  setBatchListLoading,
  setSkippedOBUpload,
} = uploadSlice.actions;

export const selectUploadState = (state) => state?.upload;
export const selectFileName = (state) => state.upload.fileName;
export const getSelectedPreset = (state) => state.upload.preset;
export const selectIsUploading = (state) => state.upload.isUploading;
export const getIsComplete = (state) => state.upload.isDone;
export const isProcessed = (state) => state.upload.isViewable;
export const getTimeRemaining = (state) => state.upload.timeRemaining;
export const getProgress = (state) => {
  const progress = Math.round((state.upload.uploadedSize / state.upload.fileSize) * 100);

  if (Number.isNaN(progress)) {
    return 0;
  }

  return (progress < 100) ? progress : 100;
};
export const getFileId = (state) => state.upload.fileId;
export const selectHasErrors = (state) => state.upload.hasUploadErrors;
export const selectBatchUploadLoader = (state) => state.upload.batchUploadLoader;
export const selectBatchList = (state) => state.upload.batchList;
export const selectFileLink = (state) => state.upload.filelink;
export const selectNextBatchPageToken = (state) => state.upload.nextBatchPageToken;
export const isBatchListLoadingSelector = (state) => state.upload.isBatchListLoading;
export const selectSkippedOBUpload = (state) => state.upload.skippedOBUpload;

export const videoUpload = createAsyncThunk("upload/video", async (payload, thunkAPI) => {
  const { file, duration, speakers } = payload;
  const { dispatch, getState } = thunkAPI;
  const { library, upload } = getState();
  const { breadcrumbs } = library;
  const { preset } = upload;
  const currentFolder = breadcrumbs[breadcrumbs.length - 1]?.folderId;

  const setProgress = (uploadedSize) => {
    dispatch(updateProgress({ size: uploadedSize }));
  };

  dispatch(
    setFileToUpload({
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type?.split("/")[0],
      duration,
    }),
  );

  try {
    const { file_id } = await uploadMediaFile(file, setProgress);

    dispatch(setFileId(file_id));

    try {
      await triggerProcessingPipeline(file_id, speakers, preset);

      setTimeout(() => {
        dispatch(getFolder({ folderId: currentFolder || "root" }));
      }, 3000);

      dispatch(setIsDone(true));

      posthog.track("User Successfully Uploaded Transcript", {
        transcriptType: "video",
        duration: (duration / 60).toFixed(2),
        durationBucket: getDurationBucket(duration),
      });
    } catch (e) {
      dispatch(reset());
      dispatch(
        setToast({
          message: "Your file was uploaded but could not be processed. We're looking into it.",
          severity: "warning",
        }),
      );
    }
  } catch (e) {
    dispatch(reset());
    dispatch(setHasUploadErrors(true));
    dispatch(
      setToast({
        message: "Your file could not be uploaded. Please try again.",
        severity: "error",
      }),
    );
  }
});

export const audioUpload = createAsyncThunk("upload/audio", async (payload, thunkAPI) => {
  const { file, duration, speakers } = payload;
  const { dispatch, getState } = thunkAPI;
  const { library, upload } = getState();
  const { breadcrumbs } = library;
  const { preset } = upload;
  const currentFolder = breadcrumbs[breadcrumbs.length - 1]?.folderId;

  const setProgress = (uploadedSize) => {
    dispatch(updateProgress({ size: uploadedSize }));
  };

  dispatch(
    setFileToUpload({
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type?.split("/")[0],
      duration,
    }),
  );

  try {
    const { file_id } = await uploadMediaFile(file, setProgress);

    dispatch(setFileId(file_id));

    try {
      await triggerProcessingPipeline(file_id, speakers, preset);

      setTimeout(() => {
        dispatch(getFolder({ folderId: currentFolder || "root" }));
      }, 3000);

      dispatch(setIsDone(true));

      posthog.track("User Successfully Uploaded Transcript", {
        transcriptType: "audio",
        duration: (duration / 60).toFixed(2),
        durationBucket: getDurationBucket(duration),
      });
    } catch (e) {
      dispatch(reset());
      dispatch(
        setToast({
          message: "Your file was uploaded but could not be processed. We're looking into it.",
          severity: "warning",
        }),
      );
    }
  } catch (e) {
    dispatch(reset());
    dispatch(setHasUploadErrors(true));
    dispatch(
      setToast({
        message: "Your file could not be uploaded. Please try again.",
        severity: "error",
      }),
    );
  }
});

export const docUpload = createAsyncThunk("upload/doc", async (payload, thunkAPI) => {
  const { file, speakers } = payload;
  const { dispatch, getState } = thunkAPI;
  const { library, upload } = getState();
  const { breadcrumbs } = library;
  const { preset } = upload;
  const currentFolder = breadcrumbs[breadcrumbs.length - 1]?.folderId;

  const setProgress = (uploadedSize) => {
    dispatch(updateProgress({ size: uploadedSize }));
  };

  dispatch(
    setFileToUpload({
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type?.split("/")[0],
      duration: 0,
    }),
  );

  try {
    const { file_id } = await uploadMediaFile(file, setProgress);

    dispatch(setFileId(file_id));

    try {
      await triggerProcessingPipeline(file_id, speakers, preset);

      setTimeout(() => {
        dispatch(getFolder({ folderId: currentFolder || "root" }));
      }, 3000);

      dispatch(setIsDone(true));

      posthog.track("User Successfully Uploaded Transcript", {
        transcriptType: "document",
        duration: undefined,
        durationBucket: undefined,
      });
    } catch (e) {
      dispatch(reset());
      dispatch(
        setToast({
          message: "Your file was uploaded but could not be processed. We're looking into it.",
          severity: "warning",
        }),
      );
    }
  } catch (e) {
    dispatch(reset());
    dispatch(setHasUploadErrors(true));
    dispatch(
      setToast({
        message: e?.response?.data?.message || e?.message,
        severity: "error",
      }),
    );
  }
});

// Used exclusively in the Onboarding flow. Uses the podcast preset by default.
export const youtubeUpload = createAsyncThunk("upload/youtube", async (payload, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { library } = getState();
  const { breadcrumbs } = library;
  const { url } = payload;
  const currentFolder = breadcrumbs[breadcrumbs.length - 1]?.folderId;

  try {
    const video = await getYTLinks(url);

    if (video) {
      dispatch(
        setFileToUpload({
          fileName: video.title,
          fileSize: 100,
          fileType: "video",
        }),
      );
      dispatch(setFileLink(url));
      dispatch(updateProgress({ size: 48, timeRemaining: 2 }));

      try {
        await processYTVideo(video);

        dispatch(updateProgress({ size: 84, timeRemaining: 1 }));

        setTimeout(() => {
          dispatch(updateProgress({ size: 100, timeRemaining: 0 }));
          dispatch(getFolder({ folderId: currentFolder || "root" }));
        }, 3000);
      } catch (e) {
        throw new Error(e);
      }
    }
  } catch (e) {
    dispatch(
      setToast({
        message: "YouTube link could not be processed.",
        severity: "error",
      }),
    );
    dispatch(reset());
  }
});

export const getFileStatus = createAsyncThunk("upload/fileStatus", async (payload, thunkAPI) => {
  const { docId } = payload;
  const { dispatch } = thunkAPI;

  try {
    const file = await getProcessedStatus(docId);

    if (file.viewable) {
      dispatch(setIsViewable(true));
    }
  } catch (e) {
    //
  }
});

export const linkForBatchUpload = createAsyncThunk("upload/linkForBatchUpload", async (payload, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { url, fromStart, nextBatchToken } = payload || {};
  const { upload: { filelink } } = getState();
  const opType = fromStart ? "overwrite" : "append";

  const params = new URLSearchParams({ url: url || filelink });

  if (fromStart) {
    dispatch(setFileLink(url));
  }

  if (nextBatchToken) {
    params.set("pageToken", nextBatchToken);
  }

  dispatch(setBatchListLoading(true));

  try {
    const response = await uploadLink(params);

    if (response.videos.length === 0) {
      dispatch(setNextBatchPageToken(null));
    } else {
      dispatch(setNextBatchPageToken(response.pageToken || null));
    }

    dispatch(setBatchList({ batchList: response.videos, opType }));
  } catch (e) {
    dispatch(setNextBatchPageToken(null));
    dispatch(
      setToast({
        message: "URL import did not succeed. Please try again.",
        severity: "error",
        autoClose: true,
      }),
    );
  } finally {
    dispatch(setBatchListLoading(false));
  }
});

// eslint-disable-next-line consistent-return
export const uploadBatch = createAsyncThunk("upload/uploadBatch", async (filesSelected, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  const { library, upload, writingStyle } = getState();
  const { breadcrumbs } = library;
  const { preset } = upload;
  const { selectedStyleItemId } = writingStyle;
  const currentFolder = breadcrumbs[breadcrumbs.length - 1]?.folderId;
  const styleId = (selectedStyleItemId === "deciphr_default") ? null : selectedStyleItemId;

  dispatch(setFileToUpload({
    fileName: "",
    fileSize: 0,
    fileType: "",
    duration: 0,
  }));

  try {
    await processingBatchFiles(filesSelected, 0, preset, styleId);

    dispatch(setNextBatchPageToken(null));
    dispatch(setBatchList([]));
    dispatch(setIsDone(true));
    dispatch(
      setToast({
        message: "Files queued for processing!",
        severity: "success",
        autoClose: true,
      }),
    );

    setTimeout(() => {
      dispatch(getFolder({ folderId: currentFolder || "root" }));
    }, 5000);
  } catch (e) {
    dispatch(
      setToast({
        message: "There was an error uploading your files.",
        type: "error",
        autoClose: true,
      }),
    );

    return rejectWithValue(e.message);
  }
});

export default uploadSlice.reducer;
