import awsLogo from "assets/aws-logo.png";
import dropboxLogo from "assets/dropbox-logo.png";
import googleLogo from "assets/google-logo.png";
import paypalLogo from "assets/paypal-logo.png";
import spotifyLogo from "assets/spotify-logo.png";

export const partnerLogos = [
  { name: "Amazon Web Services", image: awsLogo },
  { name: "Google", image: googleLogo },
  { name: "Dropbox", image: dropboxLogo },
  { name: "PayPal", image: paypalLogo },
  { name: "Spotify", image: spotifyLogo },
];
