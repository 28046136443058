import { useState } from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import UploadCredits from "DAI/Navigation/containers/UploadCredits";
import UserMenuItemCtn from "DAI/Profile/containers/UserMenuItemCtn";
import LibrarySearchBar from "DAI/Library/components/LibrarySearchBar";
import HamburgerIcon from "./hamburger";
import UserInfo from "./user";

export default function MobileUserMenu() {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 899px)");

  const onToggle = () => {
    if (open) {
      window.dispatchEvent(new Event("ham:closed"));
    } else {
      window.dispatchEvent(new Event("ham:opened"));
    }

    setOpen((prev) => !prev);
  };

  if (isMobile) {
    return (
      <>
        <Box
          aria-hidden={!open}
          aria-live="polite"
          sx={{
            bgcolor: "#FFF",
            borderBottom: "thin solid #E3E6EA",
            borderTop: "thin solid #E3E6EA",
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.34)",
            display: "block",
            maxHeight: open ? "600px" : 0,
            transition: "max-height 0.48s ease-in-out",
            overflow: "hidden",
            position: "fixed",
            left: 0,
            right: 0,
            top: "90px",
            zIndex: 184,
          }}
        >
          <div style={{ padding: "32px 24px 8px" }}>
            <UserInfo />

            <LibrarySearchBar expanded />

            <Box sx={{ my: "32px" }}>
              <UploadCredits />
            </Box>

            <Box sx={{ mt: "32px" }}>
              <UserMenuItemCtn onClose={onToggle} />
            </Box>
          </div>
        </Box>

        <HamburgerIcon open={open} onClick={onToggle} />
      </>
    );
  }
}
