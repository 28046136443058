import { Link } from "react-router-dom";
import { ListItemIcon, ListItemText, MenuItem, SvgIcon } from "@mui/material";

export default function UserMenuItem({ name, to, target, icon, onClose }) {
  return (
    <MenuItem
      sx={{
        padding: "8px",
        "& .MuiListItemIcon-root": {
          minWidth: 0,
          mr: "8px",
        },
      }}
    >
      <ListItemIcon>
        <SvgIcon sx={{ fontSize: 16 }} component={icon} inheritViewBox />
      </ListItemIcon>

      <ListItemText sx={{ fontSize: "14px", fontWeight: 500 }}>
        <Link
          to={to}
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : ""}
          style={{ display: "block", width: "100%" }}
          onClick={onClose}
        >
          {name}
        </Link>
      </ListItemText>
    </MenuItem>
  );
}
