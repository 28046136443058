import axiosClient from "utils/axiosConfig";

export const cancelSubscription = async () => {
  try {
    const { data } = await axiosClient({
      method: "POST",
      url: "/v3/payment/unsubscribe",
      data: {
        action: "CANCEL",
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const a = "";
