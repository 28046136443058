import { getRangeInMillisFromIndex } from "DAI/MediaEditor/utils/mediaEditor";

const { createSlice } = require("@reduxjs/toolkit");

export const PlayerMode = {
  Playback: "Playback",
  Trim: "Trim",
};

const videoReelEditorSlice = createSlice({
  name: "videoReelEditor",
  initialState: {
    videoReel: null,
    editedVideoReel: null,
    player: {
      mode: PlayerMode.Playback,
      rangeInMillis: {
        start: 0,
        end: 0,
      },
    },
    trimming: {
      isTouched: false,
      trimmedRangeInMillis: {
        start: 0,
        end: 0,
      },
      trimmedWordRange: {
        start: 0,
        end: 0,
      },
      highlightedWordRange: {
        start: 0,
        end: 0,
      },
      highlightedRangeInMillis: {
        start: 0,
        end: 0,
      },
    },
  },

  reducers: {
    mute(state) {
      state.player.isMuted = true;
    },
    unmute(state) {
      state.player.isMuted = false;
    },
    setVideoReel(state, action) {
      state.videoReel = action.payload;
      state.editedVideoReel = action.payload;
    },
    setEditedVideoReel(state, action) {
      state.editedVideoReel = {
        ...action.payload,
        start: Number(action.payload.start),
        end: Number(action.payload.end),
      };
    },
    setPlayerMode(state, action) {
      state.player.mode = action.payload;
    },
    setPlayerRangeInMillis(state, action) {
      state.player.rangeInMillis = action.payload;
    },

    setTrimmerToTouched(state) {
      state.trimming.isTouched = true;
    },
    resetTrimmer(state) {
      state.trimming = {
        isTouched: false,
        trimmedRangeInMillis: {
          start: 0,
          end: 0,
        },
        trimmedWordRange: {
          start: 0,
          end: 0,
        },
        highlightedWordRange: {
          start: 0,
          end: 0,
        },
        highlightedRangeInMillis: {
          start: 0,
          end: 0,
        },
      };
    },
    /**
     * @param {Object} action
     * @param {Object} action.payload
     * @param {number} action.payload.startIndex
     * @param {number} action.payload.endIndex
     */
    setTrimmedWordRange(state, action) {
      if (!state.editedVideoReel) {
        return;
      }

      state.trimming.trimmedWordRange = {
        start: action.payload.startIndex,
        end: action.payload.endIndex,
      };

      const millisRange = getRangeInMillisFromIndex({
        words: state.editedVideoReel.groupedWords,
        startIndex: action.payload.startIndex,
        endIndex: action.payload.endIndex,
      });
      state.trimming.trimmedRangeInMillis = millisRange;
    },
    setTrimmedRangeInMillis(state, action) {
      if (!state.editedVideoReel) {
        return;
      }

      const startWordIndex = state.editedVideoReel.groupedWords.findIndex(
        (word) => {
          if (action.payload.start >= word.start && action.payload.start < word.end) {
            return true;
          }

          return false;
        },
      );

      const endWordIndex = state.editedVideoReel.groupedWords.findIndex(
        (word) => {
          if (action.payload.end <= word.end) {
            return true;
          }

          return false;
        },
      );

      if (startWordIndex !== -1 && endWordIndex !== -1) {
        state.trimming.trimmedWordRange = {
          start: startWordIndex,
          end: endWordIndex,
        };

        state.trimming.trimmedRangeInMillis = {
          start: state.editedVideoReel.groupedWords[startWordIndex].start,
          end: state.editedVideoReel.groupedWords[endWordIndex].end,
        };
      }
    },

    setHighlightedWordRange(state, action) {
      if (!action.payload) {
        state.trimming.highlightedWordRange = {
          start: 0,
          end: 0,
        };

        state.trimming.highlightedRangeInMillis = {
          start: 0,
          end: 0,
        };

        return;
      }

      state.trimming.highlightedWordRange = {
        start: action.payload.start || 0,
        end: action.payload.end || state.editedVideoReel.groupedWords.length - 1,
      };

      state.trimming.highlightedRangeInMillis = {
        start:
          state.editedVideoReel.groupedWords[action.payload.start]?.start
          || state.player.rangeInMillis.start,
        end:
          state.editedVideoReel.groupedWords[action.payload.end]?.end
          || state.player.rangeInMillis.end,
      };
    },
  },
});

export const {
  mute,
  unmute,
  setVideoReel,
  setEditedVideoReel,
  setPlayerMode,
  setTrimmedRangeInMillis,
  setTrimmedWordRange,
  setHighlightedWordRange,
  setPlayerRangeInMillis,
  setTrimmerToTouched,
  resetTrimmer,
} = videoReelEditorSlice.actions;

/**
 * @returns {ReturnType<typeof videoReelEditorSlice["getInitialState"]>}
 */
export const selectVideoReelEditorSlice = (state) => (
  state.videoReelEditor
);

/**
 * @returns {ReturnType<typeof videoReelEditorSlice["getInitialState"]>["player"]}
 */
export const selectPlayerState = (state) => state.videoReelEditor.player;
export const selectVideoReel = (state) => state.videoReelEditor.videoReel;
export const selectEditedVideoReel = (state) => state.videoReelEditor.editedVideoReel;
/**
 * @returns {ReturnType<typeof videoReelEditorSlice["getInitialState"]>["trimming"]}
 */
export const selectTrimmingState = (state) => state.videoReelEditor.trimming;

export default videoReelEditorSlice.reducer;
