import axiosClient, { getReqUrl } from "utils/axiosConfig";

export const fetchFileDetails = async (fileId) => {
  try {
    const { data } = await axiosClient({
      method: "GET",
      url: `/up/details/${fileId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateContent = async (fileId, type, content) => {
  try {
    const { data } = await axiosClient({
      method: "PUT",
      url: `/v1/up/processed_data/${fileId}/${type}`,
      data: { content },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadWrittenContent = async ({ fileId, queryParams }) => {
  try {
    const { data } = await axiosClient({
      method: "GET",
      url: `/v1/up/download/${fileId}`,
      params: queryParams,
      responseType: "blob",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadAllAsZip = async ({ fileId, params }) => {
  try {
    const { data } = await axiosClient({
      method: "GET",
      url: `/v2/up/export/${fileId}`,
      params,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export async function createContent({
  fileId,
  type,
}) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const url = getReqUrl("/up/content/create");

  const res = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ file_id: fileId, type }),
  });

  return res;
}

export async function getAllCreatedContentTypes({
  fileId,
}) {
  const { data } = await axiosClient({
    method: "GET",
    url: `/up/content/generated/${fileId}`,
  });

  return data;
}
