import { lazy, Suspense } from "react";
import PropType from "prop-types";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { partnerLogos } from "./data";
import styles from "./styles";
import daiLogo from "assets/dai-logo-purple.svg";
import deciphrLogo from "assets/deciphr-logo-purple.svg";
import circle from "assets/textCirlce.svg";

const Benefits = lazy(() => import("DAI/Auth/components/Benefits"));
const TestimonialContainer = lazy(() => import("DAI/Auth/containers/TestimonialContainer"));
const CountdownTimer = lazy(() => import("DAI/Auth/components/CountdownTimer"));

export default function AuthLayout({ children, initAction }) {
  return (
    <Box sx={styles.root}>
      <Container>
        <Box component="header" sx={{ py: "40px" }}>
          <Link href="https://www.deciphr.ai" sx={styles.logoLink}>
            <img src={daiLogo} alt="" width="36" height="45" />

            <img src={deciphrLogo} alt="Deciphr AI" width="92" height="32" />
          </Link>
        </Box>

        <Container component="main" maxWidth="lg" sx={styles.main}>
          <Box sx={styles.flexColumn}>
            <Box sx={styles.actionBox}>{children}</Box>

            {initAction !== "collaborator-signup"
              && (
                <Typography sx={styles.termsAndPolicy}>
                  This site is protected by reCAPTCHA and the Google{" "}

                  <Typography
                    component="a"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://policies.google.com/privacy"
                    sx={{ textDecoration: "underline", fontFamily: "Satoshi" }}
                  >
                    Privacy Policy
                  </Typography>{" "}

                  and{" "}

                  <Typography
                    component="a"
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://policies.google.com/privacy"
                    sx={{ textDecoration: "underline", fontFamily: "Satoshi" }}
                  >
                    Terms of Service
                  </Typography>{" "}
                  apply.
                </Typography>
              )}
          </Box>

          <Box sx={styles.flexColumn}>
            {initAction === "signup" ? (
              <>
                <CountdownTimer />

                <Typography
                  fontSize={{ xs: "28px", lg: "40px" }}
                  sx={{
                    color: "#1B2730",
                    fontFamily: "Satoshi",
                    fontWeight: 500,
                    letterSpacing: "-1.2px",
                    mb: "16px",
                    textAlign: "center",
                  }}
                >
                  Cut&nbsp;

                  <span
                    style={{
                      position: "relative",
                      backgroundImage: `url(${circle})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "left top",
                      padding: "12px 24px 16px 16px",
                    }}
                  >
                    5+ hours
                  </span>
                  &nbsp;a day from&nbsp;

                  <br />

                  your workflow with Deciphr
                </Typography>
              </>
            ) : (
              <Typography
                fontSize={{ xs: "28px", lg: "40px" }}
                sx={{
                  color: "#1B2730",
                  fontFamily: "Satoshi",
                  fontWeight: 500,
                  letterSpacing: "-1.2px",
                  mb: "16px",
                  textAlign: "center",
                }}
              >
                Thousands of{" "}

                <span
                  style={{
                    position: "relative",
                    backgroundImage: `url(${circle})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "left top",
                    padding: "5px 24px 15px 10px",
                  }}
                >
                  {" "}
                  creators

                  {" "}

                </span>{" "}<br />
                saved 200K+ hours.
              </Typography>
            )}

            <Box sx={styles.sideBox}>
              <Suspense fallback={<div />}>
                {initAction === "signup" ? (
                  <TestimonialContainer />
                ) : (
                  <Benefits />
                )}
              </Suspense>
            </Box>

            <Grid container rowSpacing="40px" spacing="24px" sx={styles.logoContainer}>
              {partnerLogos.map((brand, index) => (
                <Grid item xs={4} md={4} key={index}>
                  <Box
                    component="img"
                    src={brand.src}
                    alt={brand.name}
                    title={brand.name}
                    loading="lazy"
                    height={{ xs: "20px", lg: "28px" }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Container>
    </Box>
  );
}

AuthLayout.propTypes = {
  children: PropType.oneOf([null, PropType.object]),
  initAction: PropType.string,
};

AuthLayout.defaultProps = {
  children: null,
  initAction: "",
};
