export function getRangeInMillisFromIndex({
  words,
  startIndex,
  endIndex,
}) {
  if (!words.length || endIndex > words.length - 1) {
    return {
      start: 0,
      end: 0,
    };
  }

  return {
    start: words[startIndex].start,
    end: words[endIndex].end,
  };
}

export const getMillisFromFrame = (frame, fps) => (Math.floor((frame / fps) * 1000));
export const getFrameFromMillis = (millis, fps) => (Math.floor((millis * fps) / 1000));

export const formatColor = (color) => {
  if (!color) {
    return color;
  }

  if (color.startsWith("#")) {
    return color;
  }

  return `#${color}`;
};
