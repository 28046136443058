import { createSlice } from '@reduxjs/toolkit';

const playbackRates = [0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.5, 3, 3.5, 4];

const audioPlayerSlice = createSlice({
  name: 'audio',
  initialState: {
    start: null,
    end: null, // end time, to stop playing individual snippets when the end time is reached
    isPlaying: false,
    playRate: '1x',
  },

  reducers: {
    setStartTime: (state, action) => {
      state.start = action.payload;
    },

    setEndTime: (state, action) => {
      state.end = action.payload;
    },

    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },

    toggleIsPlaying: (state) => {
      state.isPlaying = !state.isPlaying;
    },

    setPlayRate: (state, action) => {
      state.playRate = action.payload;
    },

    playSnippet: (state, action) => {
      state.currentSnippet = action.payload.snippetId;
      state.start = action.payload.start;
      state.currentTime = action.payload.start;
      state.isPlaying = action.payload.isPlaying;
    },

    increasePlaybackRate: (state) => {
      const currentPlaybackRate = parseFloat(state.playRate);
      const currentIndex = playbackRates.indexOf(currentPlaybackRate);
      const nextIndex = (currentIndex === (playbackRates.length - 1)) ? currentIndex : (currentIndex + 1);

      state.playRate = `${playbackRates[nextIndex]}x`;
    },

    decreasePlaybackRate: (state) => {
      const currentPlaybackRate = parseFloat(state.playRate);
      const currentIndex = playbackRates.indexOf(currentPlaybackRate);
      const prevIndex = (currentIndex === 0) ? currentIndex : (currentIndex - 1);

      state.playRate = `${playbackRates[prevIndex]}x`;
    },

    resetPlaybackRate: (state) => {
      state.playRate = '1x';
    },
  },
});

export const {
  setStartTime,
  setEndTime,
  setIsPlaying,
  toggleIsPlaying,
  setPlayRate,
  playSnippet,
  increasePlaybackRate,
  decreasePlaybackRate,
  resetPlaybackRate,
} = audioPlayerSlice.actions;

export const selectStartTime = (state) => state.audio.start;
export const selectEndTime = (state) => state.audio.end;
export const selectIsPlaying = (state) => state.audio.isPlaying;
export const getPlayRate = (state) => state.audio.playRate;

export default audioPlayerSlice.reducer;
