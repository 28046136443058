import Rollbar from "rollbar";

const rollbarConfig = {
  accessToken: "6c485a3169954ea3862309f77c144875",
  enabled: process.env.REACT_APP_ENV === "production",
  environment: process.env.REACT_APP_ENV,
  server: {
    root: process.env.REACT_APP_BACKEND_URL,
    // DGAF about local/dev branches
    branch: process.env.REACT_APP_ENV === "production" ? "master" : "staging",
  },
};

export const rollbar = new Rollbar(rollbarConfig);
