/* eslint-disable react/jsx-newline */
import { lazy, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import DAIProtectedRoute from "DAI/Auth/containers/ProtectedRoute";
import Auth from "pages/Auth/indexV2";
import DownloadProgressBar from "pages/newViewTranscript/components/DownloadProgressBar";
import PageLoader from "components/pageLoader";
import { getUserInfo } from "store/user";

const SuccessPage = lazy(() => import("pages/Auth/success"));
const EmailVerificationPage = lazy(() => import("DAI/Auth/pages/EmailVerification"));
const PlansPage = lazy(() => import("DAI/Subscription/pages/Pricing"));
const Profile = lazy(() => import("pages/profile"));
const Home = lazy(() => import("DAI/Home/pages/Home"));
const LibraryPage = lazy(() => import("DAI/Library/pages/Library"));
const RevampedContentDetailsPage = lazy(() => import("DAI/ContentDetails/pages/RevampedContentDetails"));
const LibrarySearchPage = lazy(() => import("DAI/Library/pages/LibrarySearch"));
const ResetPass = lazy(() => import("pages/Auth/resetPass"));
const UserOnboarding = lazy(() => import("DAI/UserOnboarding/pages/Onboarding"));
const ContentBrainPage = lazy(() => import("DAI/contentBrain/pages"));
// const ContentCreatePage = lazy(() => import("DAI/contentBrain/pages/createContent"));
// const ProccessedContent = lazy(() => import("DAI/contentBrain/pages/processedContent"));
const DictionaryPage = lazy(() => import("DAI/contentBrain/pages/dictionary"));
// const SearchCreatePage = lazy(() => import("DAI/contentBrain/pages/searchContent"));
const AskDeciphrPage = lazy(() => import("DAI/Library/pages/AskDeciphr"));
const VideoReelEditorPage = lazy(() => import("DAI/MediaEditor/pages/VideoReelEditorPage"));
const AudiogramEditorPage = lazy(() => import("DAI/MediaEditor/pages/AudiogramEditorPage"));

export default function DAIRoutes({ isEnabled }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const loginInfo = JSON.parse(localStorage.getItem("userInfo"));
    const token = loginInfo?.token;
    const payload = token ? JSON.parse(window.atob(token.split(".")[1])) : null;

    if (loginInfo !== null) {
      if (payload && payload.org_role === null) {
        dispatch(getUserInfo({ identify: true }));
      }
    }
  }, [dispatch]);

  if (isEnabled) {
    return (
      <Routes>
        {/* PROTECTED ROUTES */}
        <Route element={<DAIProtectedRoute />}>
          <Route path="home" element={<Home />} />
          <Route path="library" element={<LibraryPage />} />
          <Route path="library/search" element={<LibrarySearchPage />} />

          <Route path="content_brain" element={<ContentBrainPage />}>
            {/* <Route path="create" element={<ContentCreatePage />} />
            <Route path="content/:id" element={<ProccessedContent />} /> */}
            <Route path="dictionary" element={<DictionaryPage />} />
            {/* <Route path="search" element={<SearchCreatePage />} /> */}
          </Route>

          <Route path="content">
            <Route path=":id" element={<RevampedContentDetailsPage />} />
          </Route>

          <Route path="main_topic/:id/*">
            <Route path="" element={<RevampedContentDetailsPage />} />
            <Route path="video-reels/:reelId" element={<VideoReelEditorPage />} />
            <Route path="audiograms/:audiogramId" element={<AudiogramEditorPage />} />
          </Route>

          {/* <Route path="writing_style" element={<WritingStyleLayout />} /> */}
          <Route path="ask-deciphr" element={<AskDeciphrPage />} />
          <Route path="success" element={<SuccessPage />} />
          <Route path="verify-email" element={<EmailVerificationPage />} />
          <Route path="profile" element={<Profile />} />
          <Route path="user-onboarding" element={<UserOnboarding />} />
          <Route path="pricing" element={<PlansPage />} />
          <Route path="plans" element={<PlansPage />} />
          <Route path="download" element={<PageLoader customLoader={<DownloadProgressBar />} />} />

          <Route path="auth" element={<Auth />}>
            <Route path="password-reset" element={<ResetPass />} />
            {/* RESET OR EMAIL VERIFICATION ROUTE */}
          </Route>

          <Route path="*" element={<Navigate to="/home" replace />} />
        </Route>

        {/* // TODO: 404 page  */}
      </Routes>
    );
  }

  return null;
}

DAIRoutes.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
};
