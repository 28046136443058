import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import camelCase from "lodash/camelCase";
import differenceBy from "lodash/differenceBy";
import {
  getAllCreatedContentTypes,
  updateContent,
} from "DAI/ContentDetails/services/apis";
import {
  getContentTypeLabel,
  getCreateContentTypesListFor,
  getSnakeCaseVersion,
  getWrittenContentTypesList,
  presetContentMap,
} from "DAI/ContentDetails/services/presets";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";

const writtenContentSlice = createSlice({
  name: "writtenContent",
  initialState: {
    isInit: false,
    isStreaming: false,
    fileId: null,
    preset: null,
    summary: null,
    quotes: null,
    chapters: null,
    tweetThread: null,
    showNotes: null,
    focusKeywords: null,
    titleSuggestions: null,
    article: null,
    captions: null,
    meetingMinutes: null,
    actionItems: null,
    decisionLog: null,
    importantDates: null,
    qAndA: null,
    nextAgenda: null,
    followUpEmails: null,
    sessionRecap: null,
    whitePaper: null,
    faqs: null,
    newsletter: null,
    landingPage: null,
    clipboardValue: null,
    clipboardType: null,
    isLoading: false,
    createdContentTypes: [],
    possibleContentTypes: [],
  },

  reducers: {
    setIsInit(state, action) {
      state.isInit = action.payload;
    },
    setIsStreaming(state, action) {
      state.isStreaming = action.payload;
    },
    initializeContent: (state, action) => {
      const { fileId, preset, content, createdContents } = action.payload;

      state.fileId = fileId;
      state.preset = preset;

      try {
        Object.entries(presetContentMap[preset]).forEach(([key, value]) => {
          if (content[value]) {
            state[key] = content[value];
          }
        });
      } catch (e) {
        //
      }

      createdContents.forEach((item) => {
        state[camelCase(item.type)] = item.content;
      });
    },

    resetContent: (state) => {
      try {
        Object.keys(state).forEach((key) => {
          state[key] = null;
        });
      } catch (e) {
        //
      }
    },

    setContent: (state, action) => {
      const { contentType, value } = action.payload;

      state[contentType] = value;
    },

    // Markdown content
    copyContent: (state, action) => {
      const { type } = action.payload;

      state.clipboardType = type;
      state.clipboardValue = state[type] || "";
    },

    setClipboard: (state, action) => {
      const { type, value } = action.payload;

      state.clipboardType = type;
      state.clipboardValue = value;
    },

    copyFocusKeywords: (state) => {
      const keywords = (state.focusKeywords || []).join(", ");

      state.clipboardType = "focusKeywords";
      state.clipboardValue = keywords;
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCreatedContentTypes(state, action) {
      state.createdContentTypes = action.payload;
    },
    addCreatedContentType(state, action) {
      if (!state.createdContentTypes) {
        state.createdContentTypes = [];
      }

      state.createdContentTypes.push({
        label: action.payload.label,
        value: action.payload.value,
      });
    },
    setPossibleContentTypes(state, action) {
      state.possibleContentTypes = action.payload;
    },
  },
});

export const {
  setIsInit,
  initializeContent,
  resetContent,
  setContent,
  copyContent,
  setClipboard,
  copyFocusKeywords,
  setIsLoading,
  setCreatedContentTypes,
  setPossibleContentTypes,
  addCreatedContentType,
  setIsStreaming,
} = writtenContentSlice.actions;

// Selectors
export const isContentReady = (contentType) => (state) => state.writtenContent[contentType] !== null;
export const selectContent = (contentType) => (state) => state.writtenContent[contentType];
export const selectClipboard = (state) => ({
  type: state.writtenContent.clipboardType,
  value: state.writtenContent.clipboardValue,
});
export const selectPreset = (state) => state.writtenContent.preset;
export const createdContentTypesSelector = (state) => state.writtenContent.createdContentTypes;
export const possibleContentTypesSelector = (state) => state.writtenContent.possibleContentTypes;
export const isWrittenContentInitSelector = (state) => state.writtenContent.isInit;
export const isStreamingSelector = (state) => state.writtenContent.isStreaming;

// Async thunks
export const updateWrittenContent = createAsyncThunk("writtenContent/update", async (payload, thunkAPI) => {
  const { content, type } = payload;
  const { dispatch, getState } = thunkAPI;
  const { writtenContent } = getState();
  const { fileId, isLoading } = writtenContent;
  const contentType = getSnakeCaseVersion(type); // snake_case version of type
  const contentLabel = getContentTypeLabel(contentType); // human readable version of type

  if (isLoading) {
    return;
  }

  dispatch(setIsLoading(true));

  try {
    await updateContent(fileId, contentType, content);

    dispatch(setContent({ contentType: type, value: content }));
    analytics.track(`${contentType} edited successfully`);
  } catch (e) {
    dispatch(
      setToast({
        message: `${contentLabel} could not be updated at this time. Please retry in a bit.`,
        severity: "error",
      }),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
});

export const copyTranscriptToClipboard = createAsyncThunk("writtenContent/copyTranscript", async (_, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { transcript } = getState();
  const transcriptArray = transcript.transcript;
  const clipboardValue = transcriptArray
    // eslint-disable-next-line no-useless-escape
    .map((t) => `**${t.timestamp}** - \(${t.speaker}\): ${t.text}`)
    .join("\n\n");

  dispatch(setClipboard({ type: "transcript", value: clipboardValue }));
});

export const copyItemToClipboard = createAsyncThunk("writtenContent/copyItem", async (payload, thunkAPI) => {
  const { type } = payload;
  const { dispatch } = thunkAPI;

  switch (type) {
    case "transcript":
      dispatch(copyTranscriptToClipboard());

      break;
    case "focusKeywords":
      dispatch(copyFocusKeywords());

      break;
    default:
      dispatch(copyContent({ type }));
  }
});

export const getCreatedContentTypes = createAsyncThunk(
  "writtenContent/getCreatedContentList",
  async (payload, thunkAPI) => {
    const { fileId } = payload;
    const { dispatch, getState } = thunkAPI;
    const state = getState();

    try {
      const res = await getAllCreatedContentTypes({
        fileId,
      });

      const presetContentTypes = getCreateContentTypesListFor(state.content.preset);
      const allContentTypes = getWrittenContentTypesList();
      const createdContentTypes = res.types.map(
        (type) => allContentTypes.find((item) => item.value === type),
      ).filter((item) => !!item);

      const possible = differenceBy(
        presetContentTypes,
        createdContentTypes,
        (item) => item.value,
      );
      dispatch(
        setPossibleContentTypes(possible),
      );

      dispatch(
        setCreatedContentTypes(createdContentTypes),
      );
    } catch {
      setToast({
        severity: "error",
        message: "Fetching created content failed",
      });
    }
  },
);

export default writtenContentSlice.reducer;
